<template>
  <div ref="addItemComponent" @click.stop>
    <b-row v-if="showAddItemButton && useAddButton">
      <b-col>
        <div>
          <b-button class="pl-2 w-100" size="sm" :variant="mainVariant" @click="addButtonClicked" >
            {{ mainTitle }}
          </b-button>
      </div>
      </b-col>
    </b-row>
    <!-- Item input field -->
     <div v-if="showItemInputField">
      <b-row>
        <b-col class="input-field" >
          <b-input 
            v-model="form.itemTitle" 
            size="sm" 
            ref="itemInput" 
            @keyup.enter="saveButtonClicked" 
            @keyup.esc="cancelButtonClicked" 
            @input="handleInput"
					></b-input>
        </b-col>
      </b-row>
      <!-- Save/cancel buttons -->
      <b-row>
        <b-col class="d-flex justify-content-end mt-2">
          <b-button size="sm" class="pl-2 pr-2 mr-2" :variant="saveVariant" @click="saveButtonClicked" :disabled="!formIsDirty">Save</b-button>
          <b-button size="sm" class="pl-2 pr-2" variant="light" @click="cancelButtonClicked">Cancel</b-button>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    useAddButton: { type: Boolean, default: true },
    mainButtonTitle: { type: String, default: "Add Item" },
    mainButtonVariant: { type: String, default: "secondary" },
    saveButtonVariant: { type: String, default: "primary" },

  },
  data() {
    return {
      form: {
        itemTitle: "",
      },
      showAddItemButton: true,
      showItemInputField: false,
      mainVariant: this.mainButtonVariant,
      saveVariant: this.saveButtonVariant,
      mainTitle: this.mainButtonTitle,
      formIsDirty: false,
    };
  },
  mounted() {
    document.addEventListener('click', this.handleClickOutside);
  },
  beforeDestroy() {
    document.removeEventListener('click', this.handleClickOutside);
  },
  methods: {
    handleClickOutside(event) {
      if (!this.$refs.addItemComponent.contains(event.target)) {
        this.cancelButtonClicked();
      }
    },
    handleInput(value) {
      this.formIsDirty = value;
    },
    addButtonClicked() {
      this.showAddItemButton = false;
      this.showItemInputField = true;
      this.formIsDirty = false;
      this.$nextTick(() => {
        this.$refs.itemInput.focus()
      })
    },
    saveButtonClicked() {
      if (this.form.itemTitle.trim()) { 
        this.$emit('save-item', this.form.itemTitle);
        this.form.itemTitle = "";
      }
      this.showAddItemButton = true;
      this.showItemInputField = false;
    },
    cancelButtonClicked() {
      this.showAddItemButton = true;
      this.showItemInputField = false;
      this.form.itemTitle = "";
    }
  }
}
</script>

<style scoped>
.map-title {
  font-weight: 700;
  color: rgb(69, 69, 69);
  font-size: .9rem;
}
.list-name {
  font-size: .8rem;
}
</style>