/**
 * Automatically imports all the modules and exports as a single module object
 */
// const requireModule = require.context(".", false, /\.store\.js$/)
// const modules = {}

// requireModule.keys().forEach((filename) => {
//   // Create the module name from fileName.
//   // Remove the store.js extension and capitalize.
//   const moduleName = filename
//     .replace(/(\.\/|\.store\.js)/g, "")
//     .replace(/^\w/, (c) => c.toUpperCase());

//   modules[moduleName] =
//     requireModule(filename).default || requireModule(filename);
// });
// export default modules

// import Vue from 'vue';
// import Vuex from 'vuex';
// import base2 from './base2.store.js';
// import issue2 from './issue2.store.js';
// import Issue from './issue.store.js'
// import Base from '../common/base.store.js'
// import PurchasedItemReceive from './purchasedItemReceive.store';
// import Dashboard from './dashboard.store.js'
// import Session from './session.store.js'
// import User from './user.store.js'
// import Notification from './notification.store.js'
// import Common from './common.store.js'

// Vue.use(Vuex);

// export default new Vuex.Store({
//   modules: {
//     Issue,
//     Base,
//     PurchasedItemReceive,
//     base2,
//     issue2,
//     Dashboard,
//     Session,
//     User,
//     Notification,
//     Common
//   }
// });

/**
 * Automatically imports all the modules and exports as a single module object
 */
const requireModule = require.context(".", false, /\.store\.js$/);
const modules = {};

requireModule.keys().forEach((filename) => {
  // Create the module name from fileName.
  // Remove the store.js extension and capitalize.
  const moduleName = filename
    .replace(/(\.\/|\.store\.js)/g, "")
    .replace(/^\w/, (c) => c.toUpperCase());

  modules[moduleName] =
    requireModule(filename).default || requireModule(filename);
});

export default modules;
