<template>
  <b-modal :id="modalId" hide-footer @close="closeModal">
    <b-card :title="heading">
      <div v-html="text"></div>
    </b-card>
  </b-modal>
</template>

<script>
export default {
  props: {
    modalId: {
      type: String,
      required: true,
    },
    heading: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      required: true,
    },
  },
  methods: {
    closeModal() {
      this.$emit('close'); // Emit close event for the parent to handle
    },
  },
};
</script>

<style scoped>
/* Add any necessary styles */
</style>
