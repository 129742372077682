//import BaseStore from "../common/base.store";
import CommonApi from "../../api/common.api";

// Common GETTERS
export const getters = {};

// Common ACTIONS
export const actions = {
  // Update EXISTING comments changed and insert any new comments
  async saveComments({ commit, dispatch }, { state, rootState, endpoint }) {
    if (!state.currentItem.comments) return;
    const updatedComments = state.currentItem?.comments?.filter(
      (x) => x.dirty && x.id > -1
    );
    if (updatedComments?.length > 0) {
      // Update changed comments, one by one
      for (const comment of updatedComments) {
        commit("CURRENT_ITEM_COMMENT_FIELD_SET", {
          id: comment?.id,
          key: "lastUpdatedBy",
          value: rootState.Session.user.familiarName,
        });
        // Save comment record to database
        try {
          const useApi = new CommonApi(endpoint);
          const updatedItem = await useApi.updateOne(comment);
          if (updatedItem.data.alert) {
            // Alert/error: post it and stay here
            dispatch(
              "Notification/alertErrorAdd",
              {
                message: updatedItem.data.alert,
                show: true,
              },
              {
                root: true,
              }
            );
            return false;
          } else {
            // Call mutation after successful save
            return updatedItem.data.data[0];
          }
        } catch (error) {
          console.log(
            "There was an error updating an item (special):",
            error.response
          );
          return false;
        }
      }
    }
    // Add NEW comments? (only 1 new comment at a time)
    const newComment = state.currentItem.comments.find(
      (x) => x.id === -1 && x.comment
    );
    if (newComment) {
      newComment.lastUpdatedBy = rootState.Session.user.familiarName;
      commit("CURRENT_ITEM_COMMENT_UPDATE_USERID_SET", {
        comment: newComment,
        userId: rootState.Session.user.familiarName,
      });
      // Save new commment record
      // Save to database
      try {
        const useApi = new CommonApi(endpoint);
        const createdItem = await useApi.createOne(newComment);
        if (createdItem.data.alert) {
          // Alert: post it and stay here
          dispatch(
            "Notification/alertErrorAdd",
            { message: createdItem.data.alert, show: true },
            {
              root: true,
            }
          );
          return false;
        } else {
          // Call mutation after successful save
          const savedComment = createdItem.data.data[0];
          commit("CURRENT_ITEM_COMMENT_UPDATE_ID_SET", {
            comment: newComment,
            id: savedComment?.id,
          });
        }
      } catch (error) {
        if (error.response) {
          console.log("There was an error adding an item:", error.response);
        } else {
          console.log("There was an error adding an item:", error);
        }
      }
    }
  },

  // Delete comment
  async deleteComment({ commit, dispatch }, { comment, endpoint }) {
    try {
      // Delete item
      const useApi = new CommonApi(endpoint);
      await useApi.deleteOne(comment);
      // Update state
      commit("CURRENT_ITEM_COMMENT_DELETE_SET", comment);
    } catch (err) {
      const msg = `Error deleting record id ${comment?.id}: ${err}`;
      dispatch("Notification/toastErrorAdd", msg, {
        root: true,
      });
    }
  },

  async updateComment({ commit }, { data, rootState }) {
    // Add comment to detail; can be new or existing
    data.userId =
      data.userId === "New Comment"
        ? rootState.Session.user.familiarName
        : data.userId;
    data.lastUpdatedBy = rootState.Session.user.familiarName;
    commit("CURRENT_ITEM_COMMENT_UPDATE_SET", data);
  },
};

// Common MUTATIONS
export const mutations = {
  // Mutations specific to this store added here
  CURRENT_ITEM_COMMENT_FIELD_SET(state, data) {
    const foundComment = state.currentItem.comments.find(
      (x) => x.id === data?.id
    );
    if (!foundComment) return;
    foundComment[data.key] = data.value;
  },
  CURRENT_ITEM_COMMENT_SET(state, data) {
    if (state.currentItem.comments) {
      state.currentItem.comments.push(data);
    } else {
      state.currentItem.comments = [data];
    }
  },
  CURRENT_ITEM_COMMENT_DELETE_SET(state, data) {
    state.currentItem.comments = state.currentItem.comments.filter(
      (x) => x.id !== data?.id && x.issueId === data.issueId
    );
  },
  CURRENT_ITEM_COMMENT_UPDATE_SET(state, data) {
    const foundComment = state.currentItem.comments.find(
      (x) => x.id === data?.id
    );
    if (!foundComment) return;
    foundComment.userId = data.userId;
    foundComment.comment = data.comment;
    foundComment.lastUpdatedBy = data.lastUpdatedBy;
    foundComment.dirty = true;
  },
  CURRENT_ITEM_COMMENT_UPDATE_USERID_SET(state, data) {
    data.comment.userId = data.userId;
    data.comment.lastUpdatedBy = data.userId;
  },
  CURRENT_ITEM_COMMENT_UPDATE_ID_SET(state, data) {
    data.comment.id = data?.id;
  },
};
