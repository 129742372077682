<template>
  <div
    :class="isChecked ? 'mapped-item-active' : 'mapped-item'"
    @mouseover="showButtons = true"
    @mouseleave="showButtons = false"
    @click="handleItemClick"
  >
    <div class="btn-drag-handle upload-map-wrapper" ref="editItemTitle" >
      <!-- First row: title -->
      <b-row class="ml-1">
        <!-- Title left column -->
        <b-col cols="9" class="p-0">
          <!-- Title -->
          <div v-if="!showTitleInput" class="map-title" @click="editItem">
            {{ savedMap.name }}
          </div>
          <!-- Title input field (to rename) -->
          <b-input
            v-if="showTitleInput"
            class="title-input"
            v-model="form.itemTitle"
            size="sm"
            ref="itemInput"
            @keyup.enter="saveButtonClicked"
            @keyup.esc="close"
            @input="handleInput"
            @click.stop
          ></b-input>
          <!-- Save / Cancel buttons (to rename) -->
          <div v-if="showTitleInput" class="d-flex justify-content-end">
            <b-button
              size="sm"
              class="pl-2 pr-2 mr-2"
              variant="primary"
              @click="saveButtonClicked"
              :disabled="!formIsDirty"
              >Save</b-button
            >
            <b-button
              size="sm"
              class="pl-2 pr-2"
              variant="light"
              @click="cancelButtonClicked"
              >Cancel</b-button
            >
            <span v-if="formIsDirty && isClosing" class="save-alert"
              >Map has changed. Save changes?</span
            >
          </div>
        </b-col>
        <b-col class="p-0 m-0 mr-1 d-flex justify-content-end align-item-start">
          <!-- Right side: Ellipsis dropdown for extra actions (rename, duplicate, delete) -->
          <div v-if="showButtons && !showTitleInput && !showDeleteConfirm" >
            <b-dropdown
              id="action-dropdown"
              variant="link"
              class="more-action-button"
              :disabled="!isFileSelected"
              right
              no-caret
            >
              <!-- Ellipsis button to trigger dropdown -->
              <template #button-content>
                <i class="fas fa-ellipsis-h dropdown-button"></i>
              </template>
              <!-- Dropdown menu options -->
              <b-dropdown-item @click.stop="editItem">
                <i class="fas fa-pencil-alt"></i> Rename
              </b-dropdown-item>
              <b-dropdown-item @click.stop="duplicateItem">
                <i class="fas fa-clone"></i> Duplicate
              </b-dropdown-item>
              <b-dropdown-divider></b-dropdown-divider>
              <b-dropdown-item @click.stop="deleteConfirm">
                <i class="fas fa-trash-alt"></i> Delete
              </b-dropdown-item>
            </b-dropdown>
          </div>      
        </b-col>
      </b-row>
      <!-- Second row: List name, mapped items, dropdown button -->
      <b-row class="d-flex ml-1">
          <!-- List name and mapped field count -->
          <div class="list-name">List: {{ listName }}</div>
          <div class="list-name pl-3">Mapped fields: {{ fieldCount }}</div>
          <div>
            <!-- Map status icon buttons -->
            <b-button
              v-if="mapStatus === 1 && isFileSelected"
              class="icon-button"
              @click="showUploadMap"
            >
              <b-icon
                id="b1"
                icon="check-circle-fill"
                variant="success"
                class="map-status-icon"
              ></b-icon>
              <b-tooltip target="b1" variant="secondary"
                >Show mapped fields</b-tooltip
              >
            </b-button>
            <b-button
              v-if="mapStatus === 0 && isFileSelected"
              class="icon-button"
              @click="showUploadMap"
            >
              <b-icon
                id="b2"
                icon="exclamation-circle-fill"
                variant="danger"
                class="map-status-icon"
              ></b-icon>
              <b-tooltip target="b2" variant="secondary"
                >Show mapped fields</b-tooltip
              >
            </b-button>
            <b-button
              v-if="mapStatus > 0 && mapStatus < 1 && isFileSelected"
              class="icon-button"
              @click="showUploadMap"
            >
              <b-icon
                id="b3"
                icon="exclamation-circle-fill"
                variant="warning"
                class="map-status-icon"
              ></b-icon>
              <b-tooltip target="b3" variant="secondary"
                >Show mapped fields</b-tooltip
              >
            </b-button>
          </div>
      </b-row>
      <!-- Third row: Activate and delete confirm -->
      <b-row class="ml-1 d-flex justify-content-between">
        <!-- Activate switch -->
        <div>
          <b-form-checkbox
            v-model="isChecked"
            :disabled="!isFileSelected"
            name="check-button"
            switch
            @change="handleActivateMap"
          >
            <span :class="getCheckboxLabelClass">Activate</span>
            <span v-if="isChecked" class="active-label">ACTIVE</span>
          </b-form-checkbox>
        </div>
        <!-- Delete confirm  -->
        <div v-if="showDeleteConfirm" class="d-flex justify-content-end mr-3">
          <span class="delete-alert my-auto">Delete upload map?</span>
          <b-button
            size="sm"
            class="pl-2 pr-2 mr-2"
            variant="danger"
            @click="deleteYes"
            >Yes</b-button
          >
          <b-button size="sm" class="pl-2 pr-2" variant="light" @click="deleteNo"
            >No</b-button
          >
        </div>
      </b-row>
    </div> 
  </div>
</template>

<script>
//import { defineComponent } from '@vue/composition-api'
import { EventBus } from "./bus.js";

export default {
  props: {
    namespace: { type: String, default: "" }, 
    savedMap: {},
    listName: { type: String, default: "" },
    isFileSelected: { type: Boolean, default: false },
    filename: { type: String, default: "" },
    isActive: { type: Boolean, default: false },
    isDirty: { type: Boolean, default: false },
    index: Number
  },
  data() {
    return {
      form: {
        itemTitle: "",
      },
      isChecked: this.isActive,
      showButtons: false,
      showTitleInput: false,
      formIsDirty: false,
      isClosing: false,
      showDeleteConfirm: false,
      showMapModal: false
    }
  },
  watch: {
    isActive(newVal) {
      this.isChecked = newVal;
    },
    isDirty(newVal) {
      this.formIsDirty = newVal;
    }
  },
  mounted() {
    EventBus.$on("closeOtherInputs", this.close);
    document.addEventListener("click", this.handleGlobalClick);
  },
  beforeDestroy() {
    EventBus.$off("closeOtherInputs", this.close);
    document.removeEventListener("click", this.handleGlobalClick);
  },
  computed: {
    fieldCount() {
      return this.savedMap?.uploadMap?.length || "";
    },
    mapStatus() {
      const matchedMaps = this.savedMap?.uploadMap?.filter(
        (field) => field.isMatch
      ).length;
      return matchedMaps / this.fieldCount;
    },
    mapStatusTooltipGreen() {
      return `All mapped fields verified for <br>this CSV file '${this.filename}'`;
    },
    getCheckboxLabelClass() {
      // Determine class based on state
      if (!this.isFileSelected) {
        return "activate-map-button-disabled";
      }
      if (this.isChecked) {
        return "activate-map-button-active";
      }
      return "activate-map-button-normal";
    },
  },
  methods: {
    showUploadMap() {
      const buttonRect = event.target.getBoundingClientRect();
      this.$emit("show-upload-map-modal", {
        savedMap: this.savedMap,
        top: buttonRect.top,
        left: buttonRect.left + buttonRect.width,
      });
    },
    handleActivateMap(checked) {
//      if (checked) {
      this.$emit("activate-map", { map: this.savedMap, isActive: checked });
  //    } else {
      //   console.log("UploadMapItem.handleActivateMap.checked is false: ", checked)
      //   this.$emit("activate-map", null);
      // }
    },
    handleGlobalClick(event) {
      // Check if the click was outside of this component
      const clickedInside = this.$el?.contains(event.target);
      if (!clickedInside) {
        this.close();
      }
    },
    handleItemClick(event) {
      if (!this.showTitleInput) {
        EventBus.$emit("closeOtherInputs");
      } else if (
        this.showTitleInput &&
        !this.$refs.editItemTitle?.contains(event.target)
      ) {
        this.close();
      }
    },
    handleInput(value) {
      // If value is not blank, set dirty
      if (value && value !== this.savedMap.name) {
        this.formIsDirty = true;
      } else {
        this.formIsDirty = false;
      }
    },
    closeInput() {
      this.showTitleInput = false;
    },
    editItem() {
      // Emit an event to close other inputs
      EventBus.$emit("closeOtherInputs");
      this.showTitleInput = true;
      this.$nextTick(() => {
        this.$refs.itemInput.focus();
        this.form.itemTitle = this.savedMap.name;
      });
    },
    duplicateItem() {
      this.$emit("duplicate-item", this.savedMap);
    },
    deleteConfirm() {
      this.showDeleteConfirm = true;
    },
    deleteYes() {
      this.showDeleteConfirm = false;
      this.$emit("delete-item", this.savedMap);
    },
    deleteNo() {
      this.showDeleteConfirm = false;
    },
    saveButtonClicked() {
      if (this.form.itemTitle.trim()) {
        const payload = { savedMap: this.savedMap, title: this.form.itemTitle };
        this.$emit("update-item", payload);
      }
      this.showTitleInput = false;
      this.formIsDirty = false;
      this.isClosing = false;
    },
    close() {
      this.isClosing = true;
      if (this.formIsDirty) {
        return;
      }
      this.cancelButtonClicked();
    },
    cancelButtonClicked() {
      this.showTitleInput = false;
      this.form.itemTitle = "";
      this.formIsDirty = false;
      this.isClosing = false;
    },
  },
};
</script>

<style scoped>
.upload-map-wrapper {
  position: relative;
}
.mapped-item {
  background-color: #f7f7f7;
  margin-bottom: 10px;
  position: relative;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 2px 2px 5px rgba(14, 15, 14, 0.241);
  transition: background-color 0.3s ease;
}
.mapped-item-active {
  background-color: #f7f7f7;
  margin-bottom: 10px;
  position: relative;
  padding: 10px;
  border-radius: 10px;
  border: 1px solid #039d5f;
  box-shadow: 2px 2px 5px rgba(1, 219, 56, 0.383);
  transition: background-color 0.3s ease;
}

.mapped-item:hover {
  background-color: #f0f0f0;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
}
.text-section {
  display: flex;
  flex-direction: column;
}
.map-title {
  font-weight: 700;
  color: rgb(69, 69, 69);
  font-size: 0.9rem;
  cursor: pointer;
}
.title-input {
  width: 350px;
  margin-bottom: 8px;
}
.list-name {
  font-size: 0.8rem;
  color: #888;
}

.activate-map-button-normal,
.activate-map-button-disabled,
.activate-map-button-active {
  font-size: 0.8rem;
  text-transform: uppercase;
  font-weight: 600;
  color: #707070;
  margin-bottom: 2px;
}

.activate-map-button-disabled {
  opacity: 0.5;
}
.activate-map-button-active {
  opacity: 1;
  color: green;
}

.active-label {
  background-color: green;
  color: rgb(213, 245, 204);
  margin-left: 10px;
  font-size: .7rem;
  padding: 2px 5px;
  font-weight: 600;
}

.icon-button {
  background: none;
  border: none;
  padding: 0;
  width: 16px;
  height: 16px;
  margin-left: 5px;
  margin-bottom: 4px;
  border-radius: 50%;
}
.icon-button:focus,
.icon-button:active {
  outline: none;
  box-shadow: none;
  background-color: transparent;
}

.icon-button:hover .map-status-icon {
  transform: scale(1.1);
  color: #34d058;
  transition: transform 0.2s ease, color 0.2s ease; /* Smooth transition */
  color: inherit;
}

.map-status-icon {
  font-size: 16px;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
  border-radius: 50%;
  margin-bottom: 3px;
  margin-top: -3px;
  margin-right: 2px;
  color: inherit;
}

.save-alert {
  font-size: 0.9rem;
  vertical-align: middle;
  margin-left: 10px;
  padding: 2px 5px;
  border-radius: 5px;
  color: rgb(0, 49, 2);
  background-color: #caf6cf;
}

.delete-alert {
  font-size: 0.9rem;
  margin-right: 10px;
  padding: 2px 4px;
  border-radius: 5px;
  color: rgb(99, 0, 0);
  background-color: #eec7cb;
}

.dropdown-button {
  color: gray;
  transition: transform 0.2s ease-in-out, color 0.2s ease-in-out;
}

#action-dropdown {
  position: absolute;
  right: 0;
  top: 0;
  margin: 0;
  padding: 0;
}

.dropdown-button:hover {
  transform: scale(1.1);
  color: rgb(102, 102, 102);
}

.more-action-button {
  position: absolute;
  z-index: 1000;
}

</style>
