<template>
  <b-overlay
    variant="white"
    :show="isLoading"
    :opacity="0.0"
    spinner-type="none"
    spinner-variant="none"
    class="mx-auto"
  >
    <b-navbar
      toggleable="lg"
      type="dark"
      variant="dark" 
      class="shadow-sm my-auto"
    >
      <b-navbar-brand :to="dashboardPath" class="d-flex my-auto logo-style">
        <BaseLogo />
        <div>
          <span class="processor-name ml-1">{{ user.processor.processorName }}</span>
        </div>
      </b-navbar-brand>

      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav class="d-flex align-items-center my-auto">
          <b-nav-item
            :to="{ name: 'PurchasedItemReceivePending' }"
            active-class="active"
            class="mr-2"
            v-if="isAuthorized('OnlineArbitrageMenu', 'read')"
            >
            <b-icon icon="cart-fill" font-scale=".9" class="nav-icon-cart"/>
            Purchased Items</b-nav-item
          >
          <b-nav-item
            :to="{ name: 'OpenIssues' }"
            active-class="active"
            class="mr-2 nav-item-custom mr-3"
            v-if="isAuthorized('IssueMenu', 'read')"
            >
            <b-icon icon="flag-fill" font-scale=".9" class="nav-icon-flag"/>
            Issues</b-nav-item
          >
            <i class="fab fa-amazon custom-amazon-icon m-auto"  ></i>
          <b-nav-item
            :to="{ name: 'AmazonInboundShipments' }"
            active-class="active"
            class="mr-2 nav-item-custom"
            v-if="isAuthorized('AmazonMenu', 'read')"
            >
            Amazon</b-nav-item
          >
          <b-nav-item
            active-class="active"
            to="/admin"
            :disabled="!isAdmin"
            href="#"
            class="nav-item-custom admin-menu"
            v-if="isAuthorized('Admin', 'read')"
            >Admin</b-nav-item
          >
        </b-navbar-nav>

        <!-- Right aligned nav items -->
        <b-navbar-nav class="ml-auto align-items-center" >
          <div>
            <ClientSelect />
          </div>
          <b-nav-item-dropdown right >
            <!-- Using 'button-content' slot -->
            <template #button-content >
              <span class="familiar-name">{{ user.familiarName }}</span>
              <b-avatar variant="info" size="1.6em" class="ml-3"></b-avatar>
            </template>
            <!-- <b-dropdown-item to="/profile">Profile</b-dropdown-item> -->
            <b-dropdown-item @click="$bvModal.show('profile-modal')">Profile</b-dropdown-item>
            <b-dropdown-item to="/about">About</b-dropdown-item>

            <b-dropdown-item @click="localLogout" href="#"
              >Log Out</b-dropdown-item
            >
          </b-nav-item-dropdown>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
    <ProfileModal />
  </b-overlay>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ProfileModal from "../auth/ProfileModal.vue"
import ClientSelect from "./ClientSelect.vue"
// import { handleRouterPushError } from "../utils/component-utils";

export default {
  components: {ProfileModal, ClientSelect},
  mounted() {
    this.toDashboard();
  },
  computed: {
    ...mapGetters({
      isAdmin: "Session/isAdmin"
    }),
    ...mapGetters("Session", [
      "isLoading",
      "isAuthorized",
      "user",
      "dashboardPath"
    ])
  },
  methods: {
    ...mapActions("Session", ["logout", "toDashboard"]),
    ...mapActions("Notification", ["toastMsgAdd"]),

    async localLogout() {
      await this.logout(true);
      await this.toastMsgAdd("Successfully logged out");
    }
  }
};
</script>

<style lang="css" scoped>
nav {
  padding: 2px 10px;
}
.familiar-name{
  font-size: 1em;
}
/* Set default opacity for the icon */
.nav-icon-flag {
  opacity: 0.7; /* Dim the icon by default */
  transition: opacity 0.3s ease, transform 0.3s ease;
  color: rgb(255, 183, 0);
  margin-bottom: 1px
}
.nav-icon-cart {
  opacity: 0.8; /* Dim the icon by default */
  transition: opacity 0.3s ease, transform 0.3s ease;
  margin-bottom: 3px
}
/* Optional: Hover effect for the nav-item itself */
.nav-item-custom:hover .nav-icon-flag .nav-icon-cart{
  opacity: 1.0;
  background-color: rgba(0, 0, 0, 0.1);
}

.nav-item-custom.active .nav-icon-cart{
  opacity: 1.0;
  background-color: rgba(0, 0, 0, 0.1);
}

.nav-item-custom.active .nav-icon-flag{
  opacity: 1.0;
  background-color: rgba(0, 0, 0, 0.1);
  color: rgb(157, 255, 0);
}
.custom-amazon-icon {
  margin: auto;
  padding: 0px;
  color: grey
}
.nav-item-custom {
  display: flex;
  align-items: center;
}

.processor-name {
  font-size: 1rem;
  color: rgb(226, 152, 5);
  margin-right: 5px;
}
.logo-style {
  padding-top: 0px;
}
.admin-menu {
  padding: 0;
  padding-top: 2px;
}

</style>