<template>
  <b-container 
    fluid
    class="main-container"
    :style="[cssVars, { cursor: isLoading ? 'wait' : 'auto' }]"
  >
    <!-- Entire container -->
    <b-overlay
      class="p-0 m-0"
      variant="light"
      :show="isLoading && showLoading"
      :opacity="0.1"
      spinner-variant="secondary"
    >
      <b-row>
        <!-- Saved upload maps -->
        <b-col style="flex: 0 0 29.167%" class="pr-3">
          <div>
            <h1 class="map-title mb-4">File Upload</h1>
            <form @submit.prevent="submitForm">
              <h2 class="saved-map-title mb-2">Facility/Line of Business</h2>
              <!-- Extra (Facility/lob) dropdowns -->
              <template v-for="extra in extras">
                <div :key="extra.field">
                  <BaseFormSelect
                    :id="extra.field"
                    :ref="`${extra.field}Ref`"
                    :name="extra.field"
                    :label="extra.label"
                    :labelCols="4"
                    :namespace="namespace"
                    :fieldName="extra.field"
                    :options="extraOptions[extra.field]"
                    :text-field="extra.textField"
                    :value-field="extra.valueField"
                    v-model="form[extra.field]"
                    :state="getFieldState(extra.field)"
                    @input="$v.form[extra.field]?.$touch()"
                    @change="getSkuPrefix(extra.field)"
                    :disabled="user.scope === 'client' && !extra.showOnClient"
                    :error="$v.form[extra.field]?.$error"
                    :errorMsg="`${extra.label} is required`"
                    @blur="$v.form[extra.field]?.$touch()"
                    compact
                    size="sm"
                  >
                  </BaseFormSelect>
                </div>
              </template>
              <!-- Source (like CheddarSoft) -->
              <hr />
              <div>
                <BaseFormSelect
                  name="originId"
                  label="Origin:"
                  ref="originIdRef"
                  :labelCols="4"
                  :namespace="namespace"
                  fieldName="origin"
                  text-field="originName"
                  value-field="originId"
                  v-model="form.originId"
                  :state="getFieldState('originId')"
                  @input="$v.form.originId?.$touch()"
                  :error="$v.form.originId?.$error"
                  :errorMsg="`Origin is required`"
                  @blur="$v.form.originId?.$touch()"
                  compact
                  size="sm"
                >
                </BaseFormSelect>
                <div class="d-flex flex-column">
                  <!-- Generate sku -->
                  <div class="d-flex align-items-center mb-2" >
                    <b-form-checkbox
                      id="generate-sku"
                      ref="generateSkuRef"
                      v-model="form.generateSku"
                      class="font-sm"
                      name="generateSku"
                      style="flex-wrap: nowrap; margin-top: 2px;"
                      :style="{ width: originLabelWidth + 'px' }"
                    >
                      Generate SKU
                    </b-form-checkbox>
                    <div class="d-flex align-items-center" v-if="form.generateSku">
                      <span ref="prefixSpan font-sm" class="mr-2">Prefix:</span>
                      <b-form-input
                        v-model="form.skuPrefix"
                        size="sm"
                        class="mr-2"
                        @input="createSampleSku"
                        style="width: 100px"
                        />
                      <span id="sampleSku" class="sample-sku" >{{ sampleSku }}</span>
                      <b-tooltip target="sampleSku" variant="secondary" >Next SKU to be used</b-tooltip>

                    </div>
                  </div>
                </div>
              </div>
            </form>
            <h2 class="saved-map-title mt-4">Saved Upload Maps</h2>
            <span v-if="savedMaps?.length === 0">No saved upload maps</span>
            <div ref="mapScrollableContainerRef" class="map-scrollable-container">
              <draggable
                v-model="savedMaps"
                @end="handleSavedMapReorder"
                item-key="id"
              >
                <div class="text-center mb-3">
                  <BaseAddListItem
                    v-if="isMapDirty && !isMapItemActive"
                    mainButtonTitle="Add a New Map"
                    @save-item="handleAddFileUploadMap"
                  />
                </div>
                <div v-for="(item, index) in savedMaps" :key="item.id">
                  <UploadMapItem
                    :namespace="namespace"
                    :savedMap="item"
                    :listName="listName"
                    :handleFileSelected="selectedFilename !== ''"
                    @update-item="handleUploadMapTitle"
                    @show-upload-map-modal="handleUploadMapModal"
                    @activate-map="handleActivateMap"
                    @delete-item="handleDeleteFileUploadMap"
                    @duplicate-item="handleDuplicateUploadMap"
                    :isFileSelected="isFileSelected"
                    :isActive="item.isActive"
                    :isDirty="isMapDirty"
                    :index="index"
                  />
                </div>
              </draggable>
            </div>
            <!-- </b-list-group> -->
            <!-- Show saved map uploadMap modal -->
            <b-modal
              id="small-table-modal"
              v-model="showUploadMapModal"
              size="lg"
              hide-footer
              :centered="true"
              :body-class="['modal-position']"
            >
              <template #modal-header>
                <span class="upload-map-modal-title">{{
                  uploadMapModalTitle
                }}</span>
              </template>
              <SavedUploadMapTable :items="uploadMapModalItems" />
            </b-modal>
          </div>
        </b-col>
        <!-- Fields and pills -->
        <b-col class="pl-0">
          <div>
            <!-- File picker -->
            <b-form-file
              ref="fileInput"
              v-model="uploadFile"
              :disabled="blockActivity"
              accept=".csv"
              placeholder="Choose a file to upload or drop it here..."
              size="sm"
              class="file-browser"
              @input="handleFileSelected"
            />
          </div>
          <!-- Upload Progress Bar -->
          <div ref="progressBarRef" class="mb-3">
            <b-progress class="">
              <b-progress-bar
                :label="uploadProgressLabel"
                :value="csvUploadProgressPct"
                :max="100"
                variant="info"
                show-progress
              ></b-progress-bar>
            </b-progress>
            <div class="d-flex align-items-center justify-content-between">
              <div class="text-center progress-text ml-1">{{ csvUploadProgressMessage}}</div> 
              <div class="mr-1">
                <span style="font-size: .9rem" class="mr-1">Elapsed time:</span>
                <span>{{fileUploadElapsedTime}}</span>
              </div>
            </div>
          </div>
          <!-- Titles -->
          <div class="main-list-container">
            <div class="fields-scrollable-container">
              <b-row >
                <b-col cols="5" class="fields pr-0" ref="fieldsContainer">
                  <!-- Subtitle - left -->
                  <div class="fields-sub-title fields-sub-title-left pl-2">
                    <span>{{ listName }} List</span>
                    <span v-if="!hasMappedFields" class="field-label ml-4"
                      >Nothing mapped yet</span
                    >
                    <span v-if="hasMappedFields" class="field-label ml-4"
                      >Mapped fields: {{ mappedFieldCount }}</span
                    >
                  </div>
                  <!-- List of Fields (Left Column) -->
                  <div
                    v-for="(field, index) in fields"
                    :key="index"
                    class="field-item"
                  >
                    <div class="field-label-container">
                      <div class="field-label">{{ field.label }}</div>
                      <div
                        class="required-flag"
                        :class="{
                          'required-flag-highlight':
                            requiredFlagHighlight && fieldValidationError(field),
                        }"
                        v-if="field.isRequired"
                      >
                        REQUIRED
                      </div>
                    </div>
                    <div
                      :class="[
                        'fields-drop-zone',
                        { 'highlight-drop': field.isHovered },
                        { 'fields-drop-zone-required': field.isRequired },
                      ]"
                      @drop="handleDrop($event, field)"
                      @dragover.prevent="handleDragOver(field)"
                      @dragleave="handleDragLeave(field)"
                    >
                      <!-- Show the pill within the drop zone with styling and draggable -->
                      <div
                        v-if="field.mappedLabel"
                        class="pill"
                        :style="pillStyle(field.pctDataFilled)"
                        @click="toggleFieldDropdown(field.name)"
                        :draggable="true"
                        @dragstart="handleDragStart(field.mappedLabel, field)"
                      >
                        {{ field.mappedLabel.incomingLabel }}
                      <!-- Dropdown for pctDataFilled -->
                      <div v-if="showFieldDropdownName === field.name" class="pillDropdown">
                        Data filled: {{ field.pctDataFilled?.toFixed(2) *100}}%
                      </div>
                      </div>
                    </div>
                  </div>
                </b-col>
                <b-col class="pl-0 pr-2">
                  <!-- List of Draggable Pills (Right Column) -->
                  <!-- Subtitle - right -->
                  <div class="fields-sub-title justify-content-between mr-2">
                    <div class="d-flex align-items-end">
                      <span>Incoming Fields</span>
                      <!-- View incoming CSV data -->
                      <b-button
                        v-if="selectedFilename !== ''"
                        class="show-data-button ml-2"
                        size="sm"
                        variant="light"
                        @click="openCsvModal"
                        >Show CSV Data</b-button
                      >
                      <span>
                        <BaseCsvModal
                          ref="csvModal"
                          modalId="CsvModalShow"
                          :title="viewIncomingDataTitle"
                          :items="csvData"
                        >
                        </BaseCsvModal>
                      </span>
                    </div>
                    <div class="total-csv-rows mr-2"
                      >Total rows: {{ totalCsvRows }}</div
                    >
                  </div>
                  <div
                    class="pills-drop-zone d-flex align-items-center"
                    :style="{ height: pillsDropZoneHeight + 'px' }"
                    :class="{ 'highlight-drop': isPillsHovered }"
                    @drop="handleDropBack($event)"
                    @dragover.prevent
                    @dragenter="handlePillsDragEnter"
                    @dragleave="handlePillsDragLeave"
                  >
                    <p v-if="selectedFilename === ''" class="field-label">
                      Select a file to upload
                    </p>
                    <div
                      v-for="pill in pills"
                      :key="pill.id"
                      :draggable="true"
                      @dragstart="handleDragStart(pill)"
                      @dragenter.prevent="handlePillsDragEnter"
                      @dragleave.prevent="handlePillsDragLeave"
                      class="pill"
                      :style="pillStyle(pill.pctDataFilled)"
                      @click="togglePillDropdown(pill.id)"
                    >
                      <span >{{ pill.incomingLabel }}</span>
                      <!-- Dropdown for pctDataFilled -->
                      <div v-if="showPillDropdownId === pill.id" class="pillDropdown">
                        Data filled: {{ pill?.pctDataFilled?.toFixed(2) *100}}%
                      </div>
                    </div>
                  </div>
                </b-col>
              </b-row>
            </div>
          </div>
          <b-card-footer
            class="mt-0 upload-footer d-flex justify-content-between"
          >
            <div>
              <b-button
                class="upload-footer-button"
                size="sm"
                variant="outline-secondary"
                @click="downloadCsvTemplate"
                >Download Template</b-button
              >
              <b-button
                class="upload-footer-button ml-2"
                size="sm"
                variant="outline-secondary"
                :disabled="!isMapDirty && !isMapItemActive"
                @click="populateArrays"
                >Clear Fields</b-button
              >
              <b-button
                class="upload-footer-button ml-2"
                size="sm"
                variant="secondary"
                :disabled="!isMapDirty || !isMapItemActive"
                @click="handleUpdateFileUploadMap"
                >Save Current Map</b-button
              >
            </div>
            <div>
              <b-button
                class="mr-3"
                variant="secondary"
                size="sm"
                @click="formClose"
                >Close</b-button
              >
              <b-button
                class="upload-footer-button ml-2"
                size="sm"
                variant="secondary"
                :disabled="!hasMappedFields"
                @click="handlePreviewFileUploadMap"
                >Preview Upload</b-button
              >
              <b-button
                class="upload-footer-button ml-2"
                size="sm"
                variant="primary"
                :disabled="!hasMappedFields"
                @click="handleCsvFileUpload"
                >Upload File</b-button
              >
            </div>
          </b-card-footer>
        </b-col>
      </b-row>
      <b-row> </b-row>
      <!-- Show CSV upload preview -->
      <div>
        <BaseCsvModal
          ref="csvModalPreview"
          modalId="CsvModalPreview"
          :title="viewCsvUploadPreviewTitle"
          :items="mappedCsvData"
          :csvFields="useCsvFields"
        >
        </BaseCsvModal>
      </div>
    </b-overlay>
  </b-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import config from "../../../config.js"
import Papa from "papaparse";
import UploadMapItem from "./UploadMapItem.vue";
import * as uploadUtils from "./file-upload-utils.js";
import {
  postMsgBoxOk,
  handleValidateState,
  handleFixErrorsMsg,
  handleConfirmChanges2,
} from "../../utils/component-utils.js";
import SavedUploadMapTable from "./SavedUploadMapTable.vue";
import draggable from "vuedraggable";
import { required } from "vuelidate/lib/validators";
import socket from "@/api/socket.js"
//import _ from "lodash"

export default {
  components: {
    UploadMapItem,
    SavedUploadMapTable,
    draggable,
  },
  props: {},
  data() {
    return {
      clientId: "",
      tenantId: "",
      draggedPill: null,
      draggedFromField: null,
      pillWidth: "0px",
      isPillsHovered: false,
      pillsDropZoneHeight: 0,
      uploadFile: null,
      csvHeadings: [],
      totalCsvRows: 0,
      pills: [], // incoming columns from CSV
      selectedFile: null,
      selectedFilename: "",
      mapId: -1,
      csvData: [],
      showUploadMapModal: false,
      uploadMapModalItems: [],
      uploadMapModalTitle: "",
      isMapDirty: false,
      isMapItemActive: false,
      mappedCsvData: [],
      useCsvFields: [],
      blockActivity: false,
      requiredFlagHighlight: false,
      showPillDropdownId: null,
      showFieldDropdownName: null,
      form: {
        processorFacilityId: null,
        processorLobId: null,
        clientFacilityId: null,
        clientLobId: null,
        originId: null,
        generateSku: JSON.parse(localStorage.getItem('generateSku')) || true,
        skuPrefix: null,
      },
      csvUploadProgressMessage: '',
      csvUploadProgressPct: 0,
      startTime: null,
      timer: null,
      currentTime: null,
      fileUploadElapsedTime: null,
      uploadInProgress: false,
      cssVariables: { "--main-list-height": "auto" },
      originLabelWidth: 0,
      originInputWidth: 0,
      sampleSku: null,
      lastSku: "",
    };
  },
  validations: {
    form: {
      processorFacilityId: { required },
      processorLobId: { required },
      clientFacilityId: { required },
      clientLobId: { required },
      originId: {},
      generateSkus: {},
      skuPrefix: {},
    },
  },
  created() {
    this.$store.dispatch(`${this.namespace}/fetchUploadMaps`);
    this.$v.$reset();
  },
  mounted() {
    // Listen to feedback messages from the server
    socket.on('csvUploadFeedback', (data) => {
      this.csvUploadProgressMessage = data.message;
      this.csvUploadProgressPct = data.pctProgress/2;
    });
    socket.on('amazonImageDownload', (data) => {
      this.csvUploadProgressMessage = data.message;
      this.csvUploadProgressPct = data.pctProgress /2;
    });
    this.clearMappedFields();
    // Calculate and set the pill width when the component mounts
    this.calculatePillWidth();
    const fieldsContainerHeight = this.$refs.fieldsContainer?.offsetHeight - 9;
    this.pillsDropZoneHeight = fieldsContainerHeight;
    this.setExtraDropdownFields();
    this.$nextTick( async () => {
      this.resetValidation();
      this.tenantId = this.user.tenantId;
      this.clientId = this.user.tenantSelectedClient?.clientId || this.user.clientId;
      this.getSkuPrefix("clientLobId");
      await this.createSampleSku();
    });
    this.updateMapContainerHeight(true)
    this.calculateMainListHeight();
    window.addEventListener("resize", this.handleResize);
    this.adjustGenerateSkuWidth();
  },

  beforeDestroy() {
    // clean up socket listeners
    socket.off('csvUploadFeedback')
    clearInterval(this.timer);
    window.removeEventListener("resize", this.handleResize);
  },
  computed: {
    ...mapGetters("Session", [
      "isLoading",
      "showLoading",
      "isAuthorized",
      "user",
      "authToken",
      "tenantSelectedClient",
    ]),
    cssVars() {
      return this.cssVariables;
    },
    uploadProgressLabel() {
      return `Upload progress: ${this.csvUploadProgressPct.toFixed(0)}%`
    },
    savedMaps: {
      get() {
        return this.$store.getters[`${this.namespace}/savedMaps`];
      },
      set(value) {
        this.$store.commit(`${this.namespace}/SET_SAVED_MAPS`, value);
      },
    },
    filters() {
      return this.$store.getters[`${this.namespace}/filters`];
    },
    extras() {
      return [
        {
          field: "processorFacilityId",
          label: "Processor facility",
          textField: "processorFacilityName",
          valueField: "processorFacilityId",
          showOnClient: false,
        },
        {
          field: "processorLobId",
          label: "Processor line (LOB)",
          textField: "processorLobName",
          valueField: "processorLobId",
          showOnClient: false,
        },
        {
          field: "clientFacilityId",
          label: "Client facility",
          textField: "clientFacilityName",
          valueField: "clientFacilityId",
          showOnClient: true,
        },
        {
          field: "clientLobId",
          label: "Client line (LOB)",
          textField: "clientLobName",
          valueField: "clientLobId",
          showOnClient: true,
        },
      ];
    },
    extraOptions() {
      const optionsMap = {};
      this.extras.forEach((extra) => {
        const foundFilter = this.filters.find(
          (filter) => filter.idField === extra.field
        );
        optionsMap[extra.field] = foundFilter ? foundFilter.options : [];
      });
      return optionsMap;
    },

    isFileSelected() {
      return this.selectedFilename !== "";
    },
    namespace() {
      return this.$route.query.namespace;
    },
    listName() {
      const name = this.namespace.replace(/([a-z])([A-Z])/g, "$1 $2");
      return name;
    },
    fields() {
      // Fields to import into
      return this.$store.getters[`${this.namespace}/currentUploadMap`];
    },
    csvFileName() {
      return `${this.listName} Template.csv`;
    },
    hasMappedFields() {
      // Check if any mappedLabel in uploadFieldsMap is not null
      return this.fields.some((field) => field.mappedLabel !== null);
    },
    mappedFieldCount() {
      return this.fields?.filter((field) => field.mappedLabel !== null).length;
    },
    viewIncomingDataTitle() {
      return `Incoming CSV Data - ${this.selectedFilename}`;
    },
    viewCsvUploadPreviewTitle() {
      return `Preview Mapped CSV Data - ${this.selectedFilename}`;
    },
  },
  watch: {
    tenantSelectedClient: {
      handler() {
        // No client selected: Restrict action here
        this.uploadFile = null;
        this.clearMappedFields();
        this.pills = [];
        this.refreshSavedMaps();
        this.selectedFile = null;
        this.selectedFilename = "";
        if (this.tenantSelectedClient.clientId === "0") {
          // All clients: block activity
          this.blockActivity = true;
          const msg = `File upload requires a client context. Please select a specific client then try again.

            As a processor, you currently have "All clients" selected. On the main navigation bar at the top, select one of your clients.`;
          postMsgBoxOk(this, `File Upload Client Required`, msg);
        } else {
          this.blockActivity = false;
        }
        // Refresh extra dropdown options for client facility and client line
        this.refreshExtraOptions();
      },
      deep: true,
    },
    currentTime: {
      handler() {
        if (!this.startTime || !this.currentTime) {
          this.fileLoadElapsedTime = "0:00";
        } else {
          const diff = this.currentTime - this.startTime;
          const minutes = Math.floor(diff / 60000);
          const seconds = Math.floor((diff % 60000) / 1000);
          this.fileUploadElapsedTime = `${minutes}:${seconds < 10 ? '0' : ''}${seconds}` || "0:00";
        }
      },
      deep: true,
    },
    uploadInProgress: {
      handler() {
        this.calculateMainListHeight();
      }
    },
    'form.generateSku'(newValue) {
      localStorage.setItem('generateSku', JSON.stringify(newValue));
    },
  },
  methods: {
    ...mapActions("Notification", ["toastMsgAdd"]),

    async getLastSku() {
      const result = await this.$store.dispatch(`${this.namespace}/fetchLastSku`, { tenantId: this.tenantId, clientId: this.clientId });
      this.lastSku = result?.data?.sku;
    },

    getSkuPrefix(field) {
      if (field === 'clientLobId') {
        const clientName = this.user.scope === 'client' ? this.user.client.clientName : this.user.tenantSelectedClient.clientName;
        const value = this.form.clientLobId;
        const foundFilter = this.filters.find(filter => filter.field === "clientLobName");
        const clientGroup = foundFilter.options.find(group => group.label === clientName);
        const defaultOption = clientGroup.options.find(option => option.clientLobId === value)
        if (defaultOption) {
          this.form.skuPrefix = defaultOption.skuPrefix;
          this.createSampleSku();
        }
       }
    },

    async createSampleSku(omitNumber = false) {
      // OAC-24-1118-001
      const currentDate = new Date();
      const year = currentDate.getFullYear().toString().slice(-2);
      const month = String(currentDate.getMonth() + 1).padStart(2, '0');
      const day = String(currentDate.getDate()).padStart(2, '0');
      const datePart = `${year}-${month}${day}`;
      const leftPartNewSku = `${this.form.skuPrefix}-${datePart}`;
      // Get last sku used
      await this.getLastSku();
      // Verify expected format
      const isValidSku = /^.+-\d{2}-\d{4}-\d{3}$/.test(this.lastSku);
      if (!isValidSku) {
        this.lastSku = null;
      }
      const numPart = this.lastSku?.match(/-(\d{3})$/);
      const lastNum = numPart ? Number(numPart[1]) : null;
      const leftPartLastSkuMatch = this.lastSku?.match(/^(.*?)-\d{3}$/);
      const leftPartLastSku = leftPartLastSkuMatch ? leftPartLastSkuMatch[1] : null;
      let useNum = 1;
      // If we already have a sku for today, pick up where we left off
      if (leftPartNewSku === leftPartLastSku && lastNum) {
        useNum = lastNum ? lastNum + 1 : 1;
      }
      const paddedPart = omitNumber ? "" : String(useNum).padStart(3, '0');
      this.sampleSku = `${leftPartNewSku}-${paddedPart}`
      return this.sampleSku;
    },

    adjustGenerateSkuWidth() {
      // Set generateSku checkbox width
      const labelElement = this.$refs.originIdRef.$refs.labelRef?.$el.children[0];
      if (labelElement) {
        this.originLabelWidth = labelElement.offsetWidth;
      }
      const inputElement = this.$refs.originIdRef.$refs.inputRef?.$el;
      if (inputElement) {
        this.originInputWidth = inputElement.offsetWidth;
      }
    },
    calculateMainListHeight() {
      // this.$nextTick(() => {
      setTimeout(() => {
        const viewHeight = window.innerHeight; // Equivalent to 100vh
        const offset = 195;
//        const progressBarHeight = this.uploadInProgress ? 56 : 0;
        const progressBarHeight = 56;
        const newHeight = `${viewHeight - offset - progressBarHeight}px`;
        this.cssVariables["--main-list-height"] = newHeight;
      }, 50);
      // });
    },

    handleResize() {
      this.updateMapContainerHeight();
      this.calculateMainListHeight();
      this.adjustGenerateSkuWidth();
    },

    async formClose() {
      // Route back
      this.$router.go(-1);

		},
    startUpload() {
      this.uploadInProgress = true;
      this.startTime = new Date();
      this.timer = setInterval(() => {
        this.currentTime = new Date();
      }, 1000);
    },
    endUpload() {
      clearInterval(this.timer);
      this.timer = null;
      this.startTime = null;
    },
    clearUpload() {
      this.csvUploadProgressMessage = "";
      this.csvUploadProgressPct = 0;
      this.startTime = null;
      this.timer = null;
      this.currentTime = null;
    },
    resetValidation() {
      this.$v.$reset();
    },
    initializeForm() {
      // If we have no saved upload maps, create one and add to list of maps
    },
    fieldValidationError(field) {
      return field.isRequired && !field.mappedLabel;
    },
    setExtraDropdownFields() {
      // Set extra dropdown fields
      let clientName = "";
      this.form.processorFacilityId = this.user.processorFacilityId || null;
      this.form.processorLobId = this.user.processorLobId || null;
      if (this.user.scope === "client") {
        this.form.clientFacilityId = this.user.clientFacilityId || null;
        this.form.clientLobId = this.user.clientLobId || null;
        clientName = this.user.client.clientName;
      } else {
        this.form.clientFacilityId =
          this.user.tenantSelectedClient.defaultClientFacilityId || null;
        this.form.clientLobId =
          this.user.tenantSelectedClient.defaultClientLobId || null;
        clientName = this.user.tenantSelectedClient.clientName;
      }
      // Other Default fields: Origin
      const foundFilter = this.filters.find(filter => filter.field === "origin");
      const clientGroup = foundFilter?.options.find(group => group.label === clientName)
      const defaultOption = clientGroup?.options.find(option => option.isDefault)
      if (defaultOption) {
        this.form.originId = defaultOption.originId;
      }
    },
    refreshSavedMaps() {
      this.$store.dispatch(`${this.namespace}/fetchUploadMaps`);
    },
    async refreshExtraOptions() {
      await this.$store.dispatch(`${this.namespace}/fetchExtraOptions`);
      this.setExtraDropdownFields();
    },
    async handleFileSelected(file) {
      if (file) {
        this.selectedFilename = file.name;
        this.selectedFile = file;
        if (!this.selectedFile) return;
        await this.populateArrays();
        this.$nextTick(() => {
          this.validateAllSavedMaps();
        });
      }
      this.csvUploadProgressMessage = "";
      this.csvUploadProgressPct = 0;
    },

    async populateArrays() {
      this.clearMappedFields();
      const reader = new FileReader();
      // Use a Promise to wrap the file reading and parsing
      await new Promise((resolve, reject) => {
        reader.onload = async (e) => {
          const csvContent = e.target.result;
          // Parse the CSV using PapaParse
          Papa.parse(csvContent, {
            header: true, // Indicates that the first row is the header
            transform: (value) => {
              // Check if the value is in scientific notation
              if (typeof value === "string" && value.includes("E+")) {
                const plainNumber = Number(value).toLocaleString("fullwide", { useGrouping: false });
                return plainNumber;
              }
              // Remove leading "=" and surrounding quotes, if present
              if (typeof value === "string") {
                return value.replace(/^="|^=|"$|'/g, "").trim();
              }
              return value;
            },
            complete: async (results) => {
              try {
                // Get the column headings
                this.csvHeadings = results.meta.fields;
                // Filter out rows where all values are "" or null
                const filteredData = results.data.filter((row) =>
                  Object.values(row).some(
                    (value) => value !== "" && value !== null
                  )
                );
                this.totalCsvRows = filteredData.length;
                // Wait for all the pill mapping to complete
                this.pills = await Promise.all(
                  this.csvHeadings.map((heading, index) => ({
                    id: index + 1,
                    incomingLabel: heading,
                    originalIndex: index,
                  }))
                );
                // Calculate percentage or rows filled with data for each column in pills
                this.calculatePillDataFill(filteredData);
                this.csvData = filteredData.slice(0, config.fileUploads.MAX_ROWS);
                this.calculatePillWidth();
                resolve();
              } catch (err) {
                reject(err);
              }
            },
            error: (err) => {
              console.error("Error parsing CSV:", err);
              reject(err);
            },
          });
        };
        // Trigger the reading of the file
        reader.readAsText(this.selectedFile);
      });
    },

    calculatePillDataFill(filteredData) {
      const totalRows = filteredData.length; // total number of columns
      this.pills.forEach((pill) => {
        let nonEmptyCount = 0;
        // Go through each field in filteredData
        filteredData.forEach((dataObj) => {
          if (dataObj[pill.incomingLabel] !== undefined) {
            const value = dataObj[pill.incomingLabel]; // dynamic key access
            if (value !== null && value !== "") {
              nonEmptyCount++; // Count non-empty, non-null values
            }
          }
        });
        // Calculate the percentage of fields with data for this pill
        pill.pctDataFilled = Number((nonEmptyCount / totalRows).toFixed(2));
      });
    }, 

    calculatePillWidth() {
      const maxWidth = Math.min(
        Math.max(...this.pills.map((p) => p.incomingLabel.length * 10), 100),
        250
      );
      this.pillWidth = `${maxWidth}px`;
    },

    pillStyle(pctDataFilled) {
      // Define the base style for the pill
      const baseStyle = {
        width: this.pillWidth, // Assign pillWidth from data
      };
      if (pctDataFilled === 1) {
        // Column filled with data 100%
        return {
          ...baseStyle,
          backgroundColor: '#009688',
          color: 'white',
          border: 'none',
        };
      } else if (pctDataFilled === 0) {
        // No data in column
        return {
          ...baseStyle,
          backgroundColor: 'white',
          color: '#005A51',
          border: '1px solid #ccc'
        };
      } else {
        // Some data in column
        return {
          ...baseStyle,
          backgroundColor: "white",
          border: '2px solid #009688',
          color: '#005A51',
          paddingBottom: '1px'
        };
      }
    },

    togglePillDropdown(id) {
      this.showPillDropdownId = this.showPillDropdownId === id ? null : id;
    },

    toggleFieldDropdown(id) {
      this.showFieldDropdownName = this.showFieldDropdownName === id ? null : id;
    },

    openCsvModal() {
      this.$refs.csvModal.showModal();
    },

    clearMappedFields() {
      this.$store.commit(`${this.namespace}/CLEAR_ALL_FILE_UPLOAD_MAP_FIELDS`);
      this.isMapDirty = false;
      this.isMapItemActive = false;
    },

    // ********************************************************
    //           Handle drag pills activity
    // ********************************************************
    // Handle dragging from pill list or field
    handleDragStart(pill, field = null) {
      this.draggedPill = pill;
      this.draggedPill.pctDataFilled = pill.pctDataFilled;
      this.draggedFromField = field;
    },

    // Handle dragging over a drop zone
    handleDragOver(field) {
      if (field.isHovered) return;
      this.$store.commit(`${this.namespace}/SET_FILE_UPLOAD_MAP_HOVERED`, {
        name: field.name,
        isHovered: true,
      });
    },
    // Handle dragging leaving the drop zone
    handleDragLeave(field) {
      if (!field.isHovered) return;
      this.$store.commit(`${this.namespace}/SET_FILE_UPLOAD_MAP_HOVERED`, {
        name: field.name,
        isHovered: false,
      });
    },

    // Handle dragging entering the pills drop zone
    handlePillsDragEnter() {
      this.isPillsHovered = true; // Mark pills drop zone as hovered
    },

    // Handle dragging leaving the pills drop zone
    handlePillsDragLeave() {
      this.isPillsHovered = false; // Unmark the pills drop zone
    },

    // Handle dropping into a field's drop-zone
    handleDrop(event, field) {
      this.isMapDirty = true;
      // If the target field already has a pill, move it back to the pills array
      if (field.mappedLabel) {
        // Add the existing pill back to the pills array at its original index
        const existingPill = field.mappedLabel;
        this.pills.splice(existingPill.originalIndex, 0, existingPill);
      }
      if (this.draggedFromField) {
        this.$store.commit(`${this.namespace}/CLEAR_FILE_UPLOAD_MAP_FIELD`, {
          name: this.draggedFromField?.name,
          pctDataFilled: this.draggedFromField?.pctDataFilled || this.draggedFromField?.mappedLabel?.pctDataFilled
        });
      }
      // Assign the new pill to the target field
      this.$store.commit(`${this.namespace}/SET_FILE_UPLOAD_MAP_FIELD`, {
        name: field.name,
        mappedLabel: this.draggedPill,
        pctDataFilled: this.draggedPill?.pctDataFilled || this.draggedPill?.mappedLabel?.pctDataFilled
      });
      // Remove the dragged pill from the pills array
      this.pills = this.pills.filter((p) => p.id !== this.draggedPill.id);
      // Clear the dragged pill reference
      this.draggedPill = null;
      this.draggedFromField = null;
      // Unmark the drop zone after dropping
      this.$store.commit(`${this.namespace}/SET_FILE_UPLOAD_MAP_HOVERED`, {
        name: field.name,
        isHovered: false,
      });
    },

    // Handle dropping back into the pill list
    handleDropBack() {
      // If the pill was dragged from a field
      if (this.draggedFromField) {
        // Add the pill back to the pills array at its original index
        const originalIndex = this.draggedPill.originalIndex;
        // Check if the pill is already in the pills array before inserting
        if (!this.pills.some((p) => p.id === this.draggedPill.id)) {
          this.pills.splice(originalIndex, 0, this.draggedPill); // Insert at original index
        }
        // Clear the pill from the original field
        this.$store.commit(`${this.namespace}/CLEAR_FILE_UPLOAD_MAP_FIELD`, {
          name: this.draggedFromField.name,
        });
      }
      // Clear dragged references
      this.draggedPill = null;
      this.isPillsHovered = false; // Unmark the pills drop zone after dropping
      this.isMapDirty = this.hasMappedFields;
    },

    // ******************************************************************
    //               Handle saved map list activity
    // ******************************************************************
    async handleAddFileUploadMap(itemTitle) {
      const uploadMapRecord = {
        id: -1,
        name: itemTitle,
        listName: this.listName,
        uploadMap: this.fields,
      };
      this.isMapDirty = false;
      // Save map to store
      const newMap = await this.$store.dispatch(
        `${this.namespace}/uploadMapSave`,
        uploadMapRecord
      );
      this.validateAllSavedMaps(newMap.id);
      this.isMapDirty = false;
      this.isMapItemActive = true;
    },
    async handleUploadMapTitle(payload) {
      const map = payload.savedMap;
      const name = payload.title;
      await this.$store.dispatch(`${this.namespace}/uploadMapTitleSave`, {
        id: map.id,
        title: name,
      });
      this.isMapDirty = false;
    },
    async handleUpdateFileUploadMap() {
      const foundRec = this.savedMaps.find((f) => f.id === this.mapId);
      if (foundRec) {
        // Update uploadMap
        const copyMap = { ...foundRec };
        copyMap.uploadMap = this.fields;
        // Save changes to saved map
        await this.$store.dispatch(`${this.namespace}/uploadMapSave`, copyMap);
        this.validateAllSavedMaps(this.mapId);
        this.isMapDirty = false;
        this.isMapItemActive = true;
      }
    },
    handleDeleteFileUploadMap(item) {
      // Delete map in store
      this.$store.dispatch(`${this.namespace}/uploadMapDelete`, item);
      this.clearMappedFields();
    },
    async handleDuplicateUploadMap(uploadMap) {
      const dupMap = JSON.parse(JSON.stringify(uploadMap));
      dupMap.id = -1;
      this.isMapDirty = false;
      delete dupMap.isActive;
      // Name
      dupMap.name = uploadUtils.generateUniqueName(dupMap.name, this.savedMaps);
      // Save map to store
      const newMap = await this.$store.dispatch(
        `${this.namespace}/uploadMapSave`,
        dupMap
      );
      this.handleActivateMap({ map: newMap, isActive: true });
    },
    validateAllSavedMaps(savedMapId) {
      // Cycle through saved maps and validate uploadMap for each
      this.savedMaps?.map((map) => {
        if (!savedMapId || savedMapId === map.id) {
          const comparedMap = {
            ...map,
            uploadMap: uploadUtils.validateMappedFields(
              map.uploadMap,
              this.pills,
              this.fields
            ),
          };
          this.$store.commit(
            `${this.namespace}/SAVE_UPLOAD_MAP_COMPARISON`,
            comparedMap
          );
        }
      });
    },
    async handleActivateMap(payload) {
      const map = payload.map;
      const isActive = payload.isActive;
      await this.populateArrays();
      this.$store.commit(`${this.namespace}/SET_SAVED_MAP_ACTIVE_FIELD`, {
        map: map,
        isActive: isActive,
      });
      if (!isActive) return;
      // Validate field mapping for this item once again for safety
      const comparedMap = {
        ...map,
        uploadMap: uploadUtils.validateMappedFields(
          map.uploadMap,
          this.pills,
          this.fields
        ),
      };
      this.$store.commit(
        `${this.namespace}/SAVE_UPLOAD_MAP_COMPARISON`,
        comparedMap
      );
      if (comparedMap?.uploadMap?.filter((field) => field.isMatch).length > 0) {
        // At least one mapped field is mapped: Apply map and remove labels from incoming csv pills
        const updatedFields = uploadUtils.applyMapToFields(
          map,
          this.pills,
          this.fields
        );
        this.$store.commit(
          `${this.namespace}/SET_FILE_UPLOAD_MAP_FIELDS`,
          updatedFields
        );
      } else {
        // No matches
        postMsgBoxOk(
          this,
          "File Upload Map Selected",
          `Upload map '${map.name}' has no mapped fields that match '${this.selectedFilename}'.`
        );
      }
      this.isMapItemActive = payload.isActive;
      this.mapId = payload.map.id;
      this.handleUpdateFileUploadMap();
    },

    handleUploadMapModal(payload) {
      this.uploadMapModalItems = payload.savedMap.uploadMap;
      this.uploadMapModalTitle = `Mapped Fields for '${payload.savedMap.name}'`;
      this.showUploadMapModal = true;
    },
    handleSavedMapReorder(event) {
      console.log("TODO: reorder saved maps: event: ", event);
      //TODO: Get drag to work on saved map items order
    },
    async handlePreviewFileUploadMap() {
      this.mappedCsvData = await uploadUtils.mapCsvData(
        this.fields,
        this.csvData
      );
      this.useCsvFields = this.fields;
      if (this.mappedCsvData && this.mappedCsvData.length > 0) {
        // Display csvData
        this.$refs.csvModalPreview.showModal();
      }
    },

    downloadCsvTemplate() {
      const headers = this.fields.map((field) => field.name);
      const csvContent = headers.join(",") + "\n";
      const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
      const link = document.createElement("a");
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", this.csvFileName);
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },

    getFieldState(data) {
      return handleValidateState(this, data);
    },

    async handleCsvFileUpload() {
      this.clearUpload();
      const baseValidationErrorMsg =
        "Some validation errors have been highlighted and need to be resolved before proceeding. Please fix these validation errors and try again.";
      if (!this.validateUploadMapRequired()) {
        // Validation errors: Stop
        await handleFixErrorsMsg(this, baseValidationErrorMsg);
        this.triggerRequiredFlash();
        this.clearUpload();
        return;
      } else if (!this.validateGenerateSku()) {
        // Sku is mapped but generate sku is checked
        const msg = "Generate SKU is checked AND the SKU field is also mapped, which is invalid. Please either uncheck 'Generate SKU' or unmap the SKU field and try again."
        await handleFixErrorsMsg(this, msg);
        return;
      } else {
        // Form is clean: Proceed with upload
        this.startUpload();
        const extraInfo = this.gatherBusinessInfo();
        this.mappedCsvData = await uploadUtils.mapCsvData(
          this.fields,
          this.csvData
        );
        // Validate data is in required columns in each row
        const rowValidateResult = this.validateEachRow();
        if (rowValidateResult) {
          const detailMsg =
            this.constructValidationErrorMessage(rowValidateResult);
          const useMessage = `${baseValidationErrorMsg}\n\n${detailMsg}`;
          await handleFixErrorsMsg(this, useMessage);
          this.triggerRequiredFlash();
          this.clearUpload();
          return;
        }
        // Now continue
        const totalData = this.mappedCsvData.map((row) => {
          return {
            ...extraInfo,
            ...row,
          };
        });
        // Assign a sku if directed
        if (this.form.generateSku) {
          const nextSku = await this.createSampleSku(false);
          const nextSkuRight = nextSku?.match(/-(\d{3})$/);
          let lastSkuNum = nextSkuRight ? Number(nextSkuRight[1]) : 0;
          lastSkuNum = lastSkuNum - 1;
          const nextSkuLeftMatch = nextSku?.match(/^(.*?)-\d{3}$/);
          const nextSkuLeft = nextSkuLeftMatch ? nextSkuLeftMatch[1] : "";
          // Sort totalData by productId
          totalData.sort((a, b) => {
            if (a.productId < b.productId) return -1;
            if (a.productId > b.productId) return 1;
            return 0;
          });
          // Generate Sku
          totalData.forEach((row, index) => {
            const skuNumber = (index + lastSkuNum + 1).toString().padStart(3, '0');
            row.sku = `${nextSkuLeft}-${skuNumber}`;
          });
        }
        let result;
        try {
          // Save to server without allowing duplicates initially
          result = await this.$store.dispatch(
            `${this.namespace}/uploadCsvFile`,
            {
              uploadData: totalData,
              filename: this.selectedFilename,
              allowDuplicates: false,
            }
          );
          this.endUpload()
        } catch (error) {
          if (error?.response?.data?.message.includes("CSV Error 01")) {
            this.clearUpload();
            // Handle filename duplicate error (CSV Error 01)
            const msg = `${error?.response?.data?.message}

            To guard against accidental duplicate records, a file with the same name cannot be uploaded. If this is a legitimate requirement, please rename the file and try the upload again.`;
            await handleFixErrorsMsg(this, msg);
            return;
          } else if (error?.response?.data?.message.includes("CSV Error 02")) {
            // Handle row-level duplicate error (CSV Error 02)
            let useMsg = error?.response?.data?.message;
            const msg = `${useMsg}  Do you want to override and upload anyway? (This would be unusual.)`;
            const userConfirmed = await handleConfirmChanges2({
              thisObj: this,
              message: msg,
              okVariant: "light",
              cancelVariant: "primary",
            });
            if (userConfirmed) {
              try {
                // Retry the API call with the allowDuplicates flag set to true
                result = await this.$store.dispatch(
                  `${this.namespace}/uploadCsvFile`,
                  {
                    uploadData: totalData,
                    filename: this.selectedFilename,
                    allowDuplicates: true,
                  }
                );
                this.endUpload();
              } catch (overrideError) {
                const msg = "Failed to upload CSV, even with override.";
                await handleFixErrorsMsg(this, msg);
                this.clearUpload()
                return;
              } 
            } else {
              // User declines
              this.clearUpload();
              return;
            }
            //this.endUpload();
          } else {
            // Unknown error
            console.error(error)
            const msg = `Unknown error uploading file: ${error?.response?.data?.message || error?.response?.data || error?.response || error}`
            await handleFixErrorsMsg(this, msg);
            console.error("Unknown error uploading file: ", error?.response?.data);
            this.clearUpload();
            return;
          }
        }
        // Tell user successfully uploaded file
        const msg = `Successfully uploaded ${result?.data?.count} records`;
        await this.toastMsgAdd(msg);
        // Reset next sku
        await this.createSampleSku(false);
      }
      this.$v.$reset();
    },

    constructValidationErrorMessage(errors) {
      if (!errors || errors.length === 0) {
        return "";
      }
      let message = `Missing required data (${errors.length} ${
        errors.length === 1 ? "error" : "errors"
      }):
      `;
      // Step 1: Take up to 10 errors
      const displayErrors = errors.slice(0, 10);
      // Step 2: Construct the message
      displayErrors.forEach((error) => {
        message += `• Row: ${error.row}, column: "${error.column}"\n`;
      });
      // Step 3: Add ellipsis if there are more than 6 errors
      if (errors.length > 10) {
        message += "...";
      }
      return message;
    },

    gatherBusinessInfo() {
      const extraInfo = {
        tenantId: this.tenantId,
        clientId: this.clientId,
        processorId: this.user.processorId,
        processorFacilityId: this.form.processorFacilityId,
        processorLobId: this.form.processorLobId,
        clientFacilityId: this.form.clientFacilityId,
        clientLobId: this.form.clientLobId,
        originId: this.form.originId,
      };
      return extraInfo;
    },

    validateUploadMapRequired() {
      this.$v.$touch();
      // Check required fields in target upload table
      const requiredFields = this.fields.filter(
        (field) => field.isRequired && !field.mappedLabel
      );
      if (requiredFields && requiredFields.length > 0) {
        return false;
      }
      // Check that data has values in every required field on every row
      return !this.$v.$invalid;
    },

    validateGenerateSku() {
      // If generateSku is checked AND SKU is mapped: stop
      const skuField = this.fields.find(x => x.label === 'SKU');
      if (skuField && skuField.mappedLabel && this.form.generateSku) {
        return false;        
      }
      return true;
    },

    validateEachRow() {
      const mapRecord = this.savedMaps.find((map) => map.id === this.mapId);
      // Step 1: Find required columns
      const requiredColumns = mapRecord.uploadMap
        .filter((col) => col.isRequired)
        .map((col) => col.mappedLabel);
      // Step 2: Initialize the error array
      const errors = [];
      // Step 3: Loop through mappedCsvData
      this.csvData.forEach((rowData, rowIndex) => {
        // Skip blank row
        const isBlankRow = Object.values(rowData).every(
          (value) => value === null || value === undefined || value === ""
        );
        if (isBlankRow) {
          return;
        }
        requiredColumns.forEach((colName) => {
          // Step 4: Check if required column has data
          if (
            rowData[colName] === null ||
            rowData[colName] === undefined ||
            rowData[colName] === ""
          ) {
            errors.push({
              row: rowIndex + 2, // Adjust for column heading row
              column: colName,
              message: `Missing required data in column '${colName}' on row ${
                rowIndex + 1
              }`,
            });
          }
        });
      });
      // Step 5: Return error array or false if no errors
      return errors.length > 0 ? errors : false;
    },

    triggerRequiredFlash() {
      this.requiredFlagHighlight = true;
      setTimeout(() => {
        this.requiredFlagHighlight = false;
      }, 7000);
    },

    updateMapContainerHeight(isInitialLoad) {
      const resizeOffset = isInitialLoad ? 15 : 40;
      const div = this.$refs.mapScrollableContainerRef;
      if (div) {
        const rect = div.getBoundingClientRect();
        const height = `${window.innerHeight - rect.top - resizeOffset}px`
        div.style.height = height;
      }
    }
  },
};
</script>

<style scoped>

.main-container {
  background-color: white;
  width: 80%;
  margin: 0 auto;
  margin-bottom: 0;
  padding-bottom: 15px;
  overflow: hidden;
  border: 1px solid #ddd;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
}
@media(max-width: 1670px) {
  .main-container {
    width: 100%;
  }
}

.fields-container {
  margin: 20px auto;
  padding-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: #f9f9f9;
}

.subtitle,
.fields-sub-title,
.saved-map-title,
.current-map-title,
.map-title {
  font-weight: 600;
  font-size: 1.2rem;
  color: rgb(83, 83, 83);
}

.map-title {
  font-weight: 700;
  font-size: 1.7rem;
  margin-bottom: 0;
  margin-top: 20px;
}

.current-map-section {
  height: 80px;
}

.current-map-title {
  margin-top: 20px;
  margin-bottom: 0;
}

.fields-sub-title {
  display: flex;
  align-items: center;
  text-align: left;
  background-color: #e9e9e9;
  height: 35px;
}

.fields-sub-title-left {
  margin-left: -10px;
}

.field-map-container {
  display: flex;
  justify-content: flex-start;

}

.fields {
  height: 100%;
  width: 100%;
  margin-left: 10px;
}

.field-label-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-right: 10px;
}

.field-label {
  margin-right: 5px;
  font-size: 0.9rem;
}

.required-flag {
  font-size: 0.6rem;
  color: rgb(237, 35, 35);
}

.field-item {
  display: flex;
  justify-content: space-between;
  margin: 0px 0;
  padding-top: 5px;
  padding-bottom: 5px;
}

.pills-drop-zone {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  max-height: 677px;
  align-items: flex-start;
  width: 98%;
  border: 2px dashed #ccc;
  border-radius: 4px;
  margin-top: 4px;
  margin-bottom: 5px;
  padding: 10px;
  padding-bottom: 5px;
  transition: background-color 0.3s ease;

}

.pills-drop-zone.highlight-drop {
  background-color: rgba(0, 123, 255, 0.1);
}

.fields-drop-zone,
.fields-drop-zone-required {
  width: 350px;
  min-width: 180px;
  height: 28px;
  border: 2px dashed #ccc;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  transition: background-color 0.3s; /* Smooth transition for background color */
}

.fields-drop-zone-required {
  border: 2px dashed rgba(164, 77, 77, 0.5);
}

.fields-drop-zone.highlight-drop {
  border-color: #3f51b5;
  background-color: rgba(63, 81, 181, 0.1);
}

.file-browser {
  margin: 10px 0px;

}

.pill {
  font-size: 15px;
  background-color: #009688;
  color: white;
  border-radius: 25px;
  cursor: grab;
  text-align: center;
  display: block;
  margin: 4px;
  padding-top: 1px;
  font-weight: 500;
  height: 25px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
}

.pill:hover {
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
}

.upload-footer {
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  border-bottom: 1px solid #ddd;
  border-left: 1px solid #ddd;
  border-right: 1px solid #ddd;
}
.upload-footer-button {
  margin-left: 0px;
  margin-right: 0px;
}
.map-confirm-dialog {
  z-index: 9999;
}
.show-data-button {
  font-size: 0.8rem;
}

.total-csv-rows {
  font-size: 1rem;
}

.modal-position {
  z-index: 1050; /* Ensures it's above other elements */
}

.upload-map-modal-title {
  font-size: 1.3rem;
  font-weight: 600;
  color: #606060;
}

.map-scrollable-container {
  overflow-y: auto;
  overflow-x: hidden;
  padding: 10px 15px;;
  padding-top: 0;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.extra-label {
  font-size: 0.9em;
  width: 250px;
}
.extra-select {
  margin-bottom: 3px;
  margin-top: 3px;
}
.extra-select:disabled {
  color: black;
}

.required-flag-highlight {
  font-weight: 700;
  animation: flash 1s linear infinite;
}

@keyframes flash {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.2; /* Adjust this value for more or less flashing */
  }
  100% {
    opacity: 1;
  }
}

.pillDropdown {
  position: absolute;
  background-color: white;
  border: 1px solid #ccc;
  color: rgb(74, 74, 74);
  border-radius: 4px;
  padding: 5px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
  z-index: 10;
}

.progress-text {
  font-size: .9rem;
}

.main-list-container {
  border: 1px solid #ddd;
} 

.fields-scrollable-container {
  overflow-y: auto;
  overflow-x: hidden;
  height: var(--main-list-height);
}

.sample-sku {
  font-size: .875rem;
  color: white;
  background-color: #6c7570;
  border-radius: 5px;
  padding-left: 3px;
  padding-right: 3px;
}
</style>
