<template class="template">
  <b-overlay
    variant="white"
    :show="isLoading"
    :opacity="0.0"
    spinner-variant="info"
    spinner-type="none"
    class="mx-auto sidebar-home-overlay"
  >
    <div class="sidebar-wrapper d-flex" >
      <!-- Show sidebar -->
      <b-button
        size="sm"
        class="sidebar-unhide-button"
        variant="light"
        v-if="sidebarHidden"
        @click.prevent="unhideSidebar"
      >
        <i class="fas fa-thumbtack unpin-icon sidebar-hide-icon"></i>
      </b-button>
      <!-- Side nav for Amazon -->
      <div
        class="sidebar-body p-1 sidebar-border"
        :class="{ 'sidebar-hidden': sidebarHidden }"
      >
        <b-nav vertical class="sidebar-nav sidebar">
          <!-- Hide sidebar button -->
          <b-button
            size="sm"
            variant="light"
            class="ml-auto mb-0 sidebar-hide-button"
            @click.prevent="hideSidebar"
          >
            <i class="fas fa-thumbtack sidebar-hide-icon"></i>
          </b-button>
          <!-- Header -->
          <div class="pl-3">
            <h5>Amazon Information</h5>
          </div>
          <hr class="mt-1" />
          <!-- Amazon Shipments -->
          <b-nav-item
            ref="amazon-shipments"
            exact-active-class="nav-item-active"
            :to="{ name: 'AmazonInboundShipments' }"
            disabled
          >
            Inbound Shipments</b-nav-item
          >
          <hr />
          <!-- Amazon Returns -->
          <b-nav-item
            ref="amazon-returns"
            exact-active-class="nav-item-active"
            :to="{ name: 'AmazonReturns' }"
            disabled
          >
            Returns</b-nav-item
          >
          <!-- Amazon Reimbursments -->
          <b-nav-item
            ref="amazon-reimbursements"
            exact-active-class="nav-item-active"
            :to="{ name: 'AmazonReimbursements' }"
            disabled
          >
            Reimbursements</b-nav-item
          >
          <!-- Amazon Removals -->
          <b-nav-item
            ref="amazon-removals"
            exact-active-class="nav-item-active"
            :to="{ name: 'AmazonRemovals' }"
            disabled
          >
            Removals</b-nav-item
          >
        </b-nav>
      </div>
      <!-- Main content area -->
      <div id="amazon-main" class="main-content p-0 flex-grow-1 m-0">
        <BaseBreadcrumb class="border-left"/>
        <router-view></router-view>
      </div>
    </div>
  </b-overlay>
</template>

<script>
// Links in this navigation view reference route names.
// In router (index.js), passes name as prop to component, which passes to store fetchAll.
import { mapGetters } from "vuex";

// Bootstrap sidebar menu
export default {
  components: {},
  props: {},
  data() {
    return {
      namespace: "Amazon",
      refreshTooltip: "Click to refresh summary",
 };
  },
  async created() {},
  mounted() {
  },
  computed: {
    ...mapGetters({
      isLoading: "Session/isLoading"
    }),
    ...mapGetters("Session", ["isAuthorized", "user"]),
    myOrg() {
      return this.user.clientName;
    },
    sidebarHidden: {
      set(sidebarHidden) {
        this.$store.dispatch("Amazon/sidebarHidden", sidebarHidden);
      },
      get() {
        return this.$store.state["Amazon"].sidebarHidden;
      }
    }
  },
  methods: {
    setTitle(text) {
      this.$store.dispatch(`Session/setSubNav`, text);
    },
    hideSidebar() {
      this.sidebarHidden = true;
    },
    unhideSidebar() {
      this.sidebarHidden = false;
    }
  }
};
</script>

<style lang="css" scoped>
.active {
  background-color: #f5f5f6;
}

</style>