<template>
  <div>
    <b-button @click="handleUp">Up</b-button>
    <span>{{ vote }}</span>
    <b-button @click="handleDown">Down</b-button>
  </div>
</template>

<script>
export default {
  props: {
    // variant: {
    //   type: String,
    //   default: "danger",
    // },
  },
  data() {
    return {
      vote: 0
    };
  },
  created() {
    this.$store.dispatch("Notification/alertShowSet", false);
  },
  computed: {
    state() {
      return this.$store.state[this.namespace];
    },
    alertState() {
      return this.$store.state["Notification"].alert;
    },
    show: {
      set(show) {
        this.$store.dispatch("Notification/alertShowSet", show);
      },
      get() {
        return this.$store.state["Notification"].alert.show && this.timeout;
      }
    }
  },
  methods: {
    handleUp() {
      this.vote++;
    },
    handleDown() {
      this.vote--;
    }
  }
};
</script>

<style lang="css" scoped>
</style>