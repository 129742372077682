<template>
  <b-container>
    <h2 class="m-2 text-center">Admin</h2>
    <b-button @click="getUsers">Get Users</b-button>
    <p>{{ users.data }}</p>
  </b-container>
</template>

<script>
import { mapState, mapActions } from "vuex";
export default {
  async mounted() {
    // runs when the element is injected into the browser
    this.setSubNav("Admin");
  },
  computed: {
    ...mapState("User", ["users"]),
  },
  methods: {
    ...mapActions("Session", ["setSubNav"]),
    async getUsers() {
      await this.$store.dispatch("User/fetchUsers");
    },
  },
};
</script>

<style lang="css" scoped>
</style>