import { render, staticRenderFns } from "./AmazonHome.vue?vue&type=template&id=43f3a5fc&scoped=true&class=template"
import script from "./AmazonHome.vue?vue&type=script&lang=js"
export * from "./AmazonHome.vue?vue&type=script&lang=js"
import style0 from "./AmazonHome.vue?vue&type=style&index=0&id=43f3a5fc&prod&lang=css&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "43f3a5fc",
  null
  
)

export default component.exports