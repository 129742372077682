<template>
  <b-modal id="profile-modal" title="User Profile" hide-footer>
    <b-container class="p-3">
      <div class="pt-2">
        <table class="custom-table">
          <tbody>
            <tr v-for="(item, index) in items" :key="index">
              <td class="key">{{ item.key }}</td>
              <td class="value">{{ item.value }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </b-container>
  </b-modal>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters({
      user: "Session/user"
    }),
    items() {
      if (this.user) {
        return [
          { key: "Name:", value: `${this.user?.firstName} ${this.user?.lastName}` },
          { key: "User id:", value: this.user?.userId },
          { key: "Phone number:", value: this.user?.phoneNumber },
          { key: "Security role:", value: this.user?.AuthRole?.roleName },
          { key: "Client name:", value: this.user?.Client?.clientName },
          { key: "Client facility:", value: this.user?.ClientFacility?.facilityName },
          { key: "Processor name:", value: this.user?.Processor?.processorName },
          { key: "Processor facility:", value: this.user?.ProcessorFacility?.facilityName },
          { key: "Separator", value: "" }, // Row with separator class
          { key: "Client admin:", value: this.user?.isClientAdmin },
          { key: "Tenant admin:", value: this.user?.isTenantAdmin },
          { key: "App admin:", value: this.user?.isAppAdmin },
          { key: "Scope:", value: this.user?.scope },
          { key: "Client id:", value: this.user?.clientId },
          { key: "Tenant id:", value: this.user?.tenantId },
          {
            key: "Date created:",
            value: new Date(this.user.dateEnrolled).toLocaleDateString()
          }
        ];
      } else {
        return [];
      }
    }
  }
};
</script>

<style scoped>
.custom-table {
  width: 100%;
  border-collapse: collapse;
}

.custom-table td {
  padding: 0.1rem; /* Adjust as needed */
}

.key {
  width: 30%; /* Adjust based on your needs */
  text-align: right;
  font-weight: bold;
}

.value {
  width: 70%; /* Adjust based on your needs */
  text-align: left;
}

.separator {
  border-top: 1px solid #ccc; /* Horizontal rule style */
  margin: 1rem 0; /* Space above and below */
}

.custom-table tr.separator td {
  border: none; /* No border for separator row */
  background-color: #f9f9f9; /* Optional: Change background color */
}
</style>
