var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-card',{staticClass:"card-db shadow border mx-0 my-3",class:{
    'card-red': _vm.redGreenIndicator && parseInt(_vm.value) < 0,
    'card-green': _vm.redGreenIndicator && parseInt(_vm.value) >= 0,
  },staticStyle:{"cursor":"pointer"},on:{"click":_vm.clickedValue}},[_c('b-card-body',{staticClass:"p-0 cursor-pointer"},[_c('div',{},[_c('div',{staticClass:"d-flex align-items-baseline justify-content-center"},[_c('b-link',{on:{"click":_vm.clickedValue}},[_c('h4',[_vm._v(_vm._s(_vm.value))])]),_c('span',{staticClass:"ml-1 text-muted text-smaller"},[_vm._v(_vm._s(_vm.uom))])],1),_c('h5',{},[_vm._v(_vm._s(_vm.title))]),_c('hr',{staticClass:"p-0 my-2",class:{
          'card-red': _vm.redGreenIndicator && parseInt(_vm.value) < 0,
          'card-green': _vm.redGreenIndicator && parseInt(_vm.value) >= 0,
        }}),_c('p',{staticClass:"text-muted"},[_vm._v(_vm._s(_vm.description))])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }