<!--
  Author: Dane Iracleous <daneiracleous@gmail.com>
  Repository: https://github.com/diracleo/vue-enlargeable-image
-->
<template>
  <div
    v-bind:class="{ 'enlargeable-image': true, active: state != 'delarged' }"
  >
    <div
      class="slot"
      ref="slot"
      v-on="trigger === 'click'
          ? { click: enlarge }
          : { mouseenter: enlarge, mouseleave: reset }
      "
    >
      <slot>
        <img :src="src" class="default" />
      </slot>
    </div>
    <div
      class="full"
      v-bind:style="styles"
      v-bind:class="state"
      v-on="trigger === 'click' ? { click: reset } : {}"
    >
      <div class="small-image">
        <img
          v-if="
            state !== 'enlarged' &&
            state !== 'enlarging' &&
            state !== 'delarging'
          "
          :src="src"
        />
      </div>
      <img v-if="state === 'enlarged'" :src="src_large" />
    </div>
  </div>
</template>

<script>
export default {
  name: "EnlargeableImage",
  props: {
    src: {
      type: String
    },
    src_large: {
      type: String
    },
    animation_duration: {
      type: String,
      default: "300"
    },
    trigger: {
      type: String,
      default: "click"
    }
  },
  data() {
    return {
      state: "delarged", // Default value
      styles: {}, // Default value
      delay: 50,
      adjust_top: 0,
      wait: false,
      transition_value: ""
    };
  },
  methods: {
    init() {
      var self = this;
      self.state = "delarged";
      self.delay = 50;
      self.adjust_top = 0;
      self.wait = false;
      var transition_seconds = parseInt(self.$props.animation_duration) / 1000;
      if (transition_seconds == 0) {
        self.delay = 0;
      }
      transition_seconds = transition_seconds.toFixed(2);
      self.transition_value =
        "width " +
        transition_seconds +
        "s, height " +
        transition_seconds +
        "s, top " +
        transition_seconds +
        "s, left " +
        transition_seconds +
        "s, background-color " +
        transition_seconds +
        "s";
      self.styles = {
        transition: self.transition_value
      };
      if (self.$props.trigger == "hover") {
        self.styles.pointerEvents = "none";
      }
    },
mounted: function () {
  this.init();
},
enlarge() {
  this.$nextTick(() => {
    var rect = this.$refs.slot.getBoundingClientRect();
    this.styles = {
      position: "fixed",
      left: Math.round(rect.left) + "px",
      top: Math.round(rect.top + this.adjust_top) + "px",
      width: Math.round(rect.right - rect.left) + "px",
      height: Math.round(rect.bottom - rect.top) + "px",
      backgroundImage: `url(${this.src})`, // Changed to this.src
      transition: this.transition_value
    };
    if (this.trigger == "hover") {
      this.styles.pointerEvents = "none";
    }
    this.state = "enlarging";
    if (this.timer) {
      clearTimeout(this.timer);
    }
    this.timer = setTimeout(() => {
      this.$emit("enlarging");
      this.styles = {
        backgroundImage: `url(${this.src})`, // Changed to this.src
        transition: this.transition_value
      };
      if (this.trigger == "hover") {
        this.styles.pointerEvents = "none";
      }
      if (this.timer) {
        clearTimeout(this.timer);
      }
      this.timer = setTimeout(() => {
        this.state = "enlarged";
        this.$emit("enlarged");
      }, this.animation_duration);
    }, this.delay);
  });
},
    reset() {
      if (this.state !== "delarging") {
        var rect = this.$refs.slot.getBoundingClientRect();
        if (this.timer) {
          clearTimeout(this.timer);
        }
        this.timer = setTimeout(() => {
          this.state = "delarging";
          this.$emit("delarging");
          this.styles = {
            backgroundImage: `url(${this.src})`, // Changed to this.src
            position: "fixed",
            left: Math.round(rect.left) + "px",
            top: Math.round(rect.top + this.adjust_top) + "px",
            width: Math.round(rect.right - rect.left) + "px",
            height: Math.round(rect.bottom - rect.top) + "px",
            transition: this.transition_value
          };
          if (this.trigger == "hover") {
            this.styles.pointerEvents = "none";
          }
          if (this.timer) {
            clearTimeout(this.timer);
          }
          this.timer = setTimeout(() => {
            this.state = "delarged";
            this.$emit("delarged");
          }, this.animation_duration);
        }, 0);
      } else {
        this.enlarge();
      }
    }
  },
};
</script>

<style scoped>
img {
  box-shadow: rgba(100, 100, 111, 0.2) 5px 7px 7px 0px;
  border: 1px solid #ccc;
}
/* your passed-in element */
.enlargeable-image > .slot {
  display: inline-block;
  max-width: 100%;
  max-height: 100%;
  cursor: zoom-in;
}
/* default img if no element passed in */
.enlargeable-image > .slot > img.default {
  max-width: 100%;
  vertical-align: middle;
}
/* passed-in element when growth is happening */
.enlargeable-image.active > .slot {
  opacity: 0.3;
  filter: grayscale(100%);
}
/* full version that grows (background image allows seamless transition from thumbnail to full) */
.enlargeable-image .full {
  cursor: zoom-out;
  background-color: transparent;
  align-items: center;
  justify-content: center;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  display: none;
}
.enlargeable-image .full > img {
  object-fit: contain;
  width: 100%;
  height: 100%;
}
/* full version while getting bigger */
.enlargeable-image .full.enlarging {
  display: flex;
  position: fixed;
  left: 0px;
  top: 110px;
  width: 100%;
  height: 90%;
  background-color: transparent;
  cursor: zoom-out;
  z-index: 3;
}
/* full version while at its peak size */
.enlargeable-image .full.enlarged {
  display: flex;
  position: fixed;
  left: 0px;
  top: 110px;
  width: 100%;
  height: 90%;
  background-color: transparent;
  cursor: zoom-out;
  z-index: 2;
}
/* full version while getting smaller */
.enlargeable-image .full.delarging {
  display: flex;
  position: fixed;
  left: 0px;
  top: 110px;
  width: 100%;
  height: 100%;
  background-color: transparent;
  cursor: zoom-in;
  z-index: 1;
}
</style>