<template>
  <div>
    <!-- Tables -->
    <b-row align-v="start">
      <b-col>
        <b-container class="mb-3">
          <div>
            <b-overlay
              variant="white"
              :show="isLoading"
              :opacity="0.0"
              spinner-variant="info"
              spinner-small
            >
              <div id="receive-summary">
                <div class="d-flex">
                  <h5 class="mt-2">OAR Received 30d</h5>
                  <!-- Refresh -->
                  <b-button
                    variant="light"
                    class="ml-auto mb-1 mt-0"
                    :title="refreshTooltip"
                    @click.prevent="refreshRs"
                  >
                    <i class="fas fa-sync-alt refresh-icon-rs"></i>
                  </b-button>
                </div>
                <b-table
                  :items="receive30dSummary"
                  :fields="fieldsRs"
                  caption="Past year's ordered and received activity"
                  details-td-class=""
                  responsive="sm"
                  hover
                  outlined
                  small
                >
                  <template slot="custom-foot">
                    <!-- Total -->
                    <b-tr :style="'font-weight: 700'" class="footer-row">
                      <b-td> Total </b-td>
                      <b-td class="text-right">{{
                        totalOrdered().toLocaleString()
                      }}</b-td>
                      <b-td class="text-right">{{
                        totalReceived().toLocaleString()
                      }}</b-td>
                      <b-td class="text-right">{{
                        totalFlowRate().toLocaleString()
                      }}</b-td>
                    </b-tr>
                    <!-- Avg -->
                    <b-tr :style="'font-weight: 700'">
                      <b-td> Avg </b-td>
                      <b-td class="text-right">{{
                        averageOrdered().toLocaleString()
                      }}</b-td>
                      <b-td class="text-right">{{
                        averageReceived().toLocaleString()
                      }}</b-td>
                      <b-td class="text-right">{{
                        averageFlowRate().toLocaleString()
                      }}</b-td>
                    </b-tr>
                  </template>
                </b-table>
              </div>
            </b-overlay>
            <!-- </b-card-body>
            </b-collapse> -->
          </div>
        </b-container>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  components: {},
  props: {},
  data() {
    return {
      namespace: "Dashboard",
      refreshTooltip: "Click to refresh summary",
      // Receive summary
      fieldsRs: [
        {
          key: "yearMonth",
          label: "Month",
          sortable: false,
          class: "col-130 min-width-col"
        },
        // {
        //   key: "totalBuyCost",
        //   label: "Spent",
        //   sortable: false,
        //   class: "text-right",
        //   formatter: value => {
        //     return `$${Number(value).toLocaleString()}`;
        //   }
        // },
        {
          key: "orderedQty",
          label: "Ordered",
          sortable: false,
          class: "text-right",
          formatter: value => {
            return Number(value).toLocaleString();
          }
        },
        {
          key: "receivedQty",
          label: "Received",
          sortable: false,
          class: "text-right",
          formatter: value => {
            return Number(value).toLocaleString();
          }
        },
        {
          key: "flowRate",
          label: "Flow Rate",
          sortable: false,
          class: "text-right",
          formatter: value => {
            return Number(value).toLocaleString();
          }
        }
      ]
    };
  },
  async created() {
    this.refreshRs();
  },
  async mounted() {},
  beforeDestroy() {},
  async activated() {},
  computed: {
    ...mapGetters({
      isLoading: "Session/isLoading"
    }),
    ...mapGetters("Session", ["isAuthorized"]),
    ...mapGetters("Dashboard", ["receive30dSummary", "isLoadingRs"])
  },
  methods: {
    async refreshRs() {
      await this.$store.dispatch(`${this.namespace}/fetchReceive30dSummary`);
    },
    totalOrdered() {
      return this.receive30dSummary?.reduce(
        (acc, curr) => acc + Number(curr.orderedQty),
        0
      );
    },
    averageOrdered() {
      return parseInt(this.totalOrdered() / this.receive30dSummary.length);
    },
    totalReceived() {
      return this.receive30dSummary?.reduce(
        (acc, curr) => acc + Number(curr.receivedQty),
        0
      );
    },
    averageReceived() {
      return parseInt(this.totalReceived() / this.receive30dSummary.length);
    },
    totalFlowRate() {
      return this.receive30dSummary?.reduce(
        (acc, curr) => acc + Number(curr.flowRate),
        0
      );
    },
    averageFlowRate() {
      return parseInt(this.totalFlowRate() / this.receive30dSummary?.length);
    }

    // async refreshPa() {
    //   await this.$store.dispatch(`${this.namespace}/fetchPendingAging`);
    // }
  }
};
</script>

<style lang="css" scoped>
#receive-summary.btn {
  background-color: transparent;
  border: none;
  padding: 0px;
  margin: 0px;
  margin-top: 0px;
  margin-bottom: 0px;
  transition: 0.2s;
}
#receive-summary.btn:hover {
  transform: scale(1.2, 1.2);
}
.refresh-icon-rs {
  font-size: 1.1em;
  vertical-align: middle;
  color: rgb(192, 189, 189);
}
.footer-row > td {
  border-top: 2px solid #ddd !important;
}
</style>