import _ from "lodash";
import pako from "pako";

// Deep compare of two arrays, sorted by passed in property
export function areEqual(arr1, arr2, sortProperty) {
  return _.areEqual(_.sortBy(arr1, sortProperty), _.sortBy(arr2, sortProperty));
}

export function isObjectEmpty(obj) {
  if (typeof obj !== "object") {
    throw new Error("Input is not an object");
  }
  //return Object.entries(obj).length === 0
  // Below twice as fast
  for (let i in obj) return false;
  return true;
}

export function addMinutes(minutesToAdd) {
  const currentDate = new Date();
  const futureDate = new Date(currentDate.getTime() + minutesToAdd * 60000);
  return futureDate;
}

export function timeDifference(oldDate) {
  var oResult = {};
  var oToday = new Date();
  var nDiff = oToday.getTime() - oldDate.getTime();
  // Get diff in days
  oResult.days = Math.floor(nDiff / 1000 / 60 / 60 / 24);
  nDiff -= oResult.days * 1000 * 60 * 60 * 24;
  // Get diff in hours
  oResult.hours = Math.floor(nDiff / 1000 / 60 / 60);
  nDiff -= oResult.hours * 1000 * 60 * 60;
  // Get diff in minutes
  oResult.minutes = Math.floor(nDiff / 1000 / 60);
  nDiff -= oResult.minutes * 1000 * 60;
  // Get diff in seconds
  oResult.seconds = Math.floor(nDiff / 1000);

  // Render the diffs into friendly duration string
  // Days
  var sDays = "00";
  if (oResult.days > 0) {
    sDays = String(oResult.days);
  }
  if (sDays.length === 1) {
    sDays = "0" + sDays;
  }

  // Format Hours
  var sHour = "00";
  if (oResult.hours > 0) {
    sHour = String(oResult.hours);
  }
  if (sHour.length === 1) {
    sHour = "0" + sHour;
  }

  //  Format Minutes
  var sMins = "00";
  if (oResult.minutes > 0) {
    sMins = String(oResult.minutes);
  }
  if (sMins.length === 1) {
    sMins = "0" + sMins;
  }

  //  Format Seconds
  var sSecs = "00";
  if (oResult.seconds > 0) {
    sSecs = String(oResult.seconds);
  }
  if (sSecs.length === 1) {
    sSecs = "0" + sSecs;
  }

  //  Set Duration
  var sDuration = sDays + ":" + sHour + ":" + sMins + ":" + sSecs;
  oResult.duration = sDuration;

  // Set friendly text for printing
  if (oResult.days === 0) {
    if (oResult.hours === 0) {
      if (oResult.minutes === 0) {
        var sSecHolder = oResult.seconds > 1 ? "Seconds" : "Second";
        oResult.friendlyNiceText = oResult.seconds + " " + sSecHolder + " ago";
      } else {
        var sMinutesHolder = oResult.minutes > 1 ? "Minutes" : "Minute";
        oResult.friendlyNiceText =
          oResult.minutes + " " + sMinutesHolder + " ago";
      }
    } else {
      var sHourHolder = oResult.hours > 1 ? "Hours" : "Hour";
      oResult.friendlyNiceText = oResult.hours + " " + sHourHolder + " ago";
    }
  } else {
    var sDayHolder = oResult.days > 1 ? "Days" : "Day";
    oResult.friendlyNiceText = oResult.days + " " + sDayHolder + " ago";
  }

  return oResult;
}

export function getType(p) {
  if (Array.isArray(p)) return "array";
  else if (typeof p == "string") return "string";
  else if (p != null && typeof p == "object") return "object";
  else return "other";
}

// export function titleCase(str) {
//   return str.replace(/\w\S*/g, t => {
//     return t.charAt(0).toUpperCase() + t.substr(1).toLowerCase()
//   })
// }

export function titleize(str) {
  let upper = true;
  let newStr = "";
  for (let i = 0, l = str.length; i < l; i++) {
    // Note that you can also check for all kinds of spaces  with
    // str[i].match(/\s/)
    if (str[i] === " ") {
      // Character is a space; next character should be capitalized
      upper = true;
      newStr += str[i];
      continue;
    } else if (str[i] === "_") {
      // Character is an underscore; next character should be capitalized
      upper = true;
      newStr += str[i];
      continue;
    } else if (str[i] === str[i].toUpperCase()) {
      // Character is already upper case; keep it
      newStr += str[i];
      continue;
    }

    newStr += upper ? str[i].toUpperCase() : str[i].toLowerCase();
    upper = false;
  }
  return newStr.replace(/_/g, " ");
}
// test

export function truncate(str, n) {
  if (!str) return;
  return str.length > n ? str.substr(0, n - 1) + "&hellip;" : str;
}

export const compressFile = async (input) => {
  let fileContent;
  // Check if input is a Blob (i.e., a file)
  if (input instanceof Blob) {
    // Handle file input: read file as text
    const reader = new FileReader();
    return new Promise((resolve, reject) => {
      reader.onload = () => {
        fileContent = reader.result;
        const compressed = pako.gzip(fileContent);
        resolve(new Blob([compressed], { type: "application/gzip" }));
      };
      reader.onerror = reject;
      reader.readAsText(input); // Read file content as text
    });
  }
  // Check if input is a JSON array
  if (Array.isArray(input)) {
    // Handle JSON array input: stringify and compress it
    const jsonString = JSON.stringify(input);
    const compressed = pako.gzip(jsonString);
    return new Blob([compressed], { type: "application/gzip" });
  }
  // If input is neither a file nor a JSON array, throw an error
  throw new Error("Expected input to be either a Blob (file) or a JSON array.");
};
