<template>
  <div>
    <!-- Tables -->
    <b-row align-v="start">
      <b-col>
        <b-container class="mb-3">
          <div>
            <!-- Pending aging -->
            <b-overlay
              variant="white"
              :show="isLoading"
              :opacity="0.0"
              spinner-variant="info"
              spinner-small
            >
              <div class="" id="pa-table">
                <div class="d-flex">
                  <h5 class="mt-2">Pending > 21d</h5>
                  <b-button
                    variant="light"
                    class="ml-auto mb-1 mt-0"
                    :title="refreshTooltip"
                    @click.prevent="refreshPa"
                  >
                    <i class="fas fa-sync-alt refresh-icon-rs"></i>
                  </b-button>
                </div>
                <b-table
                  size="sm"
                  :items="pendingAging30d"
                  :fields="fieldsPa"
                  caption="All units pending for more than 21 days"
                  hover
                  outlined
                  small
                >
                  <template slot="custom-foot">
                    <!-- Total -->
                    <b-tr :style="'font-weight: 700'" class="footer-row">
                      <b-td> Total </b-td>
                      <b-td class="text-right">{{
                        totalPending().toLocaleString()
                      }}</b-td>
                    </b-tr>
                  </template>
                </b-table>
              </div>
            </b-overlay>
          </div>
          <!-- OAR Receive List - > 30d -->
          <div class="d-flex">
            <b-button
              class="mx-auto"
              variant="primary"
              :style="{}"
              :to="{ name: 'PurchasedItemReceive21d' }"
              v-if="isAuthorized('OarPending30dMenu', 'read')"
            >
              See Detail
            </b-button>
          </div>
        </b-container>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  components: {},
  props: {},
  data() {
    return {
      namespace: "Dashboard",
      refreshTooltip: "Click to refresh summary",
      // Pending aging
      fieldsPa: [
        {
          key: "yearMonth",
          label: "Month",
          sortable: false
        },
        {
          key: "status",
          label: "Status",
          sortable: false
        },
        {
          key: "pendingQty",
          label: "Pending Units",
          sortable: false,
          class: "text-right",
          formatter: value => {
            return Number(value).toLocaleString();
          }
        }
      ]
    };
  },
  async created() {},
  async mounted() {
    this.refreshPa();
  },
  beforeDestroy() {},
  async activated() {},
  computed: {
    ...mapGetters({
      isLoading: "Session/isLoading"
    }),
    ...mapGetters("Session", ["isAuthorized"]),
    ...mapGetters("Dashboard", ["pendingAging30d"])
    // totalPending: function() {
    //   return this.pendingAging
    //     .reduce((acc, curr) => acc + Number(curr.pendingQty), 0)
    //     .toLocaleString();
    // },
  },
  methods: {
    async refreshPa() {
      await this.$store.dispatch(`${this.namespace}/fetchPendingAging30d`);
    },
    totalPending() {
      return this.pendingAging30d.reduce(
        (acc, curr) => acc + Number(curr.pendingQty),
        0
      );
    }

    // async refreshPa() {
    //   await this.$store.dispatch(`${this.namespace}/fetchPendingAging`);
    // }
  }
};
</script>

<style lang="css" scoped>
.pa-table {
  width: 250px;
  padding-top: 0px;
  font-size: 0.9rem;
  margin-left: -18px;
  margin-right: -18px;
}
#pa-table.btn {
  background-color: transparent;
  border: none;
  padding: 0px;
  margin: 0px;
  margin-top: 0px;
  margin-bottom: 0px;
  transition: 0.2s;
}
#pa-table.btn:hover {
  transform: scale(1.2, 1.2);
}
.refresh-icon-rs {
  font-size: 1.1em;
  vertical-align: middle;
  color: rgb(192, 189, 189);
}
.footer-row > td {
  border-top: 2px solid #ddd !important;
}
</style>