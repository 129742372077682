<template>
  <b-card
     @click="clickedValue" style="cursor: pointer;" class="card-db shadow border mx-0 my-3"
    :class="{
      'card-red': redGreenIndicator && parseInt(value) < 0,
      'card-green': redGreenIndicator && parseInt(value) >= 0,
    }"
  >
    <b-card-body class="p-0 cursor-pointer">
      <!-- Main value -->
      <div class="">
        <!-- <div class="d-flex align-items-baseline justify-content-center"> -->
        <div class="d-flex align-items-baseline justify-content-center">
          <b-link @click="clickedValue">
            <h4>{{ value }}</h4>
          </b-link>
          <span class="ml-1 text-muted text-smaller">{{ uom }}</span>
        </div>
        <h5 class="">{{ title }}</h5>
        <hr
          class="p-0 my-2"
          :class="{
            'card-red': redGreenIndicator && parseInt(value) < 0,
            'card-green': redGreenIndicator && parseInt(value) >= 0,
          }"
        />
        <p class="text-muted">{{ description }}</p>
      </div>
      <!-- </div> -->
    </b-card-body>
  </b-card>
</template>

<script>
export default {
  props: {
    namespace: {
      type: String,
      default: "Session"
    },
    id: {
      type: Number,
      default: -1
    },
    title: {
      type: String,
      default: "Card title"
    },
    value: {
      type: String,
      default: "0"
    },
    description: {
      type: String,
      default: ""
    },
    uom: {
      type: String,
      default: "cnt"
    },
    redGreenIndicator: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {};
  },
  created() {},
  computed: {
    state() {
      return this.$store.state[this.namespace];
    }
  },
  methods: {
    clickedValue() {
      this.$emit("g3Clicked", this.id);
    }
  }
};
</script>

<style lang="css" scoped>
.card-db {
  height: 180px;
  width: 250px;
}
.card-red {
  background-color: rgb(253, 232, 232);
  border: 1px solid rgb(209, 2, 2);
}
.card-green {
  /* color: rgb(27, 187, 13); */
  background-color: rgb(229, 255, 229);
  border: 1px solid green;
}
</style>