<template>
  <div class="dboard">
    <!-- <DashboardQikPrep v-if="customDashboard && role === 'QikPrep Processing'" /> -->
    <!-- <DashboardAdministrator
      v-if="customDashboard && role === 'Administrator'"
    /> -->
    <!-- Default dashbaord -->
    <b-container class="text-center dboard-cards px-3">
      <b-overlay :show="isLoading" :opacity="0.1">
        <h3 class="dboard-title">SLC Prep Center Dashboard</h3>
        <p>Security Role {{ role }} (in progress)</p>
        <!-- Refresh dashboard button -->
        <!-- <b-button
          variant="light"
          class="ml-auto mb-1 mt-0"
          :title="refreshTooltip"
          @click.prevent="fetchOarKpis()"
        >
          <i class="fas fa-sync-alt refresh-icon-rs"></i>
        </b-button> -->

        <!-- <p>Auth expiry: {{ authExpiry }}</p> -->
        <b-container fluid>
          <!-- <b-row cols="1" cols-sm="2" cols-md="3" cols-lg="6"> -->
          <b-row>
            <!-- <div fluid v-for="(kpi, index) in oarKpis" :key="index">
              <b-col>
                <BaseInfoCardSmall
                  :id="kpi.id"
                  :title="kpi.title"
                  :value="parseInt(kpi.value).toLocaleString()"
                  :uom="kpi.units"
                  :redGreenIndicator="kpi.showColor === 1 ? true : false"
                  :description="kpi.description"
                  @g3Clicked="showCardDetail"
                />
              </b-col>
            </div> -->
          </b-row>
        </b-container>
      </b-overlay>
    </b-container>
    <!-- ReceiveSummary -->
    <!-- <b-modal id="show-receive-summary" centered hide-footer>
      <PurchasedItemReceiveSummary />
    </b-modal> -->
    <!-- ShippedSummary -->
    <!-- <b-modal id="show-shipped-summary" centered hide-footer>
      <OarShippedSummary />
    </b-modal> -->
    <!-- PendingSummary -->
    <!-- <b-modal id="show-pending-summary" centered hide-footer>
      <OarPendingSummary />
    </b-modal> -->
    <!-- 30 day receive activity summary -->
    <!-- <b-modal id="show-receive-30d-summary" centered hide-footer>
      <PurchasedItemReceive30dSummary />
    </b-modal> -->
    <!-- Pending Aged 30d Summary -->
    <!-- <b-modal id="show-pending-30d-summary" centered hide-footer>
      <OarPendingAged30dSummary />
    </b-modal> -->
    <!-- Comming soon placeholder -->
    <b-modal id="coming-soon" centered hide-footer>
      <b-container>
        <h4>Comming Soon...</h4>
      </b-container>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters, mapState, mapActions } from "vuex";
// import DashboardQikPrep from "./DashboardQikPrep.vue";
// import DashboardAdministrator from "./DashboardAdministrator.vue";
// import OarPendingSummary from "./report/OarPendingSummary.vue";
// import OarPendingAged30dSummary from "./report/OarPendingAged30dSummary.vue";
// import PurchasedItemReceiveSummary from "./report/PurchasedItemReceiveSummary.vue";
// import OarShippedSummary from "./report/OarShippedSummary.vue";
// import PurchasedItemReceive30dSummary from "./report/PurchasedItemReceive30dSummary.vue";
// import { handleRouterPushError } from "../utils/component-utils";
// import { setSubNavText } from "../utils/component-utils";
// import config from "../../config";

export default {
  components: {
    // DashboardQikPrep
    // DashboardAdministrator
    // OarPendingSummary,
    // OarPendingAged30dSummary,
    // PurchasedItemReceiveSummary,
    // OarShippedSummary,
    // PurchasedItemReceive30dSummary
  },
  data() {
    return {
      title: "Dashboard",
      namespace: "Dashboard"
    };
  },
  async created() {
    document.title = "g3tools - Dashboard";
    this.setSubNav("");
    // this.timer = setInterval(
    //   await this.fetchOarKpis(),
    //   config.autoListRefreshIntervalMin * 60 * 1000
    // );
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
  async mounted() {
    // Get oar kpis
    // runs when the element is injected into the browser
    //this.setSubNav("Arbitrage");
    this.title = `${this.user.role} Dashboard`;
  },
  computed: {
    ...mapState("User", ["users"]),
    ...mapState("Session", ["auth"]),
    ...mapGetters("Session", [
      "auth",
      "user",
      "isAdmin",
      "isLoading",
      "isAuthenticated",
      "authExpiry",
      "customDashboard",
      "role"
    ]),
    ...mapGetters("Dashboard", ["oarKpis"]),
    refreshTooltip() {
      return `Refresh KPI's: \r${
        this.$store.getters[`${this.namespace}/lastRefreshed`]
      }\r\rNote: Some KPI's are refreshed by back-end processes running every 15 minutes`;
    },
    qpAgedPendingValue() {
      return this.oarKpis.find(x => x.title === "QP Aged Units")?.value;
    }
  },
  methods: {
    //...mapActions("Dashboard", ["fetchOarKpis"]),
    ...mapActions("Session", ["setSubNav", "setAccentColor"])

    // showCardDetail(cardId) {
    // if (cardId === 1) {
    //   this.$bvModal.show("show-pending-30d-summary");
    // } else if (cardId === 2) {
    //   this.$bvModal.show("show-pending-summary");
    // } else if (cardId === 3) {
    //   this.$bvModal.show("show-receive-summary");
    // } else if (cardId === 4) {
    //   this.$bvModal.show("show-receive-summary");
    // } else if (cardId === 5) {
    //   this.$bvModal.show("show-receive-summary");
    // } else if (cardId === 6) {
    //   this.$bvModal.show("show-receive-summary");
    // } else if (cardId === 7) {
    //   this.$bvModal.show("show-shipped-summary");
    // } else if (cardId === 8) {
    //   setSubNavText(this.setSubNav, this.setAccentColor, "Issue");
    //   this.$router.push({ name: "QpIssueListAllOpen" }).catch(err => {
    //     handleRouterPushError(err);
    //   });
    // }
    // }
  }
};
</script>

<style lang="css" scoped>
.dboard {
  background-color: rgb(252, 255, 255);
  height: calc(100vh - 53px);
}
.dboard-cards {
  min-width: 100%;
}
.dboard-title {
  padding-top: 10px;
  color: rgb(57, 149, 161);
  font-weight: 700;
}
.refresh-icon-rs {
  font-size: 1.1em;
  vertical-align: middle;
  color: rgb(139, 139, 139);
}
</style>