export default {
  data() {
    return {
      isDragging: false,
      dragOffset: { x: 0, y: 0 },
    };
  },
  methods: {
    startDrag(event) {
      //const headerElement = event.currentTarget;
      const modalElement = this.$refs.PurchasedItemItemModalRef?.$el;
      if (!modalElement) {
        console.warn("Modal element not found!");
        return;
      }

      this.isDragging = true;
      this.dragOffset = {
        x: event.clientX - modalElement.getBoundingClientRect().left,
        y: event.clientY - modalElement.getBoundingClientRect().top,
      };

      document.addEventListener("mousemove", this.onDrag);
      document.addEventListener("mouseup", this.endDrag);
    },

    onDrag(event) {
      if (!this.isDragging) return;

      const modalElement = this.$refs.PurchasedItemItemModalRef?.$el;

      if (modalElement) {
        modalElement.style.position = "absolute";
        modalElement.style.top = `${event.clientY - this.dragOffset.y}px`;
        modalElement.style.left = `${event.clientX - this.dragOffset.x}px`;
      }
    },

    endDrag() {
      this.isDragging = false;
      document.removeEventListener("mousemove", this.onDrag);
      document.removeEventListener("mouseup", this.endDrag);
    },

    initDrag() {
      setTimeout(() => {
        //        const modalElement = this.$refs.PurchasedItemItemModalRef?.$el;
        // if (modalElement) {
        //   console.log("Modal element ready for dragging: ", modalElement);
        // } else {
        //   console.warn("Modal element is not ready.");
        // }
      }, 100); // Adjust the timeout if necessary
    },
  },
};
