<template>
  <div class="container attachments-form" @paste="pasteImage">
    <form @submit.prevent="getFile" enctype="multipart/form-data">
      <div
        v-if="message"
        :class="`message ${
          error ? 'is-danger' : info ? 'is-info' : 'is-success'
        }`"
      >
        <div class="message-body">{{ message }}</div>
      </div>
      <div class="container">
        <b-row>
          <b-col>
            <!-- Drop zone (choose file) -->
            <div class="dropzone">
              <input
                type="file"
                ref="file"
                @change="getFile"
                class="input-file"
                accept=".gif,.jpg,.jpeg,.png"
                :disabled="disabled"
              />
              <!-- Call to action button -->
              <div :disabled="!uploading" class="call-to-action">
                <span class="file-icon"> <i class="fas fa-upload"></i> </span>
                <span> Choose a file... </span>
              </div>
            </div>
          </b-col>
          <b-col>
            <!-- Drop zone (paste file) -->
            <div v-if="supportsCB" class="dropzone" @click="pasteImage" :disabled="disabled">
              <a ref="file-paste" class="input-file" />
              <!-- Call to action button -->
              <div
                :disabled="!uploading || disabled"
                class="call-to-action call-to-action-title"
              >
                <span class="file-icon"> <i class="fas fa-paste"></i> </span>
                <span> Click to Paste... </span>
              </div>
            </div>
          </b-col>
        </b-row>

        <!-- Content -->
        <section>
          <div class="content is-vcentered has-text-centered">
            <div class="columns is-multiline">
              <!-- Attached items -->
              <div
                v-for="(file, index) in attachments"
                :key="file.thumbnailKey"
                class="column is-one-quarter"
              >
                <div
                  class="box attached-item"
                  name="attached-item"
                  @click="enlargeImage(index)"
                >
                  <!-- Delete button -->
                  <button
                    class="button is-small is-transparent delete-button"
                    @click.prevent="removeItem(file.thumbnailKey)"
                  >
                    <span class="icon">
                      <i class="fas fa-times"></i>
                    </span>
                  </button>
                  <!-- Image -->
                  <BaseEnlargeableImage
                    ref="enlargeImage"
                    class="item-image"
                    :src="file.thumbnailUrl"
                    :src_large="file.fullUrl"
                  />
                  <!-- Title -->
                  <div class="item-title is-italic" id="imageTitleId">{{
                    file.originalFilename
                  }}</div>
                  <b-tooltip target="imageTitleId" variant="secondary">{{file.originalFilename}}</b-tooltip>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </form>
  </div>
</template>

<script>
//import axios from "axios";
import { httpClient } from "../api/http-client";
import _ from "lodash";
import config from "../../config";
// import { mapGetters } from "vuex";

//import VueExpandableImage from "vue-expandable-image";
// import Vue from "vue";
// import EnlargeableImage from "@diracleo/vue-enlargeable-image";
// Vue.use(EnlargeableImage);

export default {
  components: {
    // EnlargeableImage
  },
  props: {
    namespace: { type: String, default: "" },
    recId: {
      type: [String, Number],
      default: -1
    },
    tenantId: {
      type: [String],
      required: true
    },
    clientId: {
      type: [String],
      required: true
    },
    clientName: {
      type: [String],
      required: true
    },
    processorName: {
      type: [String],
      required: true
    },
    disabled: Boolean
  },
  data() {
    return {
      message: "",
      error: false,
      info: false,
      progress: 0,
      uploading: false,
      uploadedFiles: [],
      supportsCB: false,
      pastedFile: null,
      filesAdded: [],
      filesDeleted: [],
      removingItem: false
    };
  },
  created() {
    if (navigator.clipboard) {
      this.supportsCB = true;
    }
  },
  mounted() {},
  computed: {
    baseUrl() {
      return `${process.env.VUE_APP_BASE_URL}`;
    },
    attachments() {
      return this.$store.getters[`${this.namespace}/attachments`];
    }
    //     attachments() {
    //       const attachments = this.$store.getters[`${this.namespace}/attachments`];
    //       if (!attachments) {
    //         return [];
    //       } else {
    //         return attachments;
    //       }
    //     }
  },
  methods: {
    enlargeImage(index) {
      if (this.removingItem) return;
      if (this.$refs.enlargeImage[index].state === "enlarged") return;
      this.$refs.enlargeImage[index].enlarge();
    },
    attachmentsChanged() {
      // Rase event
      this.$emit("attachmentsChanged", true);
      // Set hasAttachments
      if (
        this.attachments &&
        this.attachments.length > 0
      ) {
        this.$store.commit(`${this.namespace}/HAS_ATTACHMENTS`, true);
      } else {
        this.$store.commit(`${this.namespace}/HAS_ATTACHMENTS`, false);
      }
    },

    async pasteImage() {
      try {
        const clipboardItems = await navigator.clipboard.read();
        for (const clipboardItem of clipboardItems) {
          for (const type of clipboardItem.types) {
            const blob = await clipboardItem.getType(type);
            // we can now use blob here
            if (type.includes("image")) {
              // Image: convert to file
              const extension = type.split("/")[1];
              const filename = `pasted-image-${Date.now()}.${extension}`;
              //const fileSize = blob.length;
              this.pastedFile = new File([blob], filename, {
                type: type,
                lastModified: Date.now()
              });
            }
          }
          if (!this.pastedFile) {
            this.message = "No image in clipboard to paste";
            this.info = true;
            return;
          }
          // Send file to server and show on screen
          await this.sendFile(this.pastedFile);
          this.error = false;
          this.info = false;
          this.message = "";
        }
      } catch (err) {
        if (err.message.includes("No valid data on clipboard")) {
          this.info = true;
        } else {
          this.error = true;
        }
        this.message = err.message;
        console.error(err.name, err.message);
      }
    },

    async removeItem(thumbnailKey) {
      this.removingItem = true;
      // Remove from fileserver
      const file = this.attachments.find(
        x => x.thumbnailKey === thumbnailKey
      );
      if (!file) {
        throw new Error(
          `Error removing attachment. File not found (${thumbnailKey}).`
        );
      }
      // Save file info in case we cancel save
      this.uploading = true;
      this.filesDeleted.push(file);
      await this.deleteFile(file);
      this.uploading = false;
      this.$store.commit(
        `${this.namespace}/ATTACHMENT_REMOVE`,
        file.thumbnailKey
      );
      this.attachmentsChanged();
      this.removingItem = false;
    },

    bucketMeta() {
      return {
        "x-bucketMeta-id": this.recId,
        "x-bucketMeta-tenant-id": this.$props.tenantId,
        "x-bucketMeta-client-id": this.$props.clientId,
        "x-bucketMeta-client-name": this.$props.clientName,
        "x-bucketMeta-processor-name": this.$props.processorName,
      };
    },

    // Call API to delete s3 file (both full and thumbnail are deleted)
    // Note this is a soft delete (moves file to s3 deleted bucket)
    async deleteFile(file) {
      const url = `${process.env.VUE_APP_BASE_URL}/issue/dropzone/`;
      await httpClient.delete(url, {
        data: file,
        headers: this.bucketMeta(),
      });
    },

    // Call API to delete s3 file (both full and thumbnail) from both
    // attachments and attachments-deleted for a given issue id
    // async deleteFilePermanent(file) {
    //   const url = `${process.env.VUE_APP_BASE_URL}/issue/dropzone/permanent`;
    //   await httpClient.delete(url, {
    //     data: file,
    //     headers: this.bucketMeta(),
    //   });
    // },

    // Call API to restore (undelete) s3 file (api does both full and thumbnail)
    async restoreFile(file) {
      const url = `${process.env.VUE_APP_BASE_URL}/issue/dropzone/restore`;
      await httpClient.post(url, {
        data: file,
        headers: this.bucketMeta(),
      });
    },

    validate(file) {
      const maxSize = config.fileUploads.MAX_SIZE;
      const allowedTypes = config.fileUploads.FILE_TYPES;
      if (file?.size > maxSize) {
        return `File is too large. Max size is ${maxSize / 1000 / 100}MB`;
      }
      if (!allowedTypes.includes(file.type)) {
        return "Only images allowed";
      }
      return "";
    },

    async getFile(e) {
      e.preventDefault();
      // Pre-validate files from file input dropzone
      this.message = "";
      this.error = false;
      this.info = false;
      const files = [this.$refs.file.files[0]];
      _.forEach(files, file => {
        if (file) {
          const validateMessage = this.validate(file);
          if (validateMessage !== "") {
            // At least one file is in error: Stop
            this.message = validateMessage;
            this.error = true;
            return;
          }
        }
      });
      if (this.message !== "") return;
      await this.sendFile(this.$refs.file.files[0]);
    },

    async sendFile(file) {
      // Stop if filename already in uploadedFiles
      if (
        this.attachments?.filter(
          x => x.originalFilename === file.filename
        ).length > 0
      ) {
        return;
      }
      this.error = false;
      this.message = "";
      // Send file to server
      let formData = new FormData();
      formData.append("file", file);
      const url = `${process.env.VUE_APP_BASE_URL}/issue/dropzone`;
      try {
        this.uploading = true;
        const res = await httpClient.post(url, formData, {
          headers: { ...this.bucketMeta() },
          onUploadProgress: e =>
            (this.progress = Math.round((e.loaded * 100) / e.total))
        });
        const fileObj = {
          thumbnailUrl: res.data.thumbnailUrl,
          thumbnailKey: res.data.thumbnailKey,
          fullUrl: res.data.fullUrl,
          fullKey: res.data.fullKey,
          originalFilename: res.data.originalFilename
        };
        // Save in case we cancel
        this.filesAdded.push(fileObj);
        // Save in store attachments
        this.$store.commit(`${this.namespace}/ATTACHMENT_ADD`, fileObj);
        this.attachmentsChanged();
        this.uploading = false;
      } catch (err) {
        this.message = err.data.error;
        this.error = true;
        this.uploading = false;
      }
    }
  }
};
</script>

<style lang="css" scoped src="../../node_modules/bulma/css/bulma.css">
</style>

<style lang="css" scoped >
.dropzone {
  margin-right: -5px;
  margin-left: -5px;
  height: 100px;
  padding: 15px;
  margin-bottom: 15px;
  position: relative;
  cursor: pointer;
  outline: 2px dashed gray;
  outline-offset: -10px;
  background-color: #eee;
  border-radius: 10px;
  transition: all 0.08s ease-in;
}
.input-file {
  opacity: 0;
  width: 100%;
  height: 100px;
  position: absolute;
  cursor: pointer;
}
.dropzone:hover {
  background-color: #ddd;
}
.dropzone .call-to-action {
  display: flex;
  justify-content: center;
  font-size: 1rem;
  padding: 25px;
}
.dropzone .progress-bar {
  text-align: center;
  padding: 60px 10px;
}
.file-icon {
  font-size: 1.5rem;
  color: #888;
}
.call-to-action-title {
  font-size: 0.5rem;
}
.click-to-paste {
  border-bottom-style: none;
  display: block;
  padding: 10px;
}
.item-image {
  margin-top: 0px;
  margin-bottom: 5px;
}
.item-title {
  font-size: 0.8rem;
}
.attachments-form {
  min-height: 300px;
  margin-bottom: 15px;
}
.attachments {
  display: flex;
  padding-left: 2px;
  padding-right: -5px;
}
.attachments .column {
  width: 49%;
  margin-left: 5px;
  margin-top: 10px;
}
.attached-item {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  position: relative;
  display: block;
  text-align: center;
  padding: 20px;
  height: 150px;
  background-color: rgb(231, 236, 240);
  border: 1px #ccc solid;
  border-radius: 5px;
  cursor: zoom-in;
}
.delete-button {
  position: absolute;
  background-color: transparent;
  border-color: transparent;
  top: 0px;
  right: 0px;
  margin: 2px;
  width: 10px;
  height: 20px;
  color: #aaa;
}
.caution-text {
  color: darkred;
  font-size: 0.8rem;
  animation: blink 0.8s 1 alternate;
}

@keyframes blink {
  0% {
    color: darkred;
  }
  50% {
    color: #ccc;
  }
  100% {
    color: darkred;
  }
}

/* @keyframes blink {
  from {
    color: white;
  }
  to {
    color: darkred;
  }
} */
</style>


