<template>
  <b-container class="mt-5">
    <b-row>
      <b-col md="8" class="mx-auto">
        <b-card>
          <b-card-header class="text-center">
            <div class="d-flex justify-content-center">
              <img src="../../assets/g3tools-orange-64x64.png" width="35" class="mr-3" />
              <h2>{{ appName }}</h2>
            </div>
          </b-card-header>
          <b-card-body>
            <h5>Description</h5>
            <p>{{ description1 }}</p>
            <p>{{ description2 }}</p>

            <h5>Contact Information</h5>
            <p>Email: <a :href="'mailto:' + contactInfo.email">{{ contactInfo.email }}</a></p>
            <p>Phone: {{ contactInfo.phone }}</p>

            <b-list-group class="mt-4">
              <b-list-group-item>
                <strong>UI Version:</strong> {{ uiVersion }}
              </b-list-group-item>
              <b-list-group-item>
                <strong>API Version:</strong> {{ apiVersion }}
              </b-list-group-item>
              <b-list-group-item v-if="user.isAppAdmin">
                <table class="custom-table">
                  <tbody>
                    <tr v-for="(item, index) in adminInfo" :key="'adminInfo' + index" >
                      <td class="key top-align">{{ item.key }}</td>
                      <td class="value top-align">{{ item.value }}</td>
                    </tr>
                  </tbody>
                </table>
              </b-list-group-item>
            </b-list-group>
          </b-card-body>
          <b-card-footer class="text-center">
            <small>© {{ new Date().getFullYear() }} {{ appName }}. All rights reserved.</small>
          </b-card-footer>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { mapGetters } from "vuex";
import config from "../../../config.js";

export default {
  data() {
    return {
      appName: 'g3tools',
      description1: 'Easy to use business tools for tracking and managing merchandise inventory between sellers and processors. g3tools also provides exception tracking, automatically linking exceptions to inventory items.',
      description2: 'With g3tools, sellers and processors communicate more effectively, minimizing losses, and facilitating the smooth, predictable flow of sellable assets from seller source to online retailers.',
      contactInfo: {
        email: 'dgarvin57@gmail.com',
        phone: '903-681-1968',
      },
      uiVersion: config.app.version,
      apiVersion: "2.0.1",
      healthStatus: "",
      healthMessage: "",
      dbName: "",
      dbVersion: '1.0.0',
      dbPort: '3300',
      dbPortProxy: "",
      dbConnectionString: "",
     
    };
  },
  created() {
    this.healthcheck()
    this.dbInfo()
  },
  computed: {
    ...mapGetters({
      user: "Session/user"
    }),
    adminInfo() {
      return [
        { key: "API status: ", value: `${this.healthStatus}: ${this.healthMessage}` },
        { key: "Database name:", value: this.dbName },
        { key: "Database version:", value: this.dbVersion },
        { key: "Database port:", value: this.dbPort },
        { key: "DB proxy port:", value: this.dbPortProxy },
        { key: "Connect string:", value: this.dbConnectionString },

      ]
    }
  },
  methods: {  
    async healthcheck()  {
      const result = await this.$store.dispatch("Session/fetchHealthcheck");
      this.healthStatus = `${result.status} - ${result?.data?.status}`;
      this.healthMessage = result?.data?.message;
      this.apiVersion = result?.data?.version;
    },
    async dbInfo() {
      const result2 = await this.$store.dispatch("Session/fetchDbInfo");
      this.dbName = result2?.data?.dbName;
      this.dbVersion = result2?.data?.dbVersion;
      this.dbPort = result2?.data?.dbPort;
      this.dbPortProxy = result2?.data?.dbPortProxy;
      this.dbConnectionString = result2?.data?.dbConnectionString;
    }
  }
};
</script>

<style scoped>
.mt-5 {
  margin-top: 3rem !important;
}
.custom-table {
  width: 100%;
  border-collapse: collapse;
}
.custom-table td {
  padding: 0.2rem; 
  line-height: 1.5;
}
.key {
  min-width: 110px;
  text-align: left;
  font-weight: 700;
}

.value {
  text-align: left;
  max-width: 370px;
  overflow-wrap: break-workd;
  word-wrap: break-word;
  white-space: normal;
  font-size: .9rem;
}
.top-align {
  vertical-align: top;
}

</style>
