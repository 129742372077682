<template >
  <div
    class="table-footer d-flex align-items-center justify-content-between pl-2"
    v-if="list.totalRows > 0"
  >
    <BaseTotalRows :namespace="namespace" :totalRows="list.totalRows" class=""/>
    <b-pagination
      v-if="list.totalRows > list.pageSize"
      class="m-0 p-0"
      variant="info"
      size="sm"
      :limit="limit"
      v-model="currentPage"
      :total-rows="list.totalRows || 0"
      :per-page="list.pageSize"
      aria-controls="btable"
      @change="localFetchAll"
    ></b-pagination>
    <BaseRowsPerPage :namespace="namespace" class=""/>
  </div>
</template>

<script>
import config from "../../config.js";

export default {
  props: {
    namespace: {
      type: String,
      default: ""
    }
  },
  async mounted() {},

  computed: {
    routeName() {
      return this.$route.name;
    },
    limit: function() {
      return config.maxTablePages;
    },
    list() {
      return this.$store.getters[`${this.namespace}/list`];
    },
    currentPage: {
      set(currentPage) {
        this.$store.dispatch(`${this.namespace}/listSet`, {
          key: "currentPage",
          value: currentPage
        });
        // this.localFetchAll();
      },
      get() {
        //return this.list.currentPage;
        return this.$store.getters[`${this.namespace}/list`].currentPage;
      }
    }
  },
  methods: {
    localFetchAll() {
      this.$store.dispatch(`${this.namespace}/fetchAll`, this.routeName);
    }
  }
};
</script>

<style lang="css" scoped>
.red-border {
  border: 1px solid red;
}

.table-footer {
  margin-top: -5px;
  padding-top: 0;
  padding-bottom: 5px;
  padding-right: 5px;
}
</style>