<template>
  <b-container class="table-action-bar">
    <div class="my-auto pl-2" :style="useSearchBoxWidth">
      <!-- SEARCH -->
      <b-form-input
        ref="searchField"
        id="searchField"
        autocomplete="off"
        tbody-tr-class="clickable-row"
        v-model="searchText"
        type="text"
        class="form-control search-box"
        placeholder="Search...     [Ctl+F]"
        @input="debounceSearch"
        @focus="$event.target.select()"
        size="sm"
      />
    </div>
    <div v-if="filters.length > 0" class="my-auto start-of-filters"></div>
    <!-- FK DROPDOWN FILTERS -->
    <template v-for="filter in filters">
      <div
        v-if="
          filter.type === 'select' &&
          (filter.includeInTable || includeInRoute(filter.includeInRoutes))
        "
        class="d-flex"
        :key="filter.field"
      >
        <label
          style="white-space: nowrap"
          class="my-auto filter-label ml-2 mr-1"
          >{{ filter.label }}:</label
        >
        <b-form-select
          :id="filter.field"
          :ref="filter.field"
          class="my-auto filter-select pl-1 pr-1"
          :value="getSelected(filter.field)"
          :options="getOptionsSelect(filter.field)"
          :text-field=filter.displayField
          :value-field=filter.nameField
          @change="setSelected(filter.field)"
          size="sm"
        >
        </b-form-select>
      </div>
      <!-- ACTION BUTTONS -->
      <div
        v-else-if="filter.type === 'action' && filter.includeInTable"
        class="my-auto d-flex"
        :key="'action-' + filter.field"
      >
        <label
          :style="`min-width: 70px`"
          class="my-auto ml-2 mr-2 text-smaller text-right"
          >{{ filter.label }}</label
        >
        <b-form-group v-slot="{ ariaDescribedby }" class="my-auto">
          <b-form-radio-group
            :id="filter.field"
            :options="getOptionsRadio(filter.field)"
            :aria-describedby="ariaDescribedby"
            :name="filter.field"
            :text-field=filter.displayField
            :value-field=filter.nameField
            buttons
            :checked="getSelected(filter.field)"
            button-variant="outline-secondary"
            @change="setActionChanged($event, filter.field)"
            size="sm"
          ></b-form-radio-group>
        </b-form-group>
      </div>
    </template>
    <div v-if="filters.length > 0" class="end-of-filters my-auto"></div>
    <div class="ml-3 my-auto">
      <!-- Clear button -->
      <b-button
        variant="outline-secondary"
        @click="clearFilters($event)"
        :disabled="filterIsEmpty"
        size="sm"
        >Clear</b-button
      >
      <!-- <b-button variant="primary mx-3" :disabled="filterIsEmpty" @click="search"
        >Apply</b-button
      > -->
    </div>
  </b-container>
</template>
<script>
import _ from "lodash";
import { mapGetters } from "vuex";

export default {
  props: {
    namespace: { type: String, default: "" },
    searchBoxWidth: { type: String, default: "" },
    fetchOnStart: { type: Boolean, default: true }
  },
  data() {
    return {
    };
  },
  mounted() {
    //    this.loadFilters();
  },
  computed: {
    ...mapGetters({
      accentColor: "Session/accentColor"
    }),
    routeName() {
      return this.$route.name;
    },
    list() {
      return this.$store.getters[`${this.namespace}/list`];
    },
    filterIsEmpty() {
      return this.$store.getters[`${this.namespace}/filterIsEmpty`];
    },
    filters() {
      return this.$store.getters[`${this.namespace}/filters`];
    },
    searchText: {
      get() {
        return this.list.searchText;
      },
      set(value) {
        this.$store.dispatch(`${this.namespace}/listSet`, {
          key: "searchText",
          value: value
        });
      }
    },
    useSearchBoxWidth() {
      return `width: ${this.searchBoxWidth}px`;
    }
  },
  methods: {
    focusOnSearch() {
      //alert("in child");
      this.$refs.searchField.focus();
      this.$refs.searchField.select();
    },
    includeInRoute(value) {
      // If include in route is specified, check route and includeInRoute settings from filter
      if (!value) return false;
      for (const route of value) {
        if (this.routeName.toLowerCase().includes(route.toLowerCase()))
          return true;
      }
      return false;
    },
    async setActionChanged(value, field) {
      // Apply action button and fetch items
      await this.$store.dispatch(`${this.namespace}/setActionButton`, {
        fieldName: field,
        fieldValue: value,
        filter: this.routeName
      });
    },
    groupValue(fieldName) {
      var foundGroup = this.filters.find(x => x.field === fieldName);
      return foundGroup.value;
    },
    // Load options from store into select element
    getOptionsSelect(field) {
      const filter = this.filters.find(x => x.field === field);

      if (field === 'assigneeFamiliarName') {
        return filter.options.map(option => ({
          ...option,
          familarNameAndClientName: `${option.familiarName} - (${option.clientName})`
        }));
      }
      return filter.options;
    },

    // Load options from store into radio element
    getOptionsRadio(field) {
      const filter = this.filters.find(x => x.field === field);
      return filter.options;
    },
    // Get filter selected items from store and load into element
    getSelected(field) {
      const filter = this.filters.find(x => x.field === field);
      return filter.value || filter.defaultValue;
    },
    // User selected a filter on the filter bar: Filter selected items in store
    setSelected(field) {
      const selectedValues = this.$refs[field][0].localValue;
      this.$store.dispatch(`${this.namespace}/setFilterSelectedItems`, {
        fieldName: field,
        value: selectedValues,
        filter: this.routeName,
        setByUser: true
      });
      this.$store.dispatch(`${this.namespace}/fetchAll`);
      this.$nextTick(() => {
        this.adjustSelectWidth(field);
      });
    },
    // When option is selected in filter field, adjust width to accomodate text
    adjustSelectWidth(field) {
      const selectElement = document.querySelector(`#${field}`);
      if (selectElement) {
        const selectedOption = selectElement.querySelector('option:checked');
        if (selectedOption) {
          // Create a temporary element to measure the text width
          const tempElement = document.createElement('span');
          tempElement.style.visibility = 'hidden';
          tempElement.style.position = 'absolute';
          tempElement.style.whiteSpace = 'nowrap';
          tempElement.textContent = selectedOption.textContent;

          document.body.appendChild(tempElement);

          // Calculate the new width, adding some padding
          const newWidth = Math.min(tempElement.offsetWidth + 20, 300);
          selectElement.style.width = `${newWidth}px`;

          // Clean up
          document.body.removeChild(tempElement);
        }
      } else {
        console.error(`Select element with id "${field}" not found.`);
      }
    },

    search() {
      this.$store.dispatch(`${this.namespace}/fetchAll`, this.routeName);
    },
    debounceSearch: _.debounce(function() {
      this.search();
    }, 300),
    //setTimeout(this.search, 500);

    clearFilters($event) {
      this.$store.dispatch(`${this.namespace}/clearFilters`, {
        routeName: this.routeName,
        fetchOnStart: this.fetchOnStart
      });
      this.$emit("clear", { $event });
      // Wait for DOM updates
      this.$nextTick(() => {
        // Reset width of select elements
        const selectElements = document.querySelectorAll('.filter-select');
        selectElements.forEach(selectElement => {
          selectElement.style.width = '85px';  
        });
      });
    }
  }
};
</script>
<style lang="css" scoped>
.search-box {
  margin-left: 3px;
  min-width: 200px;
}
.filter-select {
  min-width: 85px;
}
.filter-label {
  font-size: 0.8em;
}
.start-of-filters {
  height: 25px;
  border-left: solid 1px #ccc;
  margin-left: 20px;
  margin-right: 1px;
}
.end-of-filters {
  height: 25px;
  border-left: solid 1px #ccc;
  margin-left: 15px;
}
button {
  margin-top: 4px;
  margin-bottom: 4px;
}
.table-action-bar {
  display: flex;
  background-color: RGB(248, 248, 248);
  border-bottom: 1px solid #ddd;
  border-left: 1px solid #ddd;
  margin-left: 0px;
  padding-left: 5px;
  padding-top: 5px;
  margin-right: 0px;
}

</style>