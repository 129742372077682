<template>
  <div class="pl-3 pr-3">
    <b-table :striped="striped" hover :items="items" :fields="fields" small class="p-2" :tbody-tr-class="getRowClass">
      <!-- Footer -->
      <!-- could also be a footer field slot instead -->
      <template slot="bottom-row">
        <td class="footer-row"><strong>Total count: {{ totalValue }}</strong></td>
        <!-- this is a computed prop that adds up all the expenses in the visible rows -->
        <td class="footer-row">
        </td>
        <td class="footer-row">
        </td>
      </template>
    </b-table>
  </div>
</template>

<script>
export default {
  props: {
    items: { type: Array, default: null },
    title: { type: String, default: "Description" },
    striped: { type: Boolean, default: false },
    total: { type: String, default: null }
  },
  data() {
    return {
      fields: [
        {
          key: "label",
          sortable: false,
          label: "Purchased Item Field",
        },
        {
          key: "mappedLabel",
          sortable: false,
          label: "CSV Mapped Label",
        },
        {
          key: "isMatch",
          sortable: false,
          label: "Matched",
        },
      ]
    };
  },
  created() {
  },
  computed: {
    totalValue() {
      return this.items.length
    },
  },
  methods: {
    getRowClass(item, type) {
      if (!item || type !== 'row') return
      return item.isMatch ? '' : 'text-danger'
    }
  }
};
</script>

<style>
.footer-row {
  border-top: 1px solid #bbb !important;
}
.text-danger {
  color: red; /* This class will apply red color to the text */
}
.bg-danger {
  background-color: rgba(214, 189, 189, 0.385); /* Light red background */
}
</style>