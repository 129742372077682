// Format URL
exports.formatUrl = async (props, endpoint) => {
  const {
    pageSize,
    currentPage,
    sortBy,
    sortOrder,
    orderClause,
    searchText,
    //filters,
  } = props;
  // Query string
  //const haveFilters =
  //  filters.find((x) => x.value !== "" && x.value !== null) !== undefined;
  //const useEndpoint =
  //  searchText !== "" || haveFilters ? `${endpoint}/find` : endpoint;
  const useOrderClause = orderClause
    ? `&orderBy=${sortBy}&orderDir=${sortOrder}&orderClause=${orderClause}`
    : `&orderBy=${sortBy}&orderDir=${sortOrder}`;
  const url = `${endpoint}?listPerPage=${pageSize}&page=${currentPage}${useOrderClause}`;
  // search?
  const searchString = searchText ? `&searchText=${searchText}` : "";
  return url + searchString;
};

/**
 * If something in value or defaultValue, include in the where clause
 * @param {array} filters - All filters from store
 * @returns
 */
exports.formatFilter = async (filters) => {
  const activeFilters = filters.filter(
    (x) =>
      (x.value && x.value !== "" && x.value !== null) ||
      (x.defaultValue && x.defaultValue !== "" && x.defaultValue !== null)
  );
  if (activeFilters?.length > 0) {
    let filterPredicates = [];
    const useFilters = Array.isArray(activeFilters)
      ? activeFilters
      : [activeFilters];
    useFilters.forEach((filter) => {
      const filterItem = {
        field: filter.field,
        operator:
          filter.defaultOperator && filter.defaultOperator !== ""
            ? filter.defaultOperator
            : filter.operator,
        predicate: this.getPredicate(filter),
      };
      if (filterItem.predicate !== undefined && filterItem.predicate !== null) {
        filterPredicates.push(filterItem);
      }
    });
    return [filterPredicates];
  }
  return "";
};

exports.formatOptions = async (selectedFields) => {
  if (!selectedFields || !Array.isArray(selectedFields)) return null;
  return selectedFields; // Convert array to a JSON string
};

exports.getPredicate = (filter) => {
  if (filter.type === "action") {
    // This is an action filter
    if (filter.value === "All") return null;
    if (
      filter.value !== undefined &&
      filter.value !== "" &&
      filter.value !== null
    )
      return filter.value;
    if (filter.defaultValue !== undefined && filter.defaultValue !== "") {
      // Default value exists; get translated option
      return filter.defaultValue;
    }
    return null;
  }
  // No action predicate
  return filter.value && filter.value !== ""
    ? filter.value
    : filter.defaultValue && filter.defaultValue !== ""
    ? filter.defaultValue
    : null;
};
