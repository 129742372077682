<template>
  <div>
    <b-form-group
      :id="id"
      :label="label"
      :label-for="`${id}-input`"
      :label-cols="labelCols"
      :description="error ? '' : description"
      :label-align="labelAlign"
      :label-size="size"
      :label-class="labelClass"
      class="label"
      :class="{ 'form-group--error': error, 'form-group--compact': compact }"
    >
      <b-form-file
        :class="{ 'form-group--compact': compact }"
        :id="`${id}-input`"
        :name="`${id}-input`"
        :accept="accept"
        :value="assignedValue"
        @input="updateValue(id, $event)"
        v-uppercase="uppercase"
        v-bind="$attrs"
        :autocomplete="autocomplete"
        :plaintext="plaintext"
        :readonly="readonly"
        :size="size"
        :state="state"
        :aria-describedby="`${id}-live-feedback`"
        v-on="$listeners"
        :placeholder="placeholder"
        :drop-placeholder="dropPlaceholder"
      ></b-form-file>
      <b-form-invalid-feedback :id="`${id}-live-feedback`">
        {{ errorMsg }}
      </b-form-invalid-feedback>
    </b-form-group>
  </div>
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    autocomplete: { type: String, default: "off" },
    compact: { type: Boolean, default: false },
    accept: { type: String, default: null },
    description: { type: String },
    error: { type: Boolean },
    errorMsg: { type: String },
    icon: { type: String, default: "" },
    id: { type: String },
    label: { type: String, default: "" },
    labelAlign: { type: String, default: "left" },
    labelClass: { type: String, default: "" },
    labelCols: { type: [String, Boolean, Number], default: "0" },
    plaintext: { type: Boolean, default: false },
    readonly: { type: Boolean, default: false },
    size: { type: String, default: "" },
    state: { type: [Boolean, null], default: null },
    value: { type: [Array, Object, File, String], default: null },
    uppercase: { type: Boolean, default: false },
    placeholder: { type: String, default: "" },
    dropPlaceholder: { type: String, default: "" }
  },
  computed: {
    assignedValue() {
      return Array.isArray(this.value) || typeof this.value === "object"
        ? this.value
        : [this.value];
    }
  },
  methods: {
    updateValue(id, $event) {
      this.$emit("g3Input", { id, $event });
    }
  }
};
</script>

<style lang="css" scoped>
.form-group--compact {
  margin-bottom: .4rem;
}
</style>
