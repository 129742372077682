<template>
  <div class="section">
    <div class="container">
      <form @submit.prevent="sendFiles" enctype="multipart/form-data">
        <div
          v-if="message"
          :class="`message ${error ? 'is-danger' : 'is-success'}`"
        >
          <div class="message-body">{{ message }}</div>
        </div>
        <div class="field">
          <div class="file is-boxed is-warning">
            <label class="file-label">
              <input
                multiple
                type="file"
                ref="files"
                class="file-input"
                @change="selectFiles"
              />
              <span class="file-cta">
                <span class="file-icon"> <i class="fas fa-upload"></i> </span>
                <div class="span file-label">Choose a file...</div>
              </span>
            </label>
          </div>
        </div>
        <!-- Display files -->
        <div class="field">
          <div
            v-for="(file, index) in files"
            :key="index"
            :class="`level ${file.invalidMessage && 'has-text-danger'}`"
          >
            <div class="level-left">
              <div class="level-item">
                {{ file.name }}
                <span v-if="file.invalidMessage">
                  &nbsp; - {{ file.invalidMessage }}</span
                >
              </div>
            </div>
            <div class="level-right">
              <div class="level-item">
                <a @click.prevent="deleteFiles(index)" class="delete"></a>
              </div>
            </div>
          </div>
        </div>
        <div class="field">
          <button class="button is-info">Send</button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
//import axios from "axios";
import { httpClient } from "../api/http-client";
import _ from "lodash";
import config from "../../config";

export default {
  props: {
    namespace: { type: String, default: "" },
    recId: {
      type: [String, Number],
      default: -1
    }
  },
  data() {
    return {
      files: [],
      uploadFiles: [],
      message: "",
      error: false
    };
  },
  methods: {
    selectFiles() {
      const files = this.$refs.files.files;
      // Actual files to be uploaded
      this.uploadFiles = [...this.files, ...files];
      // Custom array of objects for displaying files
      this.files = [
        ...this.files,
        ..._.map(files, file => ({
          name: file.name,
          size: file.size,
          type: file.type,
          invalidMessage: this.validate(file)
        }))
      ];
    },
    deleteFiles(index) {
      this.files.splice(index, 1);
      this.uploadFiles.splice(index, 1);
    },
    validate(file) {
      const maxSize = config.fileUploads.MAX_SIZE;
      const allowedTypes = config.fileUploads.FILE_TYPES;
      if (file.size > maxSize) {
        return `File is too large. Max size is ${maxSize / 1000}KB`;
      }
      if (!allowedTypes.includes(file.type)) {
        return "Only images allowed";
      }
      return "";
    },
    async sendFiles(e) {
      e.preventDefault();
      // Add valid files to form data
      let formData = new FormData();
      _.forEach(this.uploadFiles, file => {
        if (this.validate(file) === "") {
          formData.append("files", file);
        }
      });
      const url = `${process.env.VUE_APP_BASE_URL}/issue/multiple`;
      try {
        await httpClient.post(url, formData, {
          headers: { issueId: this.recId }
        });
        //        await httpClient.post(url, formData);
        this.message = "Files have been uploaded";
        this.file = [];
        this.uploadFiles = [];
        this.error = false;
      } catch (err) {
        this.message = err.data.error;
        this.error = true;
      }
    }
  }
};
</script>

<style lang="css" scoped src="../../node_modules/bulma/css/bulma.css">
.dropzone {
  height: 50px;
  background-color: #ccc;
}
</style>