var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:{ 'enlargeable-image': true, active: _vm.state != 'delarged' }},[_c('div',_vm._g({ref:"slot",staticClass:"slot"},_vm.trigger === 'click'
        ? { click: _vm.enlarge }
        : { mouseenter: _vm.enlarge, mouseleave: _vm.reset }
    ),[_vm._t("default",function(){return [_c('img',{staticClass:"default",attrs:{"src":_vm.src}})]})],2),_c('div',_vm._g({staticClass:"full",class:_vm.state,style:(_vm.styles)},_vm.trigger === 'click' ? { click: _vm.reset } : {}),[_c('div',{staticClass:"small-image"},[(
          _vm.state !== 'enlarged' &&
          _vm.state !== 'enlarging' &&
          _vm.state !== 'delarging'
        )?_c('img',{attrs:{"src":_vm.src}}):_vm._e()]),(_vm.state === 'enlarged')?_c('img',{attrs:{"src":_vm.src_large}}):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }