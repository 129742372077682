<template>
  <div>
    <b-container>
      <b-row>
        <div class="mx-auto col-md-6 text-center mt-4">
          <img
            src="../../assets/g3tools-orange-64x64.png"
            width="35"
            class="mr-2 mb-4"
          />
          <h1>404 - Page Not Found</h1>
          <p>This page no longer exists or was moved to another location</p>
          <img src="../../assets/vnn404.jpg" width="600px" class="mt-4 venn" />
          <b-button
            size="lg"
            variant="outline-dark"
            class="mt-5"
            @click="goHome"
            >Take me Home</b-button
          >
        </div>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import { handleRouterPushError } from "../../utils/component-utils.js";

export default {
  methods: {
    goHome() {
      this.$router.push({ path: "/" }).catch(err => {
        handleRouterPushError(err);
      });
    }
  }
};
</script>

<style lang="css" scoped>
.venn {
  margin-left: -35px;
}
</style>