// store/modules/issue.store.js
import * as baseStore from "./base2.store";
//import * as commentStore from "./issue-comments.store";
import * as issueModel from "../../views/issue/issue-model.js";
import config from "../../../config.js";
import CommonApi from "../../api/common.api";

const cApi = new CommonApi();

const initialState = () => {
  return {
    ...baseStore.state,
    comments: [],
    commentsCopy: [],
    attachments: [],
    attachmentsCopy: [],
    list: {
      allowInsert: false,
      currentPage: 1,
      documentTitle: "g3tools - Processing Issues",
      fields: [],
      imageField: "imageUrl",
      keyField: "",
      listName: "Processing Issues - All Open",
      orderClause: "",
      pageSize: 50,
      role: "Issue",
      routeName: "Issue",
      searchText: "",
      selectedItems: 0,
      selectRowShowsDetail: true,
      showAdd: true,
      showDelete: false,
      sortOrder: "DESC",
      totalRows: 0,
    },
    entityDescription: "Unknown entity type",
    subNavTitle: "Unknown Title",
    sidebarHidden: false,
    filters: issueModel.getFilters(config.SCOPES.CLIENT),
  };
};

const state = initialState();

// Getters specific to issue.store
const getters = {
  ...baseStore.getters,
  //...commentStore.getters,
  currentItem: (state) => state.currentItem,
  isItemLoaded: (state) => {
    return !!state.currentItem;
  },
  list: (state) => state.list,
  filters: (state) => state.filters,
  items: (state) => state.items,
  comments: (state) => state.comments,
  attachments: (state) => state.attachments,
  selectMode: (state) => {
    return { ...state.selectMode, derived: true };
  },
};

const actions = {
  ...baseStore.actions,
  //...commentStore.actions,

  async initializeStore({ commit, rootState }) {
    // Set fields and filters based on scope
    const useScope = rootState.Session.scope;
    const scopedFields = issueModel.getFields(useScope);
    commit("SET_FIELDS", scopedFields);
    const scopedFilters = issueModel.getFilters(useScope);
    commit("SET_FILTERS", scopedFilters);
  },
  // Update existing comment
  updateComment({ commit, state }, updatedComment) {
    const existingComment = state.comments.find(
      (comment) => comment?.id === updatedComment?.id
    );
    if (existingComment) {
      commit("COMMENT_SET", updatedComment);
    } else {
      console.error(`Comment with ID ${updatedComment?.id} does not exist.`);
    }
  },
  // Add new comment to record
  async addComment({ commit, state, rootState }) {
    // If already has a new comment, skip
    if (state.currentItem?.comments?.find((x) => x.id === -1)) return;
    const issueId = state.currentItem?.id;
    const newComment = {
      id: -1,
      issueId,
      createdById: rootState.Session.user?.id,
      comment: "",
      lastUpdatedById: rootState.Session.user?.id,
    };
    commit("COMMENT_NEW_SET", newComment);
  },

  async clearFilters({ state, dispatch }, { fetchOnStart }) {
    // Set filters based on route
    await dispatch("baseClearFilters", {
      endpoint: "/issue",
      fetchOnStart,
      childState: state,
    });
    await dispatch("baseFetchAll", {
      endpoint: "/issue",
      childStateList: state.list,
      childStateFilters: state.filters,
    });
  },
  async clearFiltersOnly({ state, commit, dispatch }) {
    // Set filters based on route
    await dispatch("dynamicFilters");
    await baseStore.actions.baseClearFiltersOnly({ state, commit, dispatch });
  },
  dynamicFilters(context) {
    issueModel.dynamicFilters(state, context);
  },

  async createNewItem({ dispatch, commit, rootState }, item) {
    const user = rootState.Session.user;
    commit("ISSUE_ITEM_RESET");
    dispatch("basePrepareCurrentItem");
    let newIssue = issueModel.defaultIssue(user);
    // Get options & control number
    const endpoint = `/issue/options-for-client?clientId=${item.clientId}&tenantId=${item.tenantId}`;
    const endpoint2 = "/issue/control-number";
    await Promise.all([
      dispatch("baseGetOptions", endpoint),
      dispatch("basePostGeneral", {
        endpoint: endpoint2,
        clientId: item.clientId,
        tenantId: item.tenantId,
      }).then((controlResult) => {
        newIssue.controlNumber = controlResult.data;
      }),
    ]);

    // Priority
    const defaultPriority = await dispatch("baseGetDefaultFilter", {
      fieldName: "priorityName",
      idField: "priorityId",
    });
    newIssue.priorityId = defaultPriority?.defaultOptionId;
    newIssue.priorityName = defaultPriority?.defaultOptionName;
    // Defaults
    newIssue.listedQty = 0;
    newIssue.otherQty = 0;
    newIssue.returnedQty = 0;
    newIssue.disposedQty = 0;
    // New Item defaults: Derive from item or session.tenantClients
    if (user.scope === "tenant") {
      newIssue.clientFacilityId =
        user.tenantSelectedClient.defaultClientFacilityId;
      newIssue.clientLobId = user.tenantSelectedClient.defaultClientLobId;
      newIssue.clientName = user.tenantSelectedClient.clientName;
    } else {
      newIssue.clientName = user.client.clientName;
    }
    newIssue.processorName = user.processor.processorName;
    // Merge passed in item with newIssue
    newIssue = { ...newIssue, ...item };
    commit("CURRENT_ITEM_SET", newIssue);
    dispatch("addComment");
  },

  // Use the shared fetchItem action from base.store
  async fetchItem({ dispatch, commit }, props) {
    const { item, tenantId, clientId } = props;
    commit("ISSUE_ITEM_RESET");
    // Get issue and related comments, attachments, and logs from db
    const moreParams = item.purchasedItemRecordId
      ? `&purchasedItemRecordId=${item.purchasedItemRecordId}`
      : "";
    const response = await dispatch("baseFetchItem", {
      endpoint: "/issue",
      item,
      tenantId,
      clientId,
      moreParams,
    });
    // Move child data into state
    commit("COMMENT_ITEMS_SET", response?.data?.comments);
    commit("COMMENT_ITEMS_COPY_SET", response?.data?.comments);
    commit("ATTACHMENT_ITEMS_SET", response?.data?.attachments);
    commit("ATTACHMENT_ITEMS_COPY_SET", response?.data?.attachments);
    // Add new comment
    await dispatch("addComment");
  },

  async fetchAll({ dispatch, state }) {
    await dispatch("baseFetchAll", {
      endpoint: "/issue",
      childStateList: state.list,
      childStateFilters: state.filters,
    });
  },

  async fetchProductInfo({ commit }, payload) {
    const endpoint = "/issue/purch-item-info";
    const productInfo = await cApi.crudPost({ endpoint, data: payload });
    if (!productInfo.data) return null;
    const prod = { ...productInfo.data };
    prod.asin = productInfo.data.productId;
    prod.purchasedItemRecordId = payload.purchasedItemRecordId;
    commit("CURRENT_ITEM_SET_ASSIGN", prod);
    return prod;
  },

  clearProductInfo({ commit }, payload) {
    commit("CURRENT_ITEM_SET_ASSIGN", payload);
  },

  async itemSaveUnreadFlag({ dispatch }, id) {
    if (state.currentItem?.id !== id) {
      throw new Error("Current item doesn't match expected item id");
    }
    const payload = {
      id: id,
      tenantId: state.currentItem.tenantId,
      clientId: state.currentItem.clientId,
    };
    await dispatch("baseItemSave", {
      endpoint: "/issue/unread",
      record: payload,
    });
  },

  async itemSaveUnlinked(_, item) {
    const payload = {
      id: item.id,
      tenantId: item.tenantId,
      clientId: item.clientId,
    };
    await cApi.crudUpdateOne({
      endpoint: "/issue/unlink",
      record: payload,
    });
  },

  async itemCreate({ commit, dispatch }, record) {
    commit("CURRENT_ITEM_SET_ASSIGN", record);
    let newRecord = {
      id: record?.id,
      tenantId: record.tenantId,
      clientId: record.clientId,
      ...state.currentItem,
      changedComments:
        state.comments.filter((comment) => comment.comment) || [],
      changedAttachments:
        state.attachments.filter(
          (attachment) => attachment.fullKey || attachment.thumbnailKey
        ) || [],
    };
    // Save to db if NOT called from purchased item; PI will handle db save
    const savedRecord = await dispatch("baseItemCreateDirect", {
      endpoint: "/issue",
      record: newRecord,
    });
    commit("CURRENT_ITEM_SET_ASSIGN", savedRecord.data);
    //}
  },

  async itemSave({ dispatch, commit }, record) {
    commit("CURRENT_ITEM_SET_ASSIGN", record);
    const [recordChanges, changedComments, changedAttachments] =
      await Promise.all([
        dispatch("getCurrentItemChanges"),
        dispatch("getCommentChanges"),
        dispatch("getAttachmentChanges"),
      ]);
    let updatedRecord = {
      id: record?.id,
      tenantId: record.tenantId,
      clientId: record.clientId,
    };
    // Send combined currentItem with changed comments and attachments
    if (recordChanges || changedComments || changedAttachments) {
      if (recordChanges) {
        updatedRecord = { ...updatedRecord, ...recordChanges };
      }
      if (changedComments) {
        updatedRecord.changedComments = changedComments;
      }
      if (changedAttachments) {
        updatedRecord.changedAttachments = changedAttachments;
      }
    }
    const bucketMeta = await dispatch("s3BucketMeta")
    const savedRecord = await dispatch("baseItemSaveDirect", {
      endpoint: "/issue",
      record: updatedRecord,
      headers: bucketMeta,
    });
    commit("CURRENT_ITEM_SET_ASSIGN", savedRecord.data);
  },

  async itemsDeleteValidate(_, items) {
    const linkedItems = items.find((x) => x.purchasedItemRecordId);
    if (linkedItems) {
      // Tell user
      const message = `You cannot delete issues that are linked to a purchased item.
      
      One or more of the issues you have selected are linked to a purchased item. Please unlink these and try again. You can unlink an Issue either from the issue record (Other Info tab), or from the Purchased Item record.`;
      return message;
    }
    return null;
  },

  async itemsDelete({ dispatch }, items) {
    await dispatch("baseItemsDelete", {
      endpoint: "/issue/bulk-delete?soft=true",
      items,
    });
  },

  async getCurrentItemChanges({ dispatch, state }) {
    return await dispatch("getChanges", {
      current: state.currentItem,
      copy: state.currentItemCopy,
    });
  },
  async getCommentChanges({ dispatch, state }) {
    let deltaChanges = [];
    // Iterate through original comments and commentsCopy
    for (const updatedComment of state.comments) {
      if (updatedComment?.id === -1 && updatedComment.comment) {
        // New comment; add to changes
        deltaChanges.push(updatedComment);
      } else {
        // Existing comment
        const copiedComment = state.commentsCopy.find(
          (c) => c.id === updatedComment?.id
        );
        if (copiedComment) {
          // Found copied comment. Now compare
          const changedComment = await dispatch("getChanges", {
            current: updatedComment,
            copy: copiedComment,
          });
          if (changedComment) {
            deltaChanges.push(changedComment);
          }
        }
      }
    }
    // Identify deleted comments:
    const deletedComments = state.commentsCopy
      .filter(
        (copyComment) =>
          !state.comments.some((comment) => comment?.id === copyComment?.id)
      )
      .map((deletedComment) => ({
        ...deletedComment,
        delete: true,
      }));
    deltaChanges = [...deletedComments, ...deltaChanges];
    return deltaChanges;
  },

  async getAttachmentChanges({ dispatch, state }) {
    let deltaChanges = [];
    // Iterate through original attachments and attachmentsCopy
    for (const updatedAttachment of state.attachments) {
      if (!updatedAttachment?.id || updatedAttachment?.id === -1) {
        // New attachment; add to changes
        updatedAttachment.id = -1;
        deltaChanges.push(updatedAttachment);
      } else {
        // Existing attachment
        const copiedAttachment = state.attachmentsCopy.find(
          (c) => c?.id === updatedAttachment?.id
        );
        if (copiedAttachment) {
          // Found copied attachment. Now compare
          const changedAttachment = await dispatch("getChanges", {
            current: updatedAttachment,
            copy: copiedAttachment,
          });
          if (changedAttachment) {
            deltaChanges.push(changedAttachment);
          }
        }
      }
    }
    // Identify deleted attachments:
    const deletedAttachments = state.attachmentsCopy
      .filter(
        (copyAttachment) =>
          !state.attachments.some(
            (attachment) => attachment?.id === copyAttachment?.id
          )
      )
      .map((deletedAttachment) => ({
        ...deletedAttachment,
        delete: true,
      }));
    deltaChanges = [...deletedAttachments, ...deltaChanges];
    return deltaChanges;
  },

  async s3BucketMeta({ state }) {
    return {
      "x-bucketMeta-id": state.currentItem?.id,
      "x-bucketMeta-tenant-id": state.currentItem?.tenantId,
      "x-bucketMeta-client-id": state.currentItem?.clientId,
      "x-bucketMeta-client-name": state.currentItem?.clientName,
      "x-bucketMeta-processor-name": state.currentItem?.processorName,
    };
  },

  async cleanupAttachments({ state, dispatch }) {
    // Find newly added images: Attachments that are not in attachmentsCopy
    // Since this is called when we cancel, we need to remove them from S3
    const addAttachments = state.attachments.filter(
      (attachment) =>
        !state.attachmentsCopy.some((copy) => copy?.id === attachment?.id)
    );
    const restoreAttachments = state.attachmentsCopy.filter(
      (attachmentCopy) =>
        !state.attachments.some(
          (attachment) => attachment?.id === attachmentCopy?.id
        )
    );
    let deletePromises = [];
    const bucketMeta = await dispatch("s3BucketMeta");
    if (addAttachments && addAttachments.length > 0) {
      // In attachments but not in copy: Was added before cancel: Need to remove
      const endpoint = "/issue/dropzone/permanent";
      deletePromises.push(
        ...addAttachments.map((attachment) =>
          cApi.crudDelete({
            endpoint,
            data: attachment,
            bucketMeta,
          })
        )
      );
    }
    if (restoreAttachments && restoreAttachments.length > 0) {
      // In attachmentsCopy but not in attachments: Was removed before cancel: Need to restore
      const endpoint = "/issue/dropzone/restore";
      const restorePromises = restoreAttachments.map(async (attachment) => {
        try {
          await cApi.crudPost({
            endpoint,
            data: attachment,
            bucketMeta,
          });
        } catch (error) {
          const err = new Error(
            `Error restoring attachment ID ${attachment?.id}`
          );
          console.error(err);
          throw err;
        }
      });
      await Promise.allSettled(restorePromises);
      // Clear deleted for those we don't know about (canceled before saving)
      const endpoint2 = `/issue/dropzone/clean-deleted-folder?key=issue/${state.currentItem?.id}`;
      await Promise.all([
        ...deletePromises,
        cApi.crudDelete({
          endpoint: endpoint2,
          bucketMeta,
        }),
      ]);
    }
  },

  // Cleanup control number
  async cleanupControlNumber(_, payload) {
    const endpoint = "issue/control-number";
    await cApi.crudDelete({ endpoint, data: payload });
  },

  // Dropdown filter set
  async setFilterSelectedItems(context, payload) {
    context.commit("FILTER_SELECTED_SET", payload);
  },
};

const mutations = {
  ...baseStore.mutations,
  //...commentStore.mutations,
  ATTACHMENT_ADD(state, data) {
    if (!state.attachments) state.attachments = [];
    state.attachments.push(data);
  },
  ATTACHMENT_REMOVE(state, data) {
    if (!state.attachments) return;
    if (!Array.isArray(state.attachments)) return;
    state.attachments = state.attachments.filter(
      (file) => file.thumbnailKey !== data
    );
  },
  ATTACHMENT_ITEMS_SET(state, data) {
    state.attachments = data;
  },
  ATTACHMENT_CHANGED_ITEMS_SET(state, data) {
    state.currentItem.changedAttachments = data;
  },
  ATTACHMENT_ITEMS_COPY_SET(state, data) {
    state.attachmentsCopy = [...data];
  },
  HAS_ATTACHMENTS(state, data) {
    state.hasAttachments = data;
  },
  ISSUE_ITEM_RESET(state) {
    state.currentItemCopy = {};
    state.comments = [];
    state.commentsCopy = [];
    state.attachments = [];
    state.attachmentsCopy = [];
  },
  COMMENT_ITEMS_SET(state, data) {
    state.comments = data;
  },
  COMMENT_CHANGED_ITEMS_SET(state, data) {
    state.currentItem.changedComments = data;
  },
  COMMENT_ITEMS_COPY_SET(state, data) {
    state.commentsCopy = [...data];
  },
  COMMENT_SET(state, data) {
    const commentIndex = state.comments.findIndex((x) => x.id === data?.id);
    if (commentIndex !== -1) {
      state.comments[commentIndex] = {
        ...state.comments[commentIndex],
        ...data,
      };
    }
  },
  COMMENT_ITEM_DELETE(state, id) {
    state.comments = state.comments.filter((c) => c.id !== id);
  },
  COMMENT_NEW_SET(state, data) {
    state.comments?.unshift(data);
  },
  // CURRENT_ITEM_RESTORE(state) {
  //   state.currentItem.comments = state.comments;
  //   state.currentItem.attachments = state.attachments;
  // },
  // CURRENT_ITEM_SET(state, data) {
  //   state.currentItem = data;
  // },
  // RESET(state) {
  //   Object.assign(state, initialState());
  // },
  SET_FIELDS(state, data) {
    state.list.fields = data;
  },
  SET_FILTERS(state, data) {
    state.filters = data;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
