<template>
  <div class="info-container" :style="cssVars">
    <button
      v-if="buttonType === 'info'"
      @click="togglePopover"
      class="info-button"
      @click.stop.prevent
      :tabindex="-1"
    >
      <b-icon
        icon="info-circle"
        aria-hidden="true"
        class="info-icon"
      ></b-icon>
    </button>

    <button
      v-if="buttonType === 'down'"
      @click="togglePopover"
      class="down-button"
      @click.stop.prevent
      :tabindex="-1"
    >
      <b-icon
        icon="chevron-down"
        aria-hidden="true"
        class="down-icon"
      ></b-icon>
    </button>

    <div v-if="popoverVisible" class="custom-popover">
      <!-- Card Heading -->
      <div class="info-header">
        <span>{{ heading }}</span>
        <button @click="closePopover" class="close-btn mb-1">&times;</button>
      </div>
      <!-- Text body -->
      <div class="info-body" v-if="text">
        <div class="body-text" v-html="text"></div>
      </div>
      <!-- Table body -->
      <div>
        <b-table
          :items="items"
          :fields="fields"
          small
          hover
          sticky-header="true"
          thead-class="text-smaller"
          class="base-info-table-condensed"
          thead-tr-class="base-info-table-header-class" >
          <template #cell(dateData)="data">
            <span v-html="data.value"></span>
          </template>
          <template #cell(byData)="data">
            <span v-html="data.value"></span>
          </template>
        </b-table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    heading: {
      type: String,
      required: true,
      default: "Popover Title",
    },
    text: {
      type: String,
      default: null,
    },
    tableItems: [],
    tableFields: [],
    buttonType: {type: String, default: "info"}, // Valid: info, down   
    width: {
      type: String,
      default: "400px",
    },
    top: {
      type: String,
    },
    bottom: {
      type: String,
    },
    left: {
      type: String,
    },
    right: {
      type: String,
    },
  },
  data() {
    return {
      popoverVisible: false,
    };
  },
  computed: {
    cssVars() {
      const positionStyles = {
        // Note to make this work, must specify ":style = 'cssVars'" in top div element above.
        "--width": this.width,
        "--left": this.left,
        "--right": this.right,
      };
      // Only apply top or bottom, but not both
      if (this.top) {
        positionStyles["--top"] = this.top || "40px";
      } else {
        positionStyles["--bottom"] = this.bottom;
      }
      return positionStyles;
    },
    items() {
      return this.tableItems;
    },
    fields() {
      return this.tableFields;
    },
  },
  methods: {
    togglePopover() {
      this.popoverVisible = !this.popoverVisible;
      if (this.popoverVisible) {
        this.$emit("show"); // Emit 'show' event when popover opens
      } else {
        this.$emit("hide"); // Emit 'hide' event when popover closes
      }
    },
    closePopover() {
      this.popoverVisible = false;
      this.$emit("hide"); // Emit 'hide' event when popover closes
    },
  },
};
</script>

<style scoped>
.info-button {
  display: flex;
  background-color: transparent; 
  color: rgb(129, 170, 183);
  height: 16px;
  width: 16px;
  border: none; 
  border-radius: 50%; 
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  transition: box-shadow 0.3s;
}

/* Optional: Add hover effect */
.info-button:hover {
  box-shadow: 1px 4px 10px rgba(0, 0, 0, 0.3);
}

.info-button:active {
  transform: translateY(2px); 
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2); 
}

.info-icon {
  margin-bottom: 5px;
}

.down-button {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent; 
  color: #999;
  height: 20px;
  width: 20px;
  padding-top: 2px;
  border: none; 
  border-radius: 3px; 
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  transition: box-shadow 0.3s;
}

.down-button:hover {
  box-shadow: 1px 4px 10px rgba(0, 0, 0, 0.3);
}

.down-button:active {
  transform: translateY(2px); 
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2); 
}

.down-icon {
  font-size: .9rem;
  font-weight: 800;
}


.info-container {
  position: relative;
  display: inline-block;
  padding: 0;
  margin: 0;
}

.custom-popover {
  position: absolute;
  top: var(--top, auto);
  bottom: var(--bottom, auto);
  left: var(--left, auto);
  right: var(--right, auto);
  z-index: 1000;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  width: var(--width);
}

.info-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 500;
  height: 38px;
  padding: 5px 10px;
  border-bottom: 1px solid #ddd;
  background-color: #eee;
}

.info-body {
  padding: 10px 10px 0px 10px;
}

.body-text {
  font-size: 0.9rem;
}

.close-btn {
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
}

.table {
  margin-top: 10px;
}



</style>
