<template>
  <div>
    <!-- <img src="../assets/logo_g3tools_blue_trans.png" width="56" class="p-2" /> -->
    <img src="../assets/g3tools-orange-64x64.png" width="32" class="mr-1" />
    <span class="g3tools-nav ml-1 mr-1">>g3tools</span>
  </div>
</template>

<script>
export default {
  props: {
    textColor: {
      type: String,
      default: "#555",
    },
    fontSize: {
      type: String,
      default: "1em",
    },
  },
  computed: {
    // cssVars() {
    //   return
    //     /* variables you want to pass to css */
    //     "--color": this.textColor,
    //   };
    // },
  },
};
</script>

<style lang="css" scoped>
.g3tools-nav {
  color: #989696;
  font-size: .7em;
}
img {
  margin-left: 0px;
  margin-top: -20px;
  margin-bottom: -20px;
}
span {
  /* font-family: "Dekko", cursive; */
  /* font-family: "Sniglet", cursive; */
  font-family: "Comfortaa", cursive;
  font-size: 1em;
  font-weight: 500;
  /* color: var(--textColor); */
}
</style>