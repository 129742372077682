<template>
  <div class="d-flex align-items-center showing-total-row">
    <div v-if="list.totalRows === 0">{{ noResultsMsg }}</div>
    <div v-else v-html="useShowing" ></div>
    <div v-show="list.selectedItems.length > 0 && list.selectMode != 'single'" class="ml-3 text-secondary">
      <i
        ><strong>{{ list.selectedItems.length }}</strong> selected</i
      >
    </div>
  </div>
</template>

<script>
// import { mapState } from 'vuex'

export default {
  props: {
    namespace: { type: String, default: "" }
  },
  data() {
    return {
      noResultsMsg: "No results"
    };
  },
  computed: {
    list() {
      return this.$store.getters[`${this.namespace}/list`];
    },
    useShowing: function() {
      // If total rows <= page size: "Showing all xx rows"
      // Else, "Showing 1-25 of 486 rows" (on page 1)
      //    Showing 26-50 of 486 rows -- on page 2
      if (!this.list.totalRows) {
        // Nothing to show
        return "";
      }
      if (this.list.totalRows <= this.list.pageSize) {
        // Showing all rows
        return `Showing all <strong>${this.list.totalRows.toLocaleString()}</strong> rows`;
      } else {
        // Showing subset of rows; reflect current page
        const start =
          this.list.currentPage * this.list.pageSize - (this.list.pageSize - 1);
        const end =
          this.list.currentPage * this.list.pageSize <= this.list.totalRows
            ? this.list.currentPage * this.list.pageSize
            : this.list.totalRows;
        return `Showing <strong>${start} - ${end}</strong> of <strong>${this.list.totalRows.toLocaleString()}</strong> rows`;
      }
    }
  }
};
</script>
<style lang="css" scoped>
</style>
