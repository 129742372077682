// Common action functions in all entity stores

export async function fetchItem(cApi, commit, payload) {
  try {
    const id = payload?.id || payload;
    const detail = payload.detail || false;
    commit("CURRENT_ITEM_SET", {});
    commit("LOGS_SET", {});
    // Get data and logs
    const response = await cApi.getOne(id, detail);
    const logs = await cApi.getLogsForOne(id);

    // const responses = await Promise.all([
    //   cApi.getOne(id, detail),
    //   cApi.getLogsForOne(id),
    // ]);
    const record = Array.isArray(response.data.data)
      ? response.data.data[0]
      : response.data.data;
    commit("CURRENT_ITEM_SET", record);
    // App logs for this item
    if (logs.data.data) {
      const logData = logs.data.data;
      commit("LOGS_SET", logData);
    }
  } catch (error) {
    console.error("There was an error fetching an item:", error);
  }
}

export async function fetchAll(cApi, state, commit, dispatch) {
  try {
    const props = {
      pageSize: state.list.pageSize,
      currentPage: state.list.currentPage,
      sortBy: state.list.sortBy,
      sortOrder: state.list.sortOrder,
      orderClause: state.list.orderClause,
      searchText: state.list.searchText,
      filters: state.filters,
    };
    await dispatch("Session/freezeIsLoading", true, { root: true });
    //const waitResponse = await Promise.all([
    // Fill filter options but turn off busy (async call; don't wait)
    dispatch("fetchOptions");
    // Get all records
    const response = await cApi.getAll(props);
    // Get calculated fields async (don't wait)
    await getCalculatedFields(cApi, commit, props);
    //]);
    // Promise.all returns an array of responses; I want the third
    //const response = waitResponse[2];
    await commit("LIST_SET", {
      key: "items",
      value: response?.data?.data,
    });
    await commit("LIST_SET", {
      key: "totalRows",
      value: response?.data?.meta?.totalCount,
    });
    await commit("LIST_SET", {
      key: "itemsLastRefreshed",
      value: new Date().toLocaleString(),
    });
  } catch (error) {
    // handle the error here
    console.error("Error fetching all items:", error?.data?.error);
  } finally {
    await dispatch("Session/freezeIsLoading", false, { root: true });
  }
}

export async function getCalculatedFields(cApi, commit, props) {
  const response = await cApi.getCalculatedFields(props);
  await commit("LIST_SET", {
    key: "totalRows",
    value: response?.data?.meta?.totalCount,
  });
  if (response?.data.meta?.calcs) {
    await commit("LIST_SET", {
      key: "calcs",
      value: response?.data?.meta?.calcs,
    });
  }
}

// Fetch filter options for all filters in store
export async function fetchOptions(cApi, state, commit) {
  try {
    state.filters.forEach(async (filter) => {
      if (filter.type === "select") {
        // Skip if we don't need options
        if (filter.fetchOptions !== undefined && filter.fetchOptions === false)
          return;
        // Skip if not included in table (still need options in detail record)
        //if (!filter.includeInTable) return;
        // Skip if we already have options (log off and on to refresh all options) --> No. Need to refresh each time in case we delete the option
        //if (filter.options && filter.options.length > 0) return;
        // if (filter.type === "select" && filter.includeInTable) {
        //await dispatch("Session/setShowLoading", true, { root: true });
        const response = await cApi.getOptions(filter.field);
        //await dispatch("Session/setShowLoading", true, { root: true });
        commit("FILTER_OPTIONS_SET", {
          fieldName: filter.field,
          options: response,
        });
      }
    });
  } catch (error) {
    // handle the error here
    console.error(`Error fetching item options: ${error}`);
  }
}

// Fetch filter options for specific filter
export async function fetchOptionsForFilter(
  cApi,
  state,
  commit,
  dispatch,
  field
) {
  try {
    const filter = state.filters.find((x) => x.field === field);
    if (!filter) return false;
    if (filter.type !== "select") return false;
    await dispatch("Session/setShowLoading", true, { root: true });
    const response = await cApi.getOptions(filter.field);
    await dispatch("Session/setShowLoading", true, { root: true });
    commit("FILTER_OPTIONS_SET", {
      fieldName: filter.field,
      options: response,
    });
    return response;
  } catch (error) {
    // handle the error here
    console.error(`Error fetching item options: ${error}`);
  }
}

// Create individual item record
export async function itemCreate(cApi, commit, dispatch, item, keyField) {
  // Save to database
  try {
    const createdItem = await cApi.createOne(item);
    if (createdItem.data.alert) {
      // Alert: post it and stay here
      dispatch(
        "Notification/alertErrorAdd",
        { message: createdItem.data.alert, show: true },
        {
          root: true,
        }
      );
      return false;
    } else {
      // Call mutation after successful save
      const msg = `Successfully added ${keyField}`;
      dispatch("Notification/toastMsgAdd", msg, { root: true });
      // Change sort to ts desc
      commit("LIST_SET", { key: "sortBy", value: "ts" });
      commit("LIST_SET", { key: "sortOrder", value: "DESC" });
      commit("CURRENT_ITEM_SET", createdItem.data.data[0]);
      // Refresh list
      await dispatch("fetchAll");
      return true;
    }
  } catch (error) {
    if (error.response) {
      console.error("There was an error adding an item:", error.response);
    } else {
      console.error("There was an error adding an item:", error);
    }
  }
}

// Create individual item record and return id
export async function itemCreateSpecial(
  cApi,
  //commit,
  dispatch,
  item
  //keyField,
  //useMutation
) {
  // Save to database
  try {
    const createdItem = await cApi.createOne(item);
    if (createdItem.data.alert) {
      // Alert: post it and stay here
      dispatch(
        "Notification/alertErrorAdd",
        { message: createdItem.data.alert, show: true },
        {
          root: true,
        }
      );
      return false;
    } else {
      // Call mutation after successful save
      //commit(useMutation, createdItem.data.data[0]);
      return createdItem.data.data[0];
    }
  } catch (error) {
    if (error.response) {
      console.error("There was an error adding an item:", error.response);
    } else {
      console.error("There was an error adding an item:", error);
    }
  }
}

// Save single item
export async function itemSave(
  cApi,
  commit,
  dispatch,
  item,
  useAlertId,
  postToast = true
) {
  // Save item to database
  try {
    const updatedItem = await cApi.updateOne(item);
    if (updatedItem.data.alert) {
      // Alert: post it and stay here
      dispatch(
        "Notification/alertErrorAdd",
        { message: updatedItem.data.alert, show: true },
        {
          root: true,
        }
      );
      return false;
    } else {
      // Call mutation after successful save
      if (postToast) {
        const msg = `Successfully updated ${
          useAlertId ? useAlertId : item?.id
        }`;
        dispatch("Notification/toastMsgAdd", msg, { root: true });
      }
      if (updatedItem?.data?.message === "Nothing to update") {
        return true;
      }
      commit("CURRENT_ITEM_SET", updatedItem.data.data[0]);
      // Refresh list
      //await dispatch("fetchAll");
      return true;
    }
  } catch (error) {
    console.error("There was an error updating an item:", error);
    return false;
  }
}

// Save single item with passed in mutation
export async function itemSaveSpecial(cApi, dispatch, item) {
  // Save item to database
  try {
    const updatedItem = await cApi.updateOne(item);
    if (updatedItem.data.alert) {
      // Alert/error: post it and stay here
      dispatch(
        "Notification/alertErrorAdd",
        { message: updatedItem.data.alert, show: true },
        {
          root: true,
        }
      );
      return false;
    } else {
      // Call mutation after successful save
      return updatedItem.data.data[0];
    }
  } catch (error) {
    console.log(
      "There was an error updating an item (special):",
      error.response
    );
    return false;
  }
}

// Delete multiple items (selected items)
export async function deleteSelectedItems(cApi, commit, state, dispatch) {
  let i = 0;
  try {
    // Deleted selected for loop
    const deleteItems = [];
    for (let j = 0; j < state.list.selectedItems.length; j++) {
      // Delete item from database
      const item = state.list.selectedItems[j];
      i++;
      deleteItems.push(item?.id);
      // Remove item from list
      commit("ITEM_REMOVE", item?.id);
    }
    // Delete batch
    await cApi.deleteManyById({ ids: deleteItems });
    await dispatch("fetchAll");
    // Success: Tell user
    const msg = `Successfully deleted ${i >= 200 ? "maximum of 200" : i} ${
      i > 1 ? "records" : "record"
    }`;
    dispatch("Notification/toastMsgAdd", msg, {
      root: true,
    });
    // Update state
    commit("CLEAR_SELECTED_ITEMS");
    i = 0;
  } catch (err) {
    const msg = `Error delete record: ${err}`;
    dispatch("Notification/toastErrorAdd", msg, {
      root: true,
    });
  }
  i = 0;
}

// Delete single item, special (passed in cApi for end point, along with item and mutation)
export async function deleteItemSpecial(
  cApi,
  commit,
  dispatch,
  item,
  mutation
) {
  try {
    // Delete item
    await cApi.deleteOne(item);
    // Update state
    commit(mutation, item);
  } catch (err) {
    const msg = `Error deleting record id ${item?.id}: ${err}`;
    dispatch("Notification/toastErrorAdd", msg, {
      root: true,
    });
  }
}

export async function clearFilters(state, commit, dispatch, payload) {
  const filter = payload.filter;
  const fetchOnStart =
    payload.fetchOnStart === undefined ? true : payload.fetchOnStart;

  // Clear selected
  await commit("LIST_SET", { key: "selectedItems", value: [] });
  // Clear paging
  await commit("LIST_SET", { key: "currentPage", value: 1 });
  // Clear search
  await commit("LIST_SET", { key: "searchText", value: "" });
  // Clear sort
  await commit("LIST_SET", {
    key: "sortBy",
    value: state.list.defaultSortBy || "",
  });
  await commit("LIST_SET", {
    key: "sortOrder",
    value: state.list.defaultSortOrder || "",
  });
  // Clear filter values
  state.filters.forEach(async (filter) => {
    // If filter has a default value, use that, otherwise, ""
    await commit("FILTER_SELECTED_SET", {
      fieldName: filter.field,
      value: filter.defaultValue ? filter.defaultValue : null,
    });
  });
  // Refresh list
  if (fetchOnStart === false) return;
  await dispatch("fetchAll", filter);
}

// Clear filter values only
export async function clearFiltersOnly(state, commit) {
  state.filters.forEach(async (filter) => {
    // If filter has a default value, use that, otherwise, ""
    await commit("FILTER_SELECTED_SET", {
      fieldName: filter.field,
      value: filter.defaultValue ? filter.defaultValue : null,
    });
  });
}

// Action filter button set
export async function setActionButton(commit, dispatch, payload) {
  await commit("ACTION_FILTER_SELECTED_SET", {
    fieldName: payload.fieldName,
    fieldValue: payload.fieldValue,
  });
  // Refresh list
  await dispatch("fetchAll", payload?.filter);
}

// Dropdown filter set
export async function filterSelectedItemsSet(commit, dispatch, payload) {
  commit("FILTER_SELECTED_SET", payload);
  // Refresh list
  await dispatch("fetchAll", payload?.filter);
}
