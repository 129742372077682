<template>
  <!-- Form group -->
  <div class="form-group" :style="cssVars">
    <!-- Label/value group -->
    <div class="label-value-group" :class="{ 'right-aligned': alignRight, 'read-only-control': readonly}" >
      <div class="label-container my-auto" ref="labelContainerRef" >
        <label :for="`${id}-spinner`" class="label-format" :class="labelClass">{{ label }}</label>
      </div>
      <div class="input-error-container" ref=inputErrorContainerRef>
        <div class="input-container my-auto" ref="inputContainerRef">
          <b-input-group
            :id="`${id}-spinner`"
            class="custom-input-group"
            :class="{ 'readonly-mode': readonly, 'custom-input-group-border': !readonly, 'custom-input-group-border-error' : error }"
            :disabled="disabled"
            :state="state"
          >
            <b-input-group-prepend v-if="!readonly">
              <b-button
                variant="light"
                class="custom-button-left custom-button my-auto"
                @click.prevent.stop="decrement"
                tabindex="-1"
                :disabled="disabled"
                :class="{'disabled-button' : disabled}"

              >
                <span class="symbol">−</span>
              </b-button>
              <div class="minus-button-right-border"></div>
            </b-input-group-prepend>
            <b-form-input 
              v-model="localValue" 
              :ref="`${id}-spinner`"
              v-if="!readonly" 
              :autofocus="autofocus"
              class="text-center no-spinners custom-input my-auto" 
              :class="{ 'readonly-input': readonly }"
              type="text" 
              :style="{ backgroundColor: readonly ? 'transparent' : '' }"
              :disabled="disabled"
              @keydown.up.prevent="increment" 
              @keydown.down.prevent="decrement"
              @blur="validateValue" 
              @input="handleInput" 
              @focus="selectAll"
              :size="size"
            />
            <!-- Read only value -->
            <div v-if="readonly" class="readonly-value" :class="{ 'readonly-value-right-align': readonlyValueRightAlign }">{{
              localValue
            }}</div>
            <b-input-group-append v-if="!readonly">
              <div class="plus-button-left-border"></div>
              <b-button
                variant="light"
                class="custom-button-right custom-button"
                tabindex="-1"
                @click.prevent.stop="increment"
              :disabled="disabled"
              :class="{'disabled-button' : disabled}"
              >
                <span class="symbol">+</span>
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </div>
      </div>
    </div>
    <!-- Error group -->
    <div :id="`${id}-live-feedback`" v-if="error" class="error-feedback">{{errorMsg}}</div>
  </div>
</template>

<script>
export default {
  props: {
    alignRight: {type: Boolean, default: false },
    autofocus: { type: Boolean, default: false },
    description: { type: String },
    disabled: {type: Boolean, default: false},
    error: { type: Boolean },
    errorMsg: { type: String },
    formGroupBottom: { type: String, default: "6px" },
    icon: { type: String, default: "" },
    id: { type: String },
    inline: { type: Boolean, default: false },
    label: { type: String, default: "" },
    labelAlign: { type: String, default: "left" },
    labelClass: { type: String, default: "" },
    labelFontColor: { type: String, default: "" },
    labelFontSize: { type: String, default: ".9rem" },
    labelFontWeight: { type: String, default: "" },
    labelWidth: { type: [String, Boolean, Number], default: "120px" },
    marginLeftValue: { type: String, String, default: "" },
    marginLeft: { type: String, default: "" },
    marginRight:{ type: String, default: "" },
    max: { type: Number, default: Infinity },
    min: { type: Number, default: 0 },
    readonly: { type: Boolean, default: false },
    readonlyValueFontSize: { type: String, default: "1rem" },
    readonlyValueFontWeight: { type: String, default: "400" },
    readonlyValueFontColor: { type: String, default: ".9rem" },
    readonlyValueMarginBottom: { type: String, default: "0px" },
    readonlyValueRightAlign: { type: [Boolean, null], default: null },
    readonlyValueWidth:  { type: String, default: "" },
    readonlyMarginTop: { type: String, default: "0px" },
    readonlyMarginBottom: { type: String, default: "7px" },
    step: { type: Number, default: 1 },
    size: { type: String, default: "sm" },
    state: { type: [Boolean, null], default: null },
    uppercase: { type: Boolean, default: false },
    value: { type: [String, Number], default: 0 },
    valueWidth: { type: String, default: "110px" },
    width: { type: String, default: "150px" },
  },
  data() {
    return {
      localValue: this.value,
      inputMarginLeft: 0,
    };
  },
  computed: {
    cssVars() {
      return {
        // Note to make this work, must specify ":style = 'cssVars'" in top div element above.
        "--from-group-bottom": this.formGroupBottom,
        "--margin-right": this.marginRight,
        "--margin-left": this.marginLeft,
        "--margin-left-value": this.marginLeftValue,
        "--label-width": this.labelWidth,
        "--label-text-align": this.labelAlign,
        "--label-font-color": this.labelFontColor,
        "--label-font-size": this.labelFontSize,
        "--label-font-weight": this.labelFontWeight,
        "--value-width": this.valueWidth,
        "--read-only-font-color": this.readonlyValueFontColor,
        "--read-only-font-size": this.readonlyValueFontSize,
        "--read-only-font-weight": this.readonlyValueFontWeight,
        "--read-only-value-margin-bottom": this.readonlyValueMarginBottom,
        "--read-only-value-width": this.readonlyValueWidth,
        "--read-only-margin-top": this.readonlyMarginTop,
        "--read-only-margin-bottom": this.readonlyMarginBottom,
        "--input-margin-left": this.inputMarginLeft
      };
    }
  },
  watch: {
    value(newValue) {
      this.localValue = newValue;
    },
    localValue(newValue) {
      this.$emit("input", newValue);
    },
  },
  mounted() {
    this.$nextTick(() => {
    setTimeout(() => {
      const marginLeftNumber = Number(this.marginLeftValue.replace("px", "")) ? Number(this.marginLeftValue.replace("px", "")) : 0;
      const actualWidth = this.getInputMarginLeft();
      this.inputMarginLeft = `${actualWidth + marginLeftNumber}px`;
    }, 50);


    });
  },
  methods: {
    getInputMarginLeft() {
      const divElement = this.$refs.labelContainerRef;
      if (divElement) {
        return Number(divElement.offsetWidth + 5);
      }
      return null;
    },

    getDivAbsoluteLeft() {
      const divElement = this.$refs.inputContainerRef;
      if (divElement) {
        const rect = divElement.getBoundingClientRect();
        return rect.left; // Absolute position from the left edge of the viewport
      }
      return null;
    },

    increment() {
      const newValue = this.localValue + this.step;
      if (newValue <= this.max) {
        this.localValue = newValue;
      }
    },
    decrement() {
      const newValue = this.localValue - this.step;
      if (newValue >= this.min) {
        this.localValue = newValue;
      }
    },
    handleInput(event) {
      const inputValue = event;
      // Allow empty string (for backspace handling) or valid number
      if (inputValue === "" || isNaN(Number(inputValue))) {
        this.localValue = "";  // Let the field be empty while the user edits
      } else {
        this.localValue = Number(inputValue);  // Convert to number when valid input
      }
      this.$emit("input", this.localValue);
    },
     // When the user leaves the input field, we convert an empty value to 0
    validateValue() {
      if (this.localValue === "") {
        this.localValue = 0;  // Set to 0 if left empty
        this.$emit("input", this.localValue);  // Emit the updated value
      }
    },
    selectAll() {
      this.$nextTick(() => {
        this.$refs[`${this.id}-spinner`].select();
      });
    },
  },
};
</script>

<style scoped>
.form-group {
  margin-bottom: var(--from-group-bottom);
}

.label-value-group {
  display: flex;
  align-items: center;
  flex-shrink: 1;
  margin-right: var(--margin-right);
  margin-left: var(--margin-left);
  margin-bottom: 0;
}

.right-aligned {
  justify-content: flex-end;
}

.label-container, .input-div {
  display: flex;
  align-items: center;
  align-content: center;
}

.label-container, label {
  font-size: .9rem;
  margin-bottom: 0;
  width: var(--label-width);  
  text-align: var(--label-text-align);
  margin-right: 7px;
}

.label-format {
  font-size: var(--label-font-size);
  font-weight: var(--label-font-weight);
  color: var(--label-font-color);
}

.input-container {
  display: flex;
  flex-shrink: 1;
  width: auto;
  margin-left: var(--margin-left-value);
}

.custom-input {
  font-weight: 500;
  border: none;
  padding: 4px;
}

/* Unified border and appearance */
.custom-input-group {
  margin-left: 0;
  width: var(--value-width);
  display: flex;
  flex-shrink: 1;
  padding: 0;
}

.custom-input-group-border {
  border: 1px solid #ced4da; 
  border-radius: 4px;
}

.custom-input-group:focus-within {
  border: 1px solid #80bdff; 
  outline: 3.5px solid #badafb;
  border-radius: 4px;
}

.custom-input-group .form-control {
  border: none;
  margin-left: 0;
  height: 27px;
  text-align: left;
}

/* ********************************************** */
/*          Plus/Minus Buttons
/************************************************ */
.symbol {
  font-size: 1.7em;
  margin-bottom: 3px;
  font-weight: 300
}

/* Adjust for button styles */
.custom-button,
.custom-button-left,
.custom-button-right {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  background-color: #fff;
  padding: 0;
  height: 27px;
  transition: none; /* Prevent shifting */
}

.custom-button:hover {
  background-color: #dddddd;
}

.custom-button-left {
  border: 0;
}

.custom-button-right {
  border: 0;
}

.disabled-button {
  color: #bbb;
  background-color: #E9ECEf;
}

.disabled-button:hover {
  background-color: #E9ECEF;
}

/* Hide buttons in readonly mode */
.readonly-mode .custom-button-left,
.readonly-mode .custom-button-right {
  display: none;
}

.minus-button-right-border {
  border: 0;
  border-left: 1px solid #ced4da;
  height: 18px;
  z-index: 4;
  margin-top: 5px;
}

.plus-button-left-border {
  border: 0;
  border-right: 1px solid #ced4da;
  height: 18px;
  z-index: 4;
  margin-top: 5px;
}
.text-center {
  text-align: center;
}

/* Ensure focus appearance applies to the entire group */
.custom-button-left:focus,
.custom-button-right:focus,
.custom-input:focus {
  outline: none;
  box-shadow: none;
}

/* Remove individual button/input borders on focus */
.custom-input-group .custom-button-left:focus,
.custom-input-group .custom-button-right:focus,
.custom-input-group .custom-input:focus {
  border: none;
}

/* Read-only mode */
.read-only-control {
  text-align: left;
  border: none;
  margin-top: var(--read-only-margin-top);
  margin-bottom: var(--read-only-margin-bottom);
}

.readonly-mode input {
  width: auto !important;
  background-color: transparent;
  border: 0;
  margin-top: var(--read-only-margin-top);
}

.readonly-value {
  font-weight: var(--read-only-font-weight);
  padding-bottom: 0;
  font-size: var(--read-only-font-size);
  color: var(--read-only-font-color);
  line-height: 1;
  padding-left: 2px;
  /*border: 1px solid red;*/
}

.readonly-mode .custom-input-group {
  width: auto !important;
  flex-direction: row;
}

.readonly-value-right-align {
  text-align: right;
  width: var(--read-only-value-width);
}

/* ********************************************** */
/*          Plus/Minus Buttons
/************************************************ */
.error-feedback {
  color: #dc3545;
  margin: 0;
  padding: 0;
  margin-top: 3px;
  margin-left: var(--input-margin-left);
  font-size: .9rem;
}

.custom-input-group-border-error {
  border: 1px solid #dc3545; 
  border-radius: 4px;
}


</style>
