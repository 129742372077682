/*
FILE UPLOAD DATA
"Saved Upload Maps"
--------------------
savedMaps [ (originates from store state.savedMaps -> getters.savedMaps)
	map {
		id, listName, 
		name: "test save 3", 
		uploadMap: [
			{
				isMatch: true,
				label: "Product description",
				mappedLabel: "name"
				name: "productDescription"
			}
		]
	}
]
"Purchased Item List"
--------------------
this.fields [ (originates from store, state.currentUploadMap -> getters.currentUploadMap -> purchased-item-model.uploadFieldsMap[])
	field {
		isHovered: false,
		label: "Product description",
		mappedLabel: "name",
    (mappedLabel is actually an object from this.pills):
      {
        id: 1,
        incomingLabel: "name",
        originalIndex: 0
      }
		name: "productDescription"
	}
]
"Incoming Fields"
--------------------
this.pills [ (originates locally from FileUpload.populateArrays, not from store)
	pill: {
		id: 1,
		incomingLabel: "name",
		originalIndex: 0
	}
]
*/

/**
 * Compare uploadMap to see if mappedFields still exist in csvFields or in fields already mapped.
 * A new property is added to uploadMap objects: isMatch.
 * @param {array} uploadMap - Objects that map uploadMap to csv columns (savedMap.uploadMap)
 * @param {array} csvFields  - Incoming csv data (one ojbect per column)
 * @returns New array like uploadMap with .isMatch added property.
 */
export const validateMappedFields = (uploadMap, csvFields, fields) => {
  const validatedMap = uploadMap?.map((uploadItem) => {
    let isMatch;
    // If not, see if exists in imcoming fields (hasn't been mapped yet)
    // Step 1: Check for exact match
    isMatch = csvFields.some(
      (csvField) => csvField.incomingLabel === uploadItem.mappedLabel
    );
    // Step 2: If no exact match, check by converting both to lower case
    if (!isMatch) {
      isMatch = csvFields.some(
        (csvField) =>
          csvField.incomingLabel.toLowerCase() ===
          uploadItem.mappedLabel.toLowerCase()
      );
    }
    // Step 3: If still no match, strip spaces and compare
    if (!isMatch) {
      const sanitizedField = uploadItem.mappedLabel.replace(/\s+/g, "");
      isMatch = csvFields.some(
        (csvField) =>
          csvField.incomingLabel.replace(/\s+/g, "") === sanitizedField
      );
    }
    if (!isMatch) {
      // See if already mapped to a field
      const foundInFields = fields.find(
        (field) => field.name === uploadItem.name
      );
      if (foundInFields) {
        if (
          foundInFields?.mappedLabel?.incomingLabel ===
            uploadItem?.mappedLabel ||
          foundInFields?.mappedLabel === uploadItem?.mappedLabel
        ) {
          isMatch = true;
        }
      }
    }
    // Add the isMatch property to the field object
    return {
      ...uploadItem,
      isMatch,
    };
  });
  return validatedMap;
};

export const applyMapToFields = (savedMap, pills, fields) => {
  // Note that fields and savedMap cannot be mutated here
  const updatedFields = fields.map((field) => ({ ...field }));
  // Loop through each item in the savedMap's uploadMap array
  savedMap.uploadMap.forEach((map) => {
    if (map.isMatch) {
      // Step 1: Find the matching pill in this.pills array
      const matchedPillIndex = pills.findIndex(
        (pill) => pill.incomingLabel === map.mappedLabel
      );
      if (matchedPillIndex !== -1) {
        const matchedPill = pills[matchedPillIndex];
        //        const incomingLabel = matchedPill.incomingLabel;
        // Step 2: Find the matching field in this.fields array
        const matchedField = updatedFields.find(
          (field) => field.label === map.label
        );
        if (matchedField) {
          // Step 3: Set the matched field's mappedLabel to the pill's incomingLabel
          matchedField.mappedLabel = matchedPill;
          matchedField.pctDataFilled = matchedPill.pctDataFilled;
          // Step 4: Remove the matching pill object from the pills array
          pills.splice(matchedPillIndex, 1);
        }
      }
    }
  });
  return updatedFields;
};

export const mapCsvData = async (fields, csvData) => {
  //const uploadMap = savedMap.uploadMap;
  const mappedCsvData = csvData
    .map((row) => {
      // For each row in csvData
      const newObj = {};
      let isEmptyRow = true;
      fields.forEach((field) => {
        // For each mapped column object
        if (field) {
          const sourceKey = field.mappedLabel?.incomingLabel;
          const targetKey = field.name;
          const value = row[sourceKey];
          // If at least one value is non-empty, mark the row as not empty
          if (value) {
            isEmptyRow = false;
          }
          newObj[targetKey] = value;
        }
      });
      return isEmptyRow ? null : newObj;
    })
    // Filter out null rows
    .filter((row) => row !== null);
  return mappedCsvData;
};

export const generateUniqueName = (baseName, savedMaps) => {
  const nameRegex = /^(.*?)(?:\s*\((\d+)\))?$/;
  let newName = baseName;
  let counter = 1;
  while (savedMaps.some((map) => map.name === newName)) {
    const match = nameRegex.exec(newName);
    if (match) {
      const base = match[1].trim();
      const currentNum = match[2] ? parseInt(match[2], 10) : 0;
      counter = currentNum + 1;
      newName = `${base} (${counter})`;
    } else {
      newName = `${newName} (${counter})`;
    }
  }
  return newName;
};
