<template>
  <b-modal :id="modalId" scrollable hide-footer centered class="custom-modal">
    <template #modal-title>
      <div class="d-flex justify-content-between w-100">
        <div>{{ title }}</div>
        <small class="text-muted mt-auto right-text"
          >{{ itemColumnCount }} Columns - (Showing first 500 rows)</small
        >
      </div>
    </template>
    <div class="table-container text-nowrap text-smaller pl-2">
      <b-table
        id="csv-table"
        :items="itemsWithIndex"
        :fields="formattedFields"
        responsive
        bordered
        sticky-header="80vh"
        small
      >
        <template v-slot:cell()="data">
          <td :class="getCellClass(data.item, data.field)">
            {{ data.item[data.field.key] }}
          </td>
        </template>
      </b-table>
    </div>
  </b-modal>
</template>

<script>
export default {
  name: "CsvModal",
  props: {
    modalId: { type: String, required: true },
    title: {
      type: String,
      default: "CSV Data Preview",
    },
    items: {
      type: Array,
      required: true,
    },
    csvFields: [],
  },
  mounted() {
  },
  computed: {
    formattedFields() {
      if (this.csvField) {
        return this.csvFields?.map(field => ({
          key: field.name,
          label: field.label,
        }));
      } else {
        return this.computedFields
      }
    },
    computedFields() {
      if (this.items.length === 0) {
        return []; // Return an empty array if there are no items
      }
      return [
        { key: "index", label: "#" },
        ...Object.keys(this.items[0]).map((field) => {
          return {
            key: field,
            label: field,
          };
        }),
      ];
    },
    itemColumnCount() {
      return Object.keys(this.items[0]).length;
    },
    // Add index to each item in the list
    itemsWithIndex() {
      return this.items.map((item, index) => {
        return {
          index: index + 1,
          ...item,
        };
      });
    },
  },
  methods: {
    showModal() {
      this.$bvModal.show(this.modalId);
    },
    hideModal() {
      this.$bvModal.hide(this.modalId);
    },
    getFieldDefinition(key) {
      return this.csvFields?.find(
        field => field.name === key
      );
    },
    getCellClass(item, field) {
      // Lookup the full field definition using the field key
      const fieldDef = this.getFieldDefinition(field.key);
      if (!fieldDef) {
        return '';
      }
      const isFieldRequired = fieldDef.isRequired;
      const fieldValue = item[field.key];
      if (isFieldRequired && (!fieldValue || fieldValue.trim() === "")) {
        return "empty-required-cell"; // Red background class
      }
      return ""; // No class if not required or value exists
    },
  },
};
</script>

<style scoped>
.table-container {
  overflow-x: auto;
  overflow-y: auto;
  width: 100%;
  max-height: 82vh;
}

.b-table {
  min-width: 800px;
  width: auto;
  border-collapse: collapse; /* Optional: prevents double borders */
}

.b-table thead th {
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 1;
}

.b-table td {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

td {
  border: none !important;
  padding: 0;
  vertical-align: middle;
  borded: 1px solid purple;
  height: 12px;
}

.cell-style {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100px;
}

.title-area {
  background-color: orangered;
  width: 90%;
  height: 50px;
}
.right-text {
  margin-left: 100px;
  font-size: 0.8rem;
}

.empty-required-cell {
  background-color: rgba(227, 149, 149, 0.905) !important; /* Ensure the red background stays */
  padding: 8px;
  vertical-align: middle;
  border-radius: 50%;
}
</style>
