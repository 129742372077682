import date from "date-and-time";
import config from "../../config";

export const formatDate = (rawDate) => {
  const convert = date.format(new Date(rawDate), "MM/DD/YYYY h:mm A");
  return convert;
};

export const formatDateCustom = (rawDate, format) => {
  const convert = date.format(new Date(rawDate), format);
  return convert;
};

export const formatDateFromUtc = (rawDate) => {
  if (!rawDate) return "";
  const convert = date.format(new Date(rawDate), "MM/DD/YYYY h:mm A", true);
  return convert;
};

// Credit: https://code.labstack.com/HVdZZYqH
export const formatSeconds = (seconds) => {
  // Hours, minutes and seconds
  var hrs = Math.floor(seconds / 3600);
  var mins = Math.floor((seconds % 3600) / 60);
  var secs = Math.floor(seconds % 60);

  // Output like "1:01" or "4:03:59" or "123:03:59"
  var ret = "";
  if (hrs > 0) {
    ret += "" + hrs + ":" + (mins < 10 ? "0" : "");
  }
  ret += "" + mins + ":" + (secs < 10 ? "0" : "");
  ret += "" + secs;

  return ret;
};

// Sleeps for given millisecondes
export const sleep = (ms) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

export const getPastDateInUTC = (days) => {
  const useDays = days ? days : config.pendingDays;
  const now = new Date();
  now.setDate(now.getDate() - useDays); // Subtract the number of days
  now.setUTCHours(23, 59, 59, 0); // Set time to 23:59:59.000 in UTC
  return now.toISOString(); // Convert to ISO string in UTC format
};
