<template>
  <div class="log-item m-0 p-0" :style = "cssVars">
    <b-row>
      <b-col cols="4" class="pl-1">
        <div class="d-flex align-items-center pl-0">
          <div :class="actionClass(item.action)">{{ item.action }}</div>
          <div class="log-date">{{ fDate(item.ts) }}</div>
          <div class="log-user">{{ item.userId }}</div>
        </div>
      </b-col>
      <b-col>
        <b-row>
          <b-col>
            <div v-html="logChanges(item.changes).firstColumnHtml"></div>
          </b-col>
          <b-col>
            <div v-html="logChanges(item.changes).secondColumnHtml"></div>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { formatDateCustom } from "../utils/date-utils.js";
import { formatCrudAction } from "../utils/component-utils";

export default {
  props: {
    item: {
      type: Object,
      default: null
    },
    userWidth: { type: [String, Number], default: "87px" },
  },
  computed: {
    cssVars() {
      return {
        // Note to make this work, must specify ":style = 'cssVars'" in top div element above.
        "--user-width": this.userWidth,
      };
    }
  },
  methods: {
    fDate: function(rawDate) {
      //return "test";
      return formatDateCustom(rawDate, "M/D/YY hh:mm A");
    },
    logChanges(obj) {
      const sortedKeys = Object.keys(obj).sort();
      const halfIndex = Math.ceil(sortedKeys.length / 2);

      // Split keys into two halves
      const firstHalf = sortedKeys.slice(0, halfIndex);
      const secondHalf = sortedKeys.slice(halfIndex);

      // Generate HTML for each half
      const formatHtml = keys => {
        let html = '<ul class="mb-0">';
        keys.forEach(key => {
          html += `<li><strong>${key}</strong>: ${obj[key]}</li>`;
        });
        html += '</ul>';
        return html;
      };

      // Return an object with HTML for both columns
      return {
        firstColumnHtml: formatHtml(firstHalf),
        secondColumnHtml: formatHtml(secondHalf)
      };
    },

      actionClass: function (action) {
      return formatCrudAction(action);
      // if (action === "Update") return "action-update";
      // else if (action === "Delete") return "action-delete";
      // return "action-insert";
    }
  }
};
</script>

<style lang="css" scoped>
div {
  font-size: 0.93em;
}
.log-item {
}
ul {
  margin-bottom: 0 !important;
}
.log-date {
  font-size: 0.8em;
  margin-top: 3px;
  margin-left: 5px;
  min-width: 85px;
}
.log-user {
  font-size: 0.8em;
  margin-top: 3px;
  margin-left: 5px;
  width: var(--user-width) !important;
}
.action-update {
  font-weight: 700;
  color: rgb(0, 153, 255);
}
.action-insert {
  font-weight: 700;
  color: rgb(18, 133, 18);
}
.action-delete {
  font-weight: 700;
  color: rgb(212, 89, 89);
}

</style>