<template>
  <div>
    <b-form-group
      :id="id"
      :label="label"
      :label-for="`${id}-input`"
      :label-cols="labelCols"
      :description="error ? '' : description"
      :label-align="labelAlign"
      :label-size="size"
      :label-class="labelClass"
      class="label"
      :class="{
        'form-group--error': error,
        'form-group--compact': compact,
        'text-bold': boldLabel,
      }"
    >
      <b-form-input
        :class="{
          'text-bold': boldValue,
          'right-align': rightAlignValue,
        }"
        :id="`${id}-input`"
        :list="`${id}-input-list`"
        :name="`${id}-input`"
        :type="type"
        :value="value"
        @input="updateValue(id, $event)"
        @focus="onFocus"
        @blur="onBlur"
        v-bind="$attrs"
        v-uppercase="uppercase"
        :autocomplete="autocomplete"
        :plaintext="plaintext"
        :placeholder="placeholder"
        :readonly="readonly"
        :style="`width: ${textFieldWidth}px; margin-left: ${marginLeftValue}px`"
        :size="size"
        :state="state"
        :aria-describedby="`${id}-live-feedback`"
        v-on="$listeners"
        :tabindex="tabIndex"
      ></b-form-input>
      <b-form-datalist 
        :id="`${id}-input-list`" 
        :options="getOptions">
        v-show="showDropdown"
      </b-form-datalist>
      <b-form-invalid-feedback :id="`${id}-live-feedback`">
        {{ errorMsg }}
      </b-form-invalid-feedback>
      <!-- </b-input-group> -->
    </b-form-group>
  </div>
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    namespace: {type: String, default: ""},
    autocomplete: { type: String, default: "on" },
    compact: { type: Boolean, default: false },
    description: { type: String },
    error: { type: Boolean },
    errorMsg: { type: String },
    icon: { type: String, default: "" },
    id: { type: String },
    label: { type: String, default: "" },
    labelAlign: { type: String, default: "left" },
    labelClass: { type: String, default: "" },
    labelCols: { type: [String, Boolean, Number], default: "0" },
    textClass: { type: String, default: "" },
    plaintext: { type: Boolean, default: false },
    readonly: { type: Boolean, default: false },
    size: { type: String, default: "" },
    state: { type: [Boolean, null], default: null },
    type: { type: String, default: "text" },
    value: [String, Number] ,
    uppercase: { type: Boolean, default: false },
    classField: { type: String, default: "" },
    placeholder: { type: String, default: "" },
    textFieldWidth: { type: [String, Number], default: "" },
    boldValue: { type: Boolean, default: false },
    boldLabel: { type: Boolean, default: false },
    rightAlignValue: { type: Boolean, default: false },
    marginLeftValue: { type: [String, Number], default: 0 },
  },
  data() {
    return {
      showDropdown: false
    }
  },
  computed: {
    tabIndex() {
      return this.readonly || this.plaintext ? "-1" : "0";
    },
    getOptions() {
      const filters = this.$store.getters[`${this.namespace}/filters`];
      const fieldModel = filters.find(item => item.field === this.id);
      return fieldModel ? fieldModel.itemOptions : [];
    },
  },
  methods: {
    onFocus() {
      this.showDropdown = true;
    },
     onBlur() {
      // Optionally hide dropdown on blur; you can add a slight delay
      setTimeout(() => {
        this.showDropdown = false;
      }, 100); // Adjust delay as necessary
    },
    updateValue(id, $event) {
      this.$emit("input", { field: id, value: $event });
      this.showDropdown = true;
    },
    touchValue(id, $event) {
      this.$emit("g3Touch", { field: id, value: $event });
    }
  }
};
</script>

<style lang="css" scoped>
.form-group--compact {
  margin-bottom: .4rem;
}
datalist option {
  background-color: #f8f9fa; /* Change background color */
  font-size: 14px;           /* Adjust font size */
  color: #333;               /* Text color */
  padding: 10px;             /* Padding between options */
}

datalist option:hover {
  background-color: #007bff; /* Background color on hover */
  color: white;
}
</style>
