<template>
  <!-- Supplemental Information card -->
  <div class="accordion" role="tablist">
    <b-card no-body class="mb-1">
      <b-card-header
        header-tag="header"
        class="p-1 text-center font-weight-bold"
      >
        <!-- <b-button disbled v-b-toggle.info-1 block variant="outline-secondary"
          >Information</b-button
        > -->
        Information
      </b-card-header>
      <b-card-body>
        <b-row>
          <b-col cols="4">
            <!-- record id -->
            <BaseFormInput
              id="id"
              label="Id:"
              labelCols="4"
              plaintext
              size="sm"
              labelClass="text-muted"
              compact
              v-model="info.id"
            />
          </b-col>
          <b-col>
            <!-- date modified -->
            <BaseFormInput
              id="ts"
              label="Modified:"
              :labelCols="labelCols"
              :style="{ marginLeft: `${adjustMarginLeft}px` }"
              labelAlign="right"
              size="sm"
              plaintext
              labelClass="text-muted"
              compact
              :value="new Date(info.ts).toLocaleString()"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="3">
            <slot name="secondRowLeft"></slot>
          </b-col>
          <b-col>
            <slot name="secondRowRight"></slot>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import { formatDateFromUtc } from "../utils/date-utils.js";

export default {
  name: "BaseInformationCard",
  props: {
    info: { type: Object, default: null },
    labelCols: { type: String, default: "3" },
    adjustMarginLeft: { type: String, default: "0" }
  },
  data() {
    return {
      //      recordId: this.item.id || "",
    };
  },
  methods: {
    fDate: function(rawDate) {
      //return "test";
      return formatDateFromUtc(rawDate);
    }
  }
};
</script>

<style lang="css" scoped>
.info-label {
  color: grey;
}
.info-card-header {
  margin-top: -12px;
  margin-bottom: -10px;
}
</style>