<template>
  <div>
    <b-form-group
      :id="id"
      :label="label"
      :label-for="`${id}-datepicker`"
      :label-cols="labelCols"
      :description="error ? '' : description"
      :label-align="labelAlign"
      :label-size="size"
      :label-class="labelClass"
      class="label"
      :class="{ 'form-group--error': error, 'form-group--compact': compact }"
    >
      <b-form-datepicker
        :aria-describedby="`${id}-live-feedback`"
        :class="{ 'readonly-mode': readonly }"
        :dark="dark"
        :disabled="disabled || readonly"
        :name="`${id}-datepicker`"
        :placeholder="placeholder"
        :readonly="readonly"
        :state="state"
        :size="size"
        :style="{
          width: `${valueFieldWidth}px`,
          marginLeft: `${marginLeftValue}px`,
        }"
        :value="value"
        :v-bind="$attrs"
        :v-on="$listeners"
        @input="updateValue($event)"
        reset-button
      ></b-form-datepicker>
      <b-form-invalid-feedback :id="`${id}-live-feedback`">{{
        errorMsg
      }}</b-form-invalid-feedback>
    </b-form-group>
  </div>
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    compact: { type: Boolean, default: false },
    dark: { type: Boolean, default: false },
    description: { type: String },
    disabled: { type: Boolean, default: false },
    error: { type: Boolean },
    errorMsg: { type: String },
    id: { type: String },
    label: { type: String, default: "" },
    labelAlign: { type: String, default: "left" },
    labelClass: [String],
    labelCols: { type: [Boolean, String, Number, null], default: null },
    marginLeftValue: { type: [Number, String], default: null },
    placeholder: { type: String, default: "" },
    readonly: { type: Boolean, default: false },
    size: { type: String, default: "" },
    state: [Boolean, null],
    value: [String, Number],
    valueFieldWidth: { type: [Number, String], default: null },
  },
  methods: {
    updateValue(newValue) {
      this.$emit("input", newValue);
    }
  }
};
</script>

<style lang="css" scoped>
.form-group--compact {
  margin-top: .4rem;
  margin-bottom: .4rem;
}

.readonly-mode {
  background-color: #f8f9f8 !important;
  border: 0;
  padding: 0;
  margin-left: -5px;
  margin-bottom: 5px;
  overflow-y: hidden !important;

}
.readonly-mode:focus-within {
  border: 1px solid #80bdff !important; 
  outline: 4px solid #badafb !important;
  border-radius: 4px;
}

</style>
