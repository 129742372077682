var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{style:(_vm.cssVars)},[_c('b-form-group',{staticClass:"label",class:{
      'form-group--error': _vm.error,
      'form-group--compact': _vm.compact,
      'form-group--compact--readonly': _vm.compact && _vm.readonly,
      'text-bold': _vm.boldLabel,
    },attrs:{"id":_vm.id,"label":_vm.label,"label-for":`${_vm.id}-input`,"label-cols":_vm.labelCols,"description":_vm.error ? '' : _vm.description,"label-align":_vm.labelAlign,"label-size":_vm.size,"label-class":_vm.labelClass}},[_c('b-form-input',_vm._g(_vm._b({directives:[{name:"uppercase",rawName:"v-uppercase",value:(_vm.uppercase),expression:"uppercase"}],staticClass:"custom-input",class:{
        'text-bold': _vm.boldValue,
        'right-align': _vm.rightAlignValue,
'input-readonly': _vm.readonly,
        'input-plaintext' : _vm.plaintext
      },style:(`width: ${_vm.textFieldWidth}px; margin-left: ${_vm.marginLeftValue}px`),attrs:{"autofocus":_vm.autofocus,"id":`${_vm.id}-input`,"name":`${_vm.id}-input`,"type":_vm.type,"value":_vm.value==='' ? '' : _vm.value,"autocomplete":_vm.autocomplete,"plaintext":_vm.plaintext || _vm.readonly,"placeholder":_vm.placeholder,"readonly":_vm.readonly,"size":_vm.size,"state":_vm.state,"aria-describedby":`${_vm.id}-live-feedback`},on:{"change":function($event){return _vm.updateValue(_vm.id, $event)},"input":function($event){return _vm.touchValue(_vm.id, $event)}}},'b-form-input',_vm.$attrs,false),_vm.$listeners)),_c('b-form-invalid-feedback',{attrs:{"id":`${_vm.id}-live-feedback`}},[_vm._v(" "+_vm._s(_vm.errorMsg)+" ")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }