// Common getter functions
export const emptyFilter = (state) => {
  // Enables filter clear button if isEmpty is false (something there):
  //  Nothing in search text, filter buttons, action buttons, selected
  return (
    state.list.searchText === "" &&
    state.filters.find((x) => x.value !== "" && x.value !== null) ===
      undefined &&
    state.list?.selectedItems?.length === 0
  );
};
export const lastRefreshed = (state) => {
  return `Last refreshed: ${state.list.itemsLastRefreshed}`;
};
