<template>
  <div>
  </div>
</template>

<script>

export default {
  components: {
  },
  props: {
    issueItems: []
  },
  data() {
    return "";
  },
  methods: {
  },
};
</script>

<style scoped>
</style>
