import Vue from "vue"
import Vuex from "vuex"
import createLogger from "vuex/dist/logger"
import modules from "./modules"

Vue.use(Vuex)
const debug = process.env.NODE_ENV !== "production"
const store = new Vuex.Store({
  modules,
  actions: {
    reset({ commit }) {
      // resets state of all the modules
      Object.keys(modules).forEach((moduleName) => {
        commit(`${moduleName}/RESET`);
      });
    },
  },
  strict: debug,
  state: {},
  mutations: {},
  plugins: debug ? [createLogger()] : [], // set logger only for development
});

// Call this from within any component or action:
//this.$store.dispatch('reset');
export default store;