import CommonApi from "../../api/common.api";
import * as cGetters from "../common/getters";
import * as cActions from "../common/actions";

// API end point
const cApi = new CommonApi("dashboard");

// State object
const initialState = () => {
  return {
    items: [],
    list: {
      selectedItems: [],
      totalRows: 0,
      pageSize: 50,
      currentPage: 1,
      sortBy: "",
      sortOrder: "",
      searchText: "",
      itemsLastRefreshed: "",
      calcs: [],
    },
    oarKpis: [],
    receiveSummary: [],
    shippedSummary: [],
    pendingAging: [],
    pendingAging30d: [],
    currentItem: {},
    filters: [],
    logs: [],
    subNavTitle: "Dashboard",
  };
};

const state = initialState();

// Getter functions
const getters = {
  currentItem(state) {
    return state.currentItem;
  },
  list(state) {
    return state.list;
  },
  logs(state) {
    return state.logs;
  },
  subNavTitle(state) {
    return state.subNavTitle;
  },
  filterIsEmpty(state) {
    return cGetters.emptyFilter(state);
  },
  filters(state) {
    return state.filters;
  },
  oarKpis(state) {
    return state.oarKpis;
  },
  lastRefreshed(state) {
    return cGetters.lastRefreshed(state);
  },
  qpAgedPending(state) {
    return state.oarKpis.find((x) => x.title === "QP Aged Units")?.value;
  },
  pendingAging(state) {
    return state.pendingAging || [];
  },
  pendingAging30d(state) {
    return state.pendingAging30d || [];
  },
  receiveSummary(state) {
    return state.receiveSummary || [];
  },
  shippedSummary(state) {
    return state.shippedSummary || [];
  },
};

// Actions
const actions = {
  // Fetch OAR KPIs
  async fetchOarKpis({ commit }) {
    const url = `/dashboard/kpis?timezoneOffset=${new Date().getTimezoneOffset()}`;
    const response = await cApi.getCustom(url);
    await commit("OAR_KPIS_SET", response.data);
    await commit("LIST_SET", {
      key: "itemsLastRefreshed",
      value: new Date().toLocaleString(),
    });
  },

  // Fetch Pending Summary data
  async fetchPendingAging30d({ commit }) {
    const url =
      "dashboard/pending-aging-aged30d?orderBy=yearMonth&orderDir=DESC";
    const response = await cApi.getCustom(url);
    await commit("PENDING_AGING_30D_SET", response.data);
  },

  // Fetch Pending Summary data
  async fetchPendingAging({ commit }) {
    const url = "dashboard/pending-aging?orderBy=yearMonth&orderDir=DESC";
    const response = await cApi.getCustom(url);
    await commit("PENDING_AGING_SET", response.data);
  },

  // Fetch Receive Summary data
  async fetchReceiveSummary({ commit }) {
    const url = "dashboard/receive-summary?orderBy=yearMonth&orderDir=DESC";
    const response = await cApi.getCustom(url);
    await commit("RECEIVE_SUMMARY_SET", response.data);
  },

  // Fetch Shipped Summary data
  async fetchShippedSummary({ commit }) {
    const url = "dashboard/shipped-summary?orderBy=yearMonth&orderDir=DESC";
    const response = await cApi.getCustom(url);
    await commit("SHIPPED_SUMMARY_SET", response.data);
  },

  // Fetch Receive 30d Summary data
  async fetchReceive30dSummary({ commit }) {
    const url = "dashboard/receive-30d-summary?orderBy=yearMonth&orderDir=DESC";
    const response = await cApi.getCustom(url);
    await commit("RECEIVE_SUMMARY_30D_SET", response.data);
  },

  async fetchItem({ commit }, id) {
    await cActions.fetchItem(cApi, commit, id);
  },

  async fetchAll({ state, commit, dispatch }) {
    await dispatch("Session/setSubNav", "", {
      root: true,
    });
    await cActions.fetchAll(cApi, state, commit, dispatch);
  },

  // Fetch filter options
  async fetchOptions({ state, commit, dispatch }) {
    await cActions.fetchOptions(cApi, state, commit, dispatch);
  },

  async itemCreate({ state, commit, dispatch }) {
    return await cActions.itemCreate(
      cApi,
      commit,
      dispatch,
      state.currentItem,
      state.currentItem.storeName
    );
  },

  async itemSave({ state, commit, dispatch }, payload) {
    const item = payload?.item || null;
    const postToast = payload?.postToast || null;
    const useItem = item ? item : state.currentItem;
    return await cActions.itemSave(
      cApi,
      commit,
      dispatch,
      useItem,
      `Store contact info ${state.currentItem?.id}`,
      postToast
    );
  },

  async deleteSelectedItems({ commit, state, dispatch }) {
    await cActions.deleteSelectedItems(cApi, commit, state, dispatch);
  },

  async clearFilters({ state, commit, dispatch }, filter) {
    await cActions.clearFilters(state, commit, dispatch, filter);
  },

  // Action filter button set
  async setActionButton({ commit, dispatch }, payload) {
    await cActions.setActionButton(commit, dispatch, payload);
  },

  // Dropdown filter set
  currentItemFieldSet({ commit }, payload) {
    commit("CURRENT_ITEM_FIELD_SET", payload);
  },
  listSet({ commit }, payload) {
    commit("LIST_SET", payload);
  },
  reset({ commit }) {
    commit("RESET");
  },
};

// Mutations
const mutations = {
  CLEAR_SELECTED_ITEMS(state) {
    state.selectedItems = [];
  },
  CURRENT_ITEM_SET(state, data) {
    state.currentItem = data;
  },
  CURRENT_ITEM_FIELD_SET(state, data) {
    state.currentItem[data.key] = data.value;
  },
  OAR_KPIS_SET(state, data) {
    state.oarKpis = data;
  },
  PENDING_AGING_SET(state, data) {
    state.pendingAging = data;
  },
  PENDING_AGING_30D_SET(state, data) {
    state.pendingAging30d = data;
  },
  RECEIVE_SUMMARY_SET(state, data) {
    state.receiveSummary = data;
  },
  SHIPPED_SUMMARY_SET(state, data) {
    state.shippedSummary = data;
  },
  RECEIVE_SUMMARY_30D_SET(state, data) {
    state.receive30dSummary = data;
  },
  ITEM_REMOVE(state, idToRemove) {
    state.items = state.items.filter((item) => item?.id !== idToRemove);
  },
  LIST_SET(state, data) {
    // data should be {key: 'sortOrder', value: 'DESC'}
    state.list[data.key] = data.value;
  },
  LOGS_SET(state, data) {
    state.logs = data;
  },
  RESET(state) {
    Object.assign(state, initialState());
  },
  SUB_NAV_SET(state, data) {
    state.subNavTitle = data;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
