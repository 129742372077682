<template>
  <div :style = "cssVars">
    <b-form-group
      :id="id"
      :label="label"
      :label-for="`${id}-input`"
      :label-cols="labelCols"
      :description="error ? '' : description"
      :label-align="labelAlign"
      :label-size="size"
      :label-class="labelClass"
      class="label"
      :class="{
        'form-group--error': error,
        'form-group--compact': compact,
        'form-group--compact--readonly': compact && readonly,
        'text-bold': boldLabel,
      }"
    >
      <b-form-input
        :class="{
          'text-bold': boldValue,
          'right-align': rightAlignValue,
  'input-readonly': readonly,
          'input-plaintext' : plaintext
        }"
        class="custom-input"
        :autofocus="autofocus"
        :id="`${id}-input`"
        :name="`${id}-input`"
        :type="type"
        :value="value==='' ? '' : value"
        @change="updateValue(id, $event)"
        @input="touchValue(id, $event)"
        v-bind="$attrs"
        v-uppercase="uppercase"
        :autocomplete="autocomplete"
        :plaintext="plaintext || readonly"
        :placeholder="placeholder"
        :readonly="readonly"
        :style="`width: ${textFieldWidth}px; margin-left: ${marginLeftValue}px`"
        :size="size"
        :state="state"
        :aria-describedby="`${id}-live-feedback`"
        v-on="$listeners"
      ></b-form-input>
      <b-form-invalid-feedback :id="`${id}-live-feedback`">
        {{ errorMsg }}
      </b-form-invalid-feedback>
      <!-- </b-input-group> -->
    </b-form-group>
  </div>
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    autocomplete: { type: String, default: "off" },
    autofocus: { type: Boolean, default: false },
    boldValue: { type: Boolean, default: false },
    boldLabel: { type: Boolean, default: false },
    classField: { type: String, default: "" },
    compact: { type: Boolean, default: false },
    description: { type: String },
    error: { type: Boolean },
    errorMsg: { type: String },
    valueHeight: { type: String, default: "" },
    icon: { type: String, default: "" },
    id: { type: String },
    label: { type: String, default: "" },
    labelAlign: { type: String, default: "left" },
    labelClass: { type: String, default: "" },
    labelCols: { type: [String, Boolean, Number], default: "0" },
    marginLeftValue: { type: [String, Number], default: 0 },
    marginLeftLabel: { type: [String, Number], default: 0 },
    placeholder: { type: String, default: "" },
    plaintext: { type: Boolean, default: false },
    readonly: { type: Boolean, default: false },
    rightAlignValue: { type: Boolean, default: false },
    size: { type: String, default: "" },
    state: { type: [Boolean, null], default: null },
    textClass: { type: String, default: "" },
    textFieldWidth: { type: [String, Number], default: "" },
    type: { type: String, default: "text" },
    value: [String, Number],
    uppercase: { type: Boolean, default: false },
  },
  mounted() {
  },
  computed: {
    tabIndex() {
      return this.readonly || this.plaintext ? "-1" : "0";
    },
    cssVars() {
      return {
        // Note to make this work, must specify ":style = 'cssVars'" in top div element above.
        "--margin-left-label": this.marginLeftLabel,
        "--input-height": this.valueHeight,
      };
    }
  },
  methods: {
    updateValue(id, $event) {
      this.$emit("g3Input", { field: id, value: $event });
    },
    touchValue(id, $event) {
      this.$emit("g3Touch", { field: id, value: $event });
    }
  }
};
</script>

<style lang="css" scoped>
.form-group--compact {
  margin-bottom: .4rem;
}

.form-group--compact--readonly {
  margin-bottom: 0px;
}
.input-plaintext {
  margin-top: 2px;
}
.input-readonly {
  border: 0 !important;
}
.input-readonly:focus-within {
  margin-left: 3px !important;
  border: 1px solid #80bdff !important; 
  outline: 4px solid #badafb !important;
  border-radius: 4px;
}

.custom-input {
  height: var(--input-height);
}
.custom-input::placeholder {
  font-style: italic !important;
  color: #888;
}

</style>
