import AmazonHome from "../views/amazon/AmazonHome.vue";
import BaseG3List from "../components/BaseG3List.vue";

const amazonInboundShipments = "AmazonInboundShipments";
const useNamespace = "Amazon";

const amazonRoutes = [
  {
    path: "/amazon",
    component: AmazonHome,
    meta: { requiresAuth: true },
    children: [
      {
        // Open issues
        path: "", // Default route
        name: amazonInboundShipments,
        component: BaseG3List,
        props: { namespace: useNamespace },
        meta: {
          breadcrumb: [
            { text: "Amazon", to: { name: amazonInboundShipments } },
            { text: "Amazon Info - Inbound Shipments" },
          ],
        },
      },
    ],
  },
];

export default amazonRoutes;
