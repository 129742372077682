// State object
const initialState = () => {
  return {
    // subNavTitle: "",
  }
}

const state = initialState()

// Getter functions
const getters = {}

// Actions
const actions = {
  // async setSubNavTitle({ commit }, value) {
  //   commit("SET_SUB_NAV_TITLE", value)
  // },
  reset({ commit }) {
    commit("RESET")
  },
}

// Mutations
const mutations = {
  RESET(state) {
    Object.assign(state, initialState())
  },
  // SET_SUB_NAV_TITLE(state, data) {
  //   state.subNavTitle = data
  // },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
