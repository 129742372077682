import CommonApi from "../../api/common.api";

// Base class for all stores, containing standard state, getters, actions, and mutations that apply to all stores.
class BaseStore {
  constructor(endpoint, childState) {
    // Required data for this class
    if (!childState)
      throw new Error("State object is reqired for baseStore class");
    if (!childState.list)
      throw new Error("list object is required in passed in state object");
    if (!childState.list.routeName)
      throw new Error("routeName is required in passed in state.list object`");
    if (!childState.list.documentTitle)
      throw new Error(
        "documentTitle is required in passed in state.list object`"
      );
    if (!childState.entityDescription)
      throw new Error(
        "entityDescription is required in passed in state object`"
      );
    if (!childState.subNavTitle)
      throw new Error("subNavTitle is required in passed in state object`");
    // Common STATE
    this.state = {
      endpoint: endpoint,
      list: {
        calcs: [],
        currentPage: childState?.list?.currentPage || 1,
        defaultSortBy: childState?.list?.defaultSortBy || "ts",
        defaultSortOrder: childState?.list?.defaultSortOrder || "DESC",
        items: [],
        parentId: childState?.list?.parentId,
        itemsLastRefreshed: childState?.list?.itemsLastRefreshed || "",
        pageSize: childState?.list?.pageSize || 50,
        role: childState?.list?.role || "",
        searchText: childState?.list?.searchText || "",
        selectedItems: [],
        sortBy: childState?.list?.sortBy || "ts",
        sortOrder: childState?.list?.sortOrder || "DESC",
        totalRows: childState?.list?.totalRows || 0,
        // Controls BaseG3List
        // route is used to combine with "Item" to route to the detail item component, i.e., for IssueItem, route would be Issue
        routeName: childState?.list?.routeName || "Unknown Route",
        documentTitle: childState?.list?.documentTitle || "Unknown Title",
        keyField: childState?.list?.keyField || "id",
        imageField: childState?.list?.imageField || "imageUrl60",
        amazonLinkField: childState?.list?.amazonLinkField || "asin",
        amazonUrlField: childState?.list?.amazonUrlField || "amazonUrl",
        class: childState?.list?.class || "g3table",
        style: childState?.list?.style || "",
        showOtherActions:
          childState?.list?.showOtherActions !== undefined
            ? childState?.list?.showOtherActions
            : true,
        selectMode: childState?.list?.selectMode || "multi",
        selectable:
          childState?.list?.selectable !== undefined
            ? childState?.list?.selectable
            : true,
        showAdd:
          childState?.list?.showAdd !== undefined
            ? childState?.list?.showAdd
            : true,
        showRefresh:
          childState?.list?.showRefresh !== undefined
            ? childState?.list?.showRefresh
            : true,
        showDrilldown:
          childState?.list?.showDrilldown !== undefined
            ? childState?.list?.showDrilldown
            : true,
        drilldownNamespace: childState?.list?.drilldownNamespace,
        drilldownId: -1,
        toggleDrilldown: false,
        allowInsert:
          childState?.list?.allowInsert !== undefined
            ? childState?.list?.allowInsert
            : true,
        showDelete:
          childState?.list?.showDelete !== undefined
            ? childState?.list?.showDelete
            : true,
        selectRowShowsDetail:
          childState?.list?.selectRowShowsDetail !== undefined
            ? childState?.list?.selectRowShowsDetail
            : false,
        fields: childState?.list?.fields || [],
        listName: childState?.list?.listName || "Unknown List",
        // Table width: At full screen, example: "80%"
        defaultContainerMaxWidth:
          childState?.list?.defaultContainerMaxWidth || "100%",
        // Table width: At 2150px, example: "90%"
        defaultContainerMinWidthAt2150px:
          childState?.list?.defaultContainerMinWidthAt2150px || "100%",
        // Table width: At 1920px, example: "100%"
        defaultContainerMinWidthAt1920px:
          childState?.list?.defaultContainerMinWidthAt1920px || "100%",
        // Optionally get calculated fields (for reports, for example, we won't)
        getCalculatedFields:
          childState?.list?.getCalculatedFields !== undefined
            ? childState?.list?.getCalculatedFields
            : true,
        // End Controls BaseG3List
      },
      currentItem: {},
      currentItemCopy: {},
      logs: [],
      entityDescription: childState?.entityDescription || "Unknown entity type",
      subNavTitle: childState?.subNavTitle || "Unknown Title",
      sidebarHidden: childState?.sideBarHidden || false,
      filters: childState?.filters || [],
    };

    // Common GETTERS
    this.getters = {
      lastRefreshed: (state) =>
        `Last refreshed: ${state.list.itemsLastRefreshed}`,
      emptyFilter: (state) => {
        // Enables filter clear button if isEmpty is false (something there):
        //  Nothing in search text, filter buttons, action buttons, selected
        state.list.searchText === "" &&
          state.filters.find((x) => x.value !== "" && x.value !== null) ===
            undefined &&
          state.list.selectedItems.length === 0;
      },
      filterIsEmpty: (state, getters) => getters.emptyFilter,
      currentItem: (state) => state.currentItem,
      selectedItems: (state) => state.list.selectedItems,
      filters: (state) => state.filters,
      list: (state) => state.list,
      logs: (state) => state.currentItem.logs,
      sidebarHidden: (state) => state.sidebarHidden,
      subNavTitle: (state) => state.subNavTitle,
    };

    // Common ACTIONS
    const cApi = new CommonApi(this.state.endpoint);
    const useEndpoint = endpoint;

    this.actions = {
      setCurrentItemToListItem(context, payload) {
        const id = payload?.id;
        const findItem = context.state.list.items.find((x) => x.id === id);
        context.commit("CURRENT_ITEM_SET", findItem);
      },

      async fetchItem(context, payload) {
        try {
          const id = payload?.itemId;
          const detail = payload?.detail || false;
          // Clear assets
          context.commit("CURRENT_ITEM_SET", {});
          context.commit("LOGS_SET", {});
          // Link current item to list item
          context.dispatch("setCurrentItemToListItem", context, { id });
          // Get data and logs
          const response = await cApi.getOne(id, detail);
          const record = Array.isArray(response.data)
            ? response.data[0]
            : response.data;
          context.commit("CURRENT_ITEM_SET_ASSIGN", record);
          // Make safety copy in case user cancels changes
          context.commit("CURRENT_ITEM_COPY_SET", record);
          // App logs for this item
          if (response.data.logs) {
            const logData = response.data.logs;
            context.commit("LOGS_SET", logData);
          }
        } catch (error) {
          console.log("There was an error fetching an item:", error);
        }
      },
      async fetchRowDetail(context, payload) {
        try {
          const props = {
            pageSize: context.state.list.pageSize,
            currentPage: context.state.list.currentPage,
            sortBy: context.state.list.sortBy,
            sortOrder: context.state.list.sortOrder,
            orderClause: context.state.list.orderClause,
            searchText: context.state.list.searchText,
            filters: context.state.filters,
            drilldownId: payload.drilldownId || -1,
            parentId: context.parentId,
          };
          // await context.dispatch("Session/freezeIsLoading", true, {
          //   root: true,
          // });
          // Clear items?
          if (context.state.list.drilldownId !== payload.drilldownId) {
            await context.commit("LIST_SET", {
              key: "items",
              value: [],
            });
          }
          // Get all records
          const response = await cApi.getAll(props);
          // See if we will be drilling down
          if (context.state.list.showDrilldown) {
            response?.data?.data.forEach((item) => (item._showDetails = false));
          }
          // Save current drilldownId
          await context.commit("LIST_SET", {
            key: "drilldownId",
            value: payload.drilldownId,
          });
          await context.commit("LIST_SET", {
            key: "items",
            value: response?.data?.data,
          });
        } catch (error) {
          // handle the error here
          console.error(
            "Error fetching row detail items:",
            error?.data?.error || error
          );
        } finally {
          // await context.dispatch("Session/freezeIsLoading", false, {
          //   root: true,
          // });
        }
      },
      async fetchAll(context, payload) {
        try {
          // Get items, options, and metadata (count, aggregates) in one http call
          await context.dispatch("Session/freezeIsLoading", true, {
            root: true,
          });
          // Prepare for options
          const selectedFields = context.state.filters
            .map((filter) => {
              if (filter.type === "select" && filter.fetchOptions) {
                return filter.field;
              }
              return null;
            })
            .filter(Boolean);
          // Get all records
          const props = {
            pageSize: context.state.list.pageSize,
            currentPage: context.state.list.currentPage,
            sortBy: context.state.list.sortBy,
            sortOrder: context.state.list.sortOrder,
            orderClause: context.state.list.orderClause,
            searchText: context.state.list.searchText,
            filters: context.state.filters,
            drilldownId: payload?.drilldownId || -1,
            parentId: context.state.list.parentId,
            selectedFields,
          };
          const response = await cApi.getAll(props);
          // No results?
          if (
            response?.data?.message &&
            response?.data?.message === "No results"
          ) {
            await context.commit("LIST_SET", {
              key: "items",
              value: null,
            });
            await context.commit("LIST_SET", {
              key: "totalRows",
              value: 0,
            });
          } else {
            // See if we will be drilling down
            if (context.state.list.showDrilldown) {
              response?.data?.data?.forEach(
                (item) => (item._showDetails = false)
              );
            }
            if (response?.data.meta?.calcs) {
              await context.commit("LIST_SET", {
                key: "calcs",
                value: response?.data?.meta?.calcs,
              });
            }
            await context.commit("LIST_SET", {
              key: "items",
              value: response?.data?.data,
            });
            await context.commit("LIST_SET", {
              key: "totalRows",
              value: response?.data?.meta?.totalCount,
            });
            await context.commit("LIST_SET", {
              key: "itemsLastRefreshed",
              value: new Date().toLocaleString(),
            });
            // Iterate over response to get options for each field
            Object.keys(response.data.options).forEach((key) => {
              context.commit("FILTER_OPTIONS_SET", {
                fieldName: key,
                options: response?.data?.options[key],
              });
            });
          }
        } catch (error) {
          // handle the error here
          console.error(
            "Error fetching all items:",
            error?.data?.error ? error?.data?.error : error
          );
        } finally {
          await context.dispatch("Session/freezeIsLoading", false, {
            root: true,
          });
        }
      },
      // // Fetch filter options for all filters in store
      // async fetchOptions(context) {
      //   try {
      //     // Collect all option fields
      //     let response;
      //     const options = context.state.filters
      //       .map((filter) => {
      //         if (filter.type === "select" && filter.fetchOptions) {
      //           return filter.field;
      //         }
      //         return null;
      //       })
      //       .filter(Boolean);
      //     // Get all options
      //     response = await cApi.getOptions({
      //       selectedFields: options,
      //       filters: context.state.filters,
      //     });
      //     // Iterate over response to get options for each field
      //     Object.keys(response.data).forEach((key) => {
      //       context.commit("FILTER_OPTIONS_SET", {
      //         fieldName: key,
      //         options: response.data[key],
      //       });
      //     });

      //     // await Promise.all(promises);
      //   } catch (error) {
      //     // handle the error here
      //     console.error(`Error fetching item options: ${error}`);
      //   }
      // },
      // Fetch filter options for specific filter
      async fetchOptionsForFilter(context, payload) {
        try {
          const field = payload.field;
          const filter = context.state.filters.find((x) => x.field === field);
          if (!filter) return false;
          if (filter.type !== "select") return false;
          await context.dispatch("Session/setShowLoading", true, {
            root: true,
          });
          const response = await cApi.getOptions({ fields: [filter.field] });
          await context.dispatch("Session/setShowLoading", true, {
            root: true,
          });
          context.commit("FILTER_OPTIONS_SET", {
            fieldName: filter.field,
            options: response,
          });
          return response;
        } catch (error) {
          // handle the error here
          console.error(`Error fetching item options: ${error}`);
        }
      },
      // Handle fetchOptionsForField
      async fetchOptionsForField(context, field) {
        return context.dispatch("fetchOptionsForFilter", { context, field });
      },

      // Create individual item record using state's current item and entity description
      async itemCreate(context, payload) {
        const useItem = payload?.item
          ? payload?.item
          : context.state.currentItem;
        const itemDescription = payload?.itemDescription
          ? payload.itemDescription
          : context.state.entityDescription;
        // Save to database
        try {
          const createdItem = await cApi.createOne(useItem);
          if (createdItem.data.alert) {
            // Alert: post it and stay here
            context.dispatch(
              "Notification/alertErrorAdd",
              { message: createdItem.data.alert, show: true },
              {
                root: true,
              }
            );
            return false;
          } else {
            // Call mutation after successful save
            const msg = `Successfully added ${itemDescription}`;
            context.dispatch("Notification/toastMsgAdd", msg, { root: true });
            // Change sort to ts desc
            context.commit("LIST_SET", { key: "sortBy", value: "ts" });
            context.commit("LIST_SET", { key: "sortOrder", value: "DESC" });
            context.commit("CURRENT_ITEM_SET", createdItem.data.data[0]);
            // Refresh list
            await context.dispatch("fetchAll", context);
            return true;
          }
        } catch (error) {
          if (error.response) {
            console.log("There was an error adding an item:", error.response);
          } else {
            console.log("There was an error adding an item:", error);
          }
        }
      },

      // // Create an issue with passed in item
      // async itemCreateRemote({ state, dispatch, commit }, item) {
      //   return await base.actions.itemCreate(
      //     { state, dispatch, commit },
      //     item,
      //     "issue"
      //   );
      // },

      // Create individual item record and return id
      async itemCreateSpecial(context, payload) {
        // Save to database
        try {
          const useApi = new CommonApi(payload.useEndpoint);
          const createdItem = await useApi.createOne(payload.item);
          if (createdItem.data.alert) {
            // Alert: post it and stay here
            context.dispatch(
              "Notification/alertErrorAdd",
              { message: createdItem.data.alert, show: true },
              {
                root: true,
              }
            );
            return false;
          } else {
            // Call mutation after successful save
            //commit(useMutation, createdItem.data.data[0]);
            return createdItem.data.data[0];
          }
        } catch (error) {
          if (error.response) {
            console.log("There was an error adding an item:", error.response);
          } else {
            console.log("There was an error adding an item:", error);
          }
        }
      },
      // Save single item
      async itemSave(context, payload) {
        // Save item to database
        const item = payload?.item || context.state.currentItem;
        const postToast = payload?.postToast || null;
        const useAlertId = `${context.state.entityDescription} ${context.state.currentItem?.id}`;
        // Increment version if passed in item or if current item
        if (!item) throw new Error("Current item object is required.");
        if (payload?.item) {
          // Item passed in
          item.version++;
          item.lastUpdatedBy = context.rootState.Session.user.familiarName;
        } else {
          // Current item
          context.commit("CURRENT_ITEM_FIELD_SET", {
            key: "version",
            value: context.state.currentItem.version + 1,
          });
          context.commit("CURRENT_ITEM_FIELD_SET", {
            key: "lastUpdatedBy",
            value: context.rootState.Session.user.familiarName,
          });
        }
        try {
          const updatedItem = await cApi.updateOne(item);
          if (updatedItem.data.alert) {
            // Alert: post it and stay here
            context.dispatch(
              "Notification/alertErrorAdd",
              { message: updatedItem.data.alert, show: true },
              {
                root: true,
              }
            );
            return false;
          } else {
            // Call mutation after successful save
            if (postToast) {
              const msg = `Successfully updated ${
                useAlertId ? useAlertId : item.id
              }`;
              context.dispatch("Notification/toastMsgAdd", msg, { root: true });
            }
            if (updatedItem?.data?.message === "Nothing to update") {
              return true;
            }
            context.commit("CURRENT_ITEM_SET", updatedItem.data.data[0]);
            // Refresh list
            //await dispatch("fetchAll");
            return true;
          }
        } catch (error) {
          console.log("There was an error updating an item:", error);
          return false;
        }
      },

      // Save single item with passed in mutation
      async itemSaveSpecial(context, payload) {
        // Save item to database
        try {
          const useApi = new CommonApi(payload.useEndpoint);
          const updatedItem = await useApi.updateOne(payload.item);
          if (updatedItem.data.alert) {
            // Alert/error: post it and stay here
            context.dispatch(
              "Notification/alertErrorAdd",
              { message: updatedItem.data.alert, show: true },
              {
                root: true,
              }
            );
            return false;
          } else {
            // Call mutation after successful save
            return updatedItem.data.data[0];
          }
        } catch (error) {
          console.log(
            "There was an error updating an item (special):",
            error.response
          );
          return false;
        }
      },

      async logIssueCreate(context, payload) {
        // Log issue create event for this receive record
        const url = `${useEndpoint}/${context.state.currentItem.id}/log-add-issue`;
        cApi.postCustom(url, { $logEvent: payload });
      },

      // Delete multiple items (selected items)
      async deleteSelectedItems(context) {
        let i = 0;
        try {
          // Deleted selected for loop
          const deleteItems = [];
          for (let j = 0; j < context.state.list.selectedItems.length; j++) {
            // Delete item from database
            const item = context.state.list.selectedItems[j];
            i++;
            deleteItems.push(item.id);
            // Remove item from list
            context.commit("ITEM_REMOVE", item.id);
          }
          // Delete batch
          await cApi.deleteManyById({ ids: deleteItems });
          await context.dispatch("fetchAll", context);
          // Success: Tell user
          const msg = `Successfully deleted ${
            i >= 200 ? "maximum of 200" : i
          } ${i > 1 ? "records" : "record"}`;
          context.dispatch("Notification/toastMsgAdd", msg, {
            root: true,
          });
          // Update state
          context.commit("CLEAR_SELECTED_ITEMS");
          i = 0;
        } catch (err) {
          const msg = `Error delete record: ${err}`;
          context.dispatch("Notification/toastErrorAdd", msg, {
            root: true,
          });
        }
        i = 0;
      },

      // Delete single item, special (passed in cApi for end point, along with item and mutation)
      async deleteItemSpecial(context, useEndpoint, item, mutation) {
        try {
          // Delete item
          const useApi = new CommonApi(useEndpoint);
          await useApi.deleteOne(item);
          // Update state
          context.commit(mutation, item);
        } catch (err) {
          const msg = `Error deleting record id ${item.id}: ${err}`;
          context.dispatch("Notification/toastErrorAdd", msg, {
            root: true,
          });
        }
      },

      async clearFilters(context, payload) {
        const filter = payload.filter;
        const fetchOnStart =
          payload.fetchOnStart === undefined ? true : payload.fetchOnStart;

        // Clear selected
        await context.commit("LIST_SET", { key: "selectedItems", value: [] });
        // Clear paging
        await context.commit("LIST_SET", { key: "currentPage", value: 1 });
        // Clear search
        await context.commit("LIST_SET", { key: "searchText", value: "" });
        // Clear sort
        await context.commit("LIST_SET", {
          key: "sortBy",
          value: context.state.list.defaultSortBy || "",
        });
        await context.commit("LIST_SET", {
          key: "sortOrder",
          value: context.state.list.defaultSortOrder || "",
        });
        // Clear filter values
        context.state.filters.forEach(async (filter) => {
          // If filter has a default value, use that, otherwise, ""
          await context.commit("FILTER_SELECTED_SET", {
            fieldName: filter.field,
            value: filter.defaultValue ? filter.defaultValue : null,
          });
        });
        // Refresh list
        if (fetchOnStart === false) return;
        await context.dispatch("fetchAll", filter);
      },

      // Clear filter values only
      async clearFiltersOnly(context) {
        context.state.filters.forEach(async (filter) => {
          // If filter has a default value, use that, otherwise, ""
          await context.commit("FILTER_SELECTED_SET", {
            fieldName: filter.field,
            value: filter.defaultValue ? filter.defaultValue : null,
          });
        });
      },

      // Action filter button set
      async setActionButton(context, payload) {
        await context.commit("ACTION_FILTER_SELECTED_SET", {
          fieldName: payload.fieldName,
          fieldValue: payload.fieldValue,
        });
        // Refresh list
        await context.dispatch("fetchAll", payload?.filter);
      },

      // Dropdown filter set
      async filterSelectedItemsSet(context, payload) {
        context.commit("FILTER_SELECTED_SET", payload);
        // Refresh list
        await context.dispatch("fetchAll", payload?.filter);
      },
      currentItemSet(context, payload) {
        context.commit("CURRENT_ITEM_SET", payload);
      },
      currentItemFieldSet(context, payload) {
        context.commit("CURRENT_ITEM_FIELD_SET", payload);
      },
      listSet(context, payload) {
        context.commit("LIST_SET", payload);
      },
      reset({ context }) {
        context.commit("RESET");
      },
      sidebarHidden(context, payload) {
        context.commit("SIDEBAR_HIDDEN_SET", payload);
      },
    };

    // Common MUTATIONS
    this.mutations = {
      CURRENT_ROUTE_SET(state, routeName) {
        state.list.routeName = routeName;
      },
      ACTION_FILTER_SELECTED_SET(state, data) {
        // Find filter field first
        const foundField = state.filters.find(
          (x) => x.field === data.fieldName
        );
        // Set selected value of filter
        foundField.value = data.fieldValue;
      },
      ATTACHMENT_ADD(state, data) {
        if (!state.currentItem.attachments) state.currentItem.attachments = [];
        state.currentItem.attachments.push(data);
      },
      ATTACHMENT_REMOVE(state, data) {
        if (!state.currentItem.attachments) return;
        if (!Array.isArray(state.currentItem.attachments)) return;
        state.currentItem.attachments = state.currentItem.attachments.filter(
          (file) => file.thumbnailKey !== data
        );
      },
      CLEAR_SELECTED_ITEMS(state) {
        state.selectedItems = [];
      },
      CLEAR_ITEMS(state) {
        state.list.items = [];
        state.list.totalRows = 0;
      },
      CURRENT_ITEM_SET_ASSIGN(state, data) {
        // Refresh currentItm without loosing reference into list.items[]
        Object.assign(state.currentItem, data);
      },
      CURRENT_ITEM_SET(state, data) {
        state.currentItem = data;
      },
      CURRENT_ITEM_COPY_SET(state, data) {
        state.currentItemCopy = data;
      },
      CURRENT_ITEM_FIELD_SET(state, data) {
        state.currentItem[data.key] = data.value;
      },
      FILTER_OPTIONS_SET(state, data) {
        const filter = state.filters.find((x) => x.field === data.fieldName);
        filter.options = data.options;
      },
      FILTER_SELECTED_SET(state, data) {
        const filter = state.filters.find((x) => x.field === data.fieldName);
        filter.value = data.value;
        if (data.operator) {
          filter.operator = data.operator;
        }
        if (data.includeInTable != undefined) {
          filter.includeInTable = data.includeInTable;
        }
        if (data.setByUser != undefined) {
          filter.setByUsaer = data.setByUser;
        }
      },
      // FILTER_VALUE_SET(state, data) {
      //   const filter = state.filters.find((x) => x.field === data.key);
      //   filter[data.subKey].value = data.value;
      // },
      FILTER_INCLUDE_IN_TABLE_VALUE_SET(state, data) {
        const filter = state.filters.find((x) => x.field === data.key);
        filter.includeInTable = data.value;
      },
      FILTER_DEFAULT_VALUE_SET(state, data) {
        const filter = state.filters.find((x) => x.field === data.key);
        filter.defaultValue = data.value;
      },
      FILTER_VALUE_SET(state, data) {
        const filter = state.filters.find((x) => x.field === data.key);
        filter.value = data.value;
      },
      HAS_ATTACHMENTS(state, data) {
        state.currentItem.hasAttachments = data;
      },
      ITEM_REMOVE(state, idToRemove) {
        state.list.items = state.list.items.filter(
          (item) => item.id !== idToRemove
        );
      },
      LIST_SET(state, data) {
        // data is key/value pair, like {key: 'sortOrder', value: 'DESC'}
        state.list[data.key] = data.value;
      },
      TOGGLE_LIST_SET(state, data) {
        // data is key/value pair, like {key: 'sortOrder', value: 'DESC'}
        state.list[data.key] = !state.list[data.key];
      },
      LOG_EVENT_SET(state, data) {
        // Add key/value pair to $logEvent or update if already there
        const { key, value } = data;
        if (!state.currentItem.$logEvent) {
          // Add key/value pair
          state.currentItem.$logEvent = [{ key: key, value: value }];
        } else {
          // $logEvent already exists, replace key/value pair if exists or add it if not
          if (!(key in state.currentItem.$logEvent)) {
            // Not yet in logEvent
            state.currentItem.$logEvent.push({ key: key, value: value });
          } else {
            // There, replace
            state.currentItem.$logEvent[key] = value;
          }
        }
      },
      LOGS_SET(state, data) {
        state.logs = data;
      },
      SIDEBAR_HIDDEN_SET(state, data) {
        state.sidebarHidden = data;
      },
      SUB_NAV_SET(state, data) {
        state.subNavTitle = data;
      },
      TOGGLE_SHOW_DETAILS(state, payload) {
        // Clear showDetails in all other records
        try {
          for (let i = 0; i < state.list.items.length; i++) {
            if (i !== payload.index) {
              state.list.items[i]._showDetails = false;
            }
          }
          state.list.items[payload.index]._showDetails =
            !state.list.items[payload.index]._showDetails;
        } catch (err) {
          console.error(err);
        }
      },
    };
  }

  getCurrentItem(state) {
    return this.getters.currentItem(state);
  }
}

export default BaseStore;