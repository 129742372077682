<template>
  <div class="d-flex align-items-center mr-3">
    <BaseClientBadge  :clientId="clientId || ''" :clientMnemonic="clientMnemonic || ''" class="mr-2"/>
    <span class="read-only-label" v-if="isTenantScope">Client:</span>
    <div class="d-flex" v-if="!isTenantScope">
      <!-- Client - read only -->
      <span class="read-only-value">{{ useClientName }}</span>
    </div>
    <div class="select-container">
      <b-form-select class="custom-select"
      id="clientSelect"
      v-if="isTenantScope"
      v-model="clientId"
      :options="useTenantClients" 
      value-field="clientId"
      text-field="clientName"
      @change="handleClientChange"
      compact
      size="sm"
      custom
      >
      <template #first>
        <b-form-select-option :value="null" >All clients</b-form-select-option>
      </template>
      </b-form-select>
      <div style="color: white">
      </div>  
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: {
    namespace: {
      type: String,
      default: ""
    },
  },
  data() {
    return {
      clientName: "",
      clientId: null,
      clientMnemonic: ""
    }
  },
  mounted() {
    this.clientName = this.useClientName || "";
    this.clientId = this.useClientId || null;
    this.clientMnemonic = this.useClientMnemonic || "";
  },
  computed: {
    ...mapGetters("Session", [
      "user",
      "tenantClients"
    ]),
    useTenantClients() {
      return this.tenantClients;
    },
    isTenantScope() {
      return this.user?.scope === "tenant";
    },
    defaultClient() {
      return null;
      //return this.user.scope === "tenant" ? null : this.user.client.clientName
    },
    useClientName() {
      return this.user?.scope === "tenant" ? null : this.user?.client.clientName
    },
    useClientId() {
      return this.user?.scope === "tenant" ? null : this.user?.clientId
    },
    useClientMnemonic() {
      return this.user?.scope === "tenant" ? null : this.user?.client.clientMnemonic
    }
  },
  methods: {
    // Tenante changes client
    async handleClientChange() {
      const findClient = this.useTenantClients?.find((f) => f.clientId === this.clientId)
      this.$store.commit("Session/SET_TENANT_SELECTED_CLIENT", {
        clientName: findClient?.clientName || "All clients",
        clientId: findClient?.clientId ?? null,
        clientMnemonic: findClient?.clientMnemonic || "",
        badgeColor: findClient?.badgeColor || "",
        defaultClientFacilityId: findClient?.defaultClientFacility?.id,
        defaultClientFacilityName: findClient?.defaultClientFacility?.facilityName,
        defaultClientLobId: findClient?.defaultClientLine?.id,
        defaultClientLobName: findClient?.defaultClientLine?.lobName,
      });
      this.clientMnemonic = findClient?.clientMnemonic || "";
      this.clientId = findClient?.clientId || null;
      // this.$nextTick( async () => {
      //   await this.$store.dispatch("PurchasedItem/clearFiltersOnly");
      // });
      this.$store.dispatch('Dashboard/fetchOarKpis');
    }
  }
};
</script>

<style scoped>
.read-only-label {
  font-size: .9rem;
  color: rgb(173, 171, 171);
  margin-right: 5px;
}
.read-only-value {
  color: rgb(193, 193, 193);
  margin-right: 10px;
}

.select-container {
    display: block;
    width: auto;
    padding: 5px;
  }

.custom-select  {
  background-color: rgb(53, 53, 53);
  color: rgb(190, 190, 190);
  border: 1px solid rgb(78, 78, 78);
}
.custom-select:hover  {
  border: 1px solid rgb(133, 133, 133);
  cursor: pointer;
}
</style>
