<template>
  <div :style="cssVars">
    <!-- Table -->
    <b-row no-gutters class="table-container">
      <b-col>
        <div class="text-smaller detail-table-div">
          <b-collapse id="row-detail-collapse" v-model="visible">
            <b-table
              :items="items"
              :fields="list.fields"
              small
              borderless
              :caption="`${list.listName} (${items.length} items)`"
              caption-top
            />
            <!-- <BaseTableG3
          id="base-g3-row-detail"
          :namespace="namespace"
          :route="list.routeName"
          :fields="list.fields"
          :keyField="list.keyField"
          :imageField="list.imageField"
          :class="list.class"
          :showOtherActions="list.showOtherActions"
          :showRefresh="list.showRefresh"
          :showDrilldown="false"
          :selectMode="list.selectMode"
          :selectable="list.selectable"
          :showAdd="list.showAdd"
          :allowInsert="list.allowInsert"
          :allowDelete="list.allowDelete"
          :selectRowShowsDetail="list.selectRowShowsDetail"
        /> -->
          </b-collapse>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
//       style="max-width: 80%"

import { mapActions } from "vuex";

export default {
  components: {},
  props: {
    namespace: {
      type: String,
      default: ""
    },
    id: {
      type: Number,
      default: -1
    },
    toggle: { type: Boolean, default: false }
  },
  data() {
    return {
      visible: false
    };
  },
  created() {},
  async mounted() {
    document.title = this.list.documentTitle;
    // Set list name
    this.setSubNav(this.list.listName);
    // Get results for passed in id
    this.visible = true;
    //document.documentElement.style.cursor = "wait";
    await this.$store.dispatch(`${this.namespace}/fetchAll`, this.id);
    //document.documentElement.style.cursor = "default";
  },
  beforeMount() {
    this.visible = false;
  },
  computed: {
    //    ...mapGetters("Session", ["setSubNav"]),
    list() {
      return this.$store.getters[`${this.namespace}/list`];
    },
    items() {
      return this.$store.getters[`${this.namespace}/items`];
    },
    cssVars() {
      return {
        // Note to make this work, must specify ":style = 'cssVars'" in div element above.
        //Variables you want to pass to css
        "--default-container-max-width": this.list.defaultContainerMaxWidth,
        "--default-container-min-width-at-2150px": this.list
          .defaultContainerMinWidthAt2150px,
        "--default-container-min-width-at-1920px": this.list
          .defaultContainerMinWidthAt1920px
      };
    }
  },
  methods: {
    ...mapActions("Session", ["setSubNav"])
  }
};
</script>

<style lang="css" scoped>
@media only screen and (max-width: 2150px) {
  .table-container {
    min-width: var(--default-container-min-width-at-2150px);
    /* min-width: 90%; */
  }
}
@media only screen and (max-width: 1920px) {
  .table-container {
    min-width: var(--default-container-min-width-at-1920px);
    /* min-width: 100%; */
  }
}
.table-container {
  /* max-width: 80%; */
  max-width: var(--default-container-max-width);
  margin-left: auto;
  margin-right: auto;
}
.bv-example-row {
  height: 50px;
  background-color: rgb(175, 201, 209);
  border: 1px solid black;
  align-content: center;
}
:deep(.caption) {
  padding-bottom: -20px;
  color: red;
}
.detail-table-div {
  padding: 0px;
  margin: 0px;
}
.card-body {
  padding: 0px;
}
</style>