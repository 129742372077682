<template>
  <b-modal id="profile-modal" title="User Profile" hide-footer size="lg">
    <b-container class="p-3">
      <div class="pt-2">
        <table class="custom-table">
          <tbody>
            <tr v-for="(item, index) in items1" :key="'items1' + index" >
              <td class="key">{{ item.key }}</td>
              <td class="value">{{ item.value }}</td>
            </tr>
            <tr>
              <td colspan="2">
                <hr />
              </td>
            </tr>
            <tr v-for="(item, index) in items2" :key="'items2' + index" >
              <td class="key">{{ item.key }}</td>
              <td class="value">{{ item.value }}</td>
            </tr>
            <tr>
              <td colspan="2">
              </td>
            </tr>
            <tr v-for="(item, index) in items3" :key="'items3' + index" >
              <td class="key top-align">{{ item.key }}</td>
              <td class="value top-align">{{ item.value }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </b-container>
  </b-modal>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() { 
    return {
      healthStatus: "",
      healthMessage: "",
      database: "" ,
      port: ""
    }
  },
  mounted() {
    this.healthcheck()
  },
  computed: {
    ...mapGetters({
      user: "Session/user"
    }),
    items1() {
      if (this.user) {
        return [
          { key: "Name:", value: `${this.user?.firstName} ${this.user?.lastName}` },
          { key: "User id:", value: this.user?.userId },
          { key: "Phone number:", value: this.user?.phoneNumber },
          { key: "Security role:", value: this.user?.authRole?.roleName },
          { key: "Client name:", value: this.user?.client?.clientName },
          { key: "Client facility:", value: this.user?.clientFacility?.facilityName },
          { key: "Processor name:", value: this.user?.processor?.processorName },
          { key: "Processor facility:", value: this.user?.processorFacility?.facilityName },
        ];
      } else {
        return [];
      }
    },
    items2() {
      if (this.user) {
        return [
          { key: "Client admin:", value: this.user?.isClientAdmin },
          { key: "Tenant admin:", value: this.user?.isTenantAdmin },
          { key: "App admin:", value: this.user?.isAppAdmin },
          { key: "Scope:", value: this.user?.scope },
          { key: "Client id:", value: this.user?.clientId },
          { key: "Tenant id:", value: this.user?.tenantId },
          {
            key: "Date user created:",
            value: new Date(this.user.dateEnrolled).toLocaleDateString()
          }
        ];
      } else {
        return [];
      }
    },
    items3() {
      return [
      ];
    },
  },
  methods: {  
    async healthcheck()  {
      const result = await this.$store.dispatch("Session/fetchHealthcheck");
      if (result) {
        this.healthStatus = `${result.status} - ${result?.data?.status}`;
        this.healthMessage = result?.data?.message
        this.database = result?.data?.database
        this.port = result?.data?.port
      }
    }
  }
};
</script>

<style scoped>
.custom-table {
  width: 100%;
  border-collapse: collapse;
}

.custom-table td {
  padding: 0.2rem; /* Adjust padding as needed */
  line-height: 1.5; /* Adjust line-height for spacing */
}

.key {
  width: 35%; /* Adjust width as needed */
  text-align: right;
}

.value {
  width: 65%; /* Adjust width as needed */
  font-weight: 600;
  text-align: left;
}

.separator {
  border-top: 1px solid #ccc; /* Horizontal rule style */
  height: 1px; /* Height to ensure visibility */
  background-color: #f9f9f9; /* Optional: Change background color */
}
.top-align{
  vertical-align: top;
}
</style>
