<template>
  <!-- Supplemental History card -->
  <div class="accordion" role="tablist">
    <b-card no-body class="mb-1">
      <b-card-header header-tag="header" class="p-1" role="tab">
        <b-button
          v-b-toggle.history-1
          block
          variant="outline-info"
          class="font-weight-bold"
          >History</b-button
        >
      </b-card-header>
      <b-collapse id="history-1" accordion="info-accordion" role="tabpanel">
        <b-card-body>
          <div v-if="noHistory" class="no-history">No history to display</div>
          <div v-if="!noHistory">
            <b-list-group
              v-for="(hx, index) in historyData"
              :key="index"
              class="results-list p-0"
            >
              <b-list-group-item class="list-item px-3 py-1">
                <BaseLogItem :item="hx" class="log-item" />
              </b-list-group-item>
            </b-list-group>
          </div>
        </b-card-body>
      </b-collapse>
    </b-card>
  </div>
</template>

<script>
import { formatDate } from "../utils/date-utils.js";

export default {
  name: "BaseInformationCard",
  props: { namespace: { type: String, default: "" } },
  data() {
    return {};
  },
  computed: {
    historyData() {
      const logs = this.$store.getters[`${this.namespace}/logs`];
      return logs;
    },
    noHistory() {
      return !this.historyData;
    }
  },
  methods: {
    formatDate: function(rawDate) {
      return formatDate(rawDate);
    }
  }
};
</script>

<style lang="css" scoped>
.info-label {
  color: grey;
}
.info-card-header {
  margin-top: -12px;
  margin-bottom: -10px;
}
.list-item {
  margin-top: -1px;
  margin-bottom: -1px;
}
.log-item {
  max-width: 525px;
}
.no-history {
  font-size: 0.9em;
}
</style>