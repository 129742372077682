import { httpClient } from "./http-client";
import axios from "axios";

const END_POINT = "/auth";

// Test
//const test = () => httpClient.get()

// Healthcheck
const apiHealthcheck = async () => await httpClient.get("/health");

// DbInfo
const apiDbInfo = async () => {
  try {
    return await httpClient.get("/auth/db-info");
  } catch (err) {
    console.error("apiDbInfo. err: ", err);
  }
};

// Tenant clients
const apiGetTenantClients = async (tenantId) => {
  try {
    return await httpClient.get(`/client/${tenantId}`);
  } catch (err) {
    console.error("apiGetTenantClients. err: ", err);
  }
};

// Login
const apiLogin = (userId, password) =>
  httpClient.post(`${END_POINT}/login`, { userId, password });

// Logout
const apiLogout = () => {
  httpClient.post(`${END_POINT}/logout`);
};

// Self-contained http call to prevent recursion possibility
async function apiRefreshToken(user) {
  const httpClientRefresh = axios.create({
    baseURL: process.env.VUE_APP_BASE_URL,
    timeout: 30000,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      credentials: "include",
    },
  });
  return await httpClientRefresh.post(`${END_POINT}/refresh-token`, user);
}

export { apiHealthcheck, apiLogin, apiLogout, apiRefreshToken, apiDbInfo, apiGetTenantClients };
