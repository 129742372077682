<template>
	<div>
		<b-row no-gutters :class="{'my-comment': isMyComment && comment.id !== -1, 'not-my-comment': !isMyComment}">
			<b-col >
				<!-- User id and dates and elipsis button -->
				<div
				>
          <!-- Created/updated by and context menu row -->
          <b-row >
            <b-col >
              <div class="d-flex" v-if="comment.id !== -1">
                <span v-b-tooltip.hover.html="useToolTip" style="display: inline-block; cursor:pointer">
                  <span class="text-smaller text-muted">
                    {{ useLastUpdatedOn }}
                  </span>
                  <span class="text-muted text-smaller ml-1">
                    by
                  </span>
                  <span class="text-smaller text-muted last-updated-by ml-1">
                    {{ useLastUpdatedBy }}
                  </span>
                </span>
                <!-- Comment id -->
                <div class="text-smaller text-muted ml-auto ">
                  Id: {{ comment.id }}
                </div>
                <!-- Elipsis menu for deleting comments, etc. -->
                <b-dropdown
                  class="elipsis-button text-muted "
                  right
                  variant="link"
                  toggle-class="text-decoration-none"
                  no-caret
                  v-if="isAuthorized('IssueComment', 'update') && comment.id !== -1 && comment.createdById === user.id"
                >
                  <template #button-content>
                    <div class="elipsis"></div>
                  </template>

                  <b-dd-item-btn
                    @click="deleteComment(comment)"
                    :disabled="!isAuthorized('IssueComment', 'delete')"
                  >
                    <i class="fas fa-trash"></i>
                    <span class="ml-1"> Delete comment</span>
                  </b-dd-item-btn>
                </b-dropdown>
              </div>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <!-- Comment -->
              <BaseFormTextarea
                id="comment2"
                placeholder="New comment"
                autocomplete="off"
                v-model="localComment.comment"
                @input="commentIsDirty"
                @blur="commentIsBlurred"
                @keydown.enter.stop
                :readonly="!isAuthorized('IssueComment', 'update')"
                :disabled="localComment.createdById !== user.id || disabled"
                :backgroundColor="useBackgroundColor"
                compact
                :rows="commentRows"
                size="sm"
              />
            </b-col>
          </b-row>
        </div>
			</b-col>
		</b-row>
	</div>
</template>

<script>
import { formatDateCustom } from "../../utils/date-utils.js";
//import { handleValidateState } from "../../utils/component-utils.js"
// import { titleize, truncate } from "../utils/utils.js";
//import { truncate } from "../../utils/utils.js";
//import { required } from "vuelidate/lib/validators";
import { mapGetters } from "vuex";
import { isValid, parseISO } from "date-fns";

export default {
  props: {
    namespace: { type: String, default: "" },
    comment: Object,
    user: Object,
    disabled: Boolean
  },
  data() {
    return {
      localComment: JSON.parse(JSON.stringify(this.comment)),
    }
  },
  computed: {
    ...mapGetters("Session", ["isAuthorized"]),
    isMyComment() {
      return this.comment?.createdById === this.user?.id || this.comment?.lastUpdatedById === this.user?.id;
    },
    useCreatedBy() {
      return this.isMyComment
        ? "Me"
        : this.comment?.authUserCreatedBy.familiarName;
    },
    useLastUpdatedBy() {
      return this.isMyComment
        ? "Me"
        : this.comment?.authUserLastUpdatedBy.familiarName;
    },
    useCreatedOn() {
      const now = new Date();
      const commentDate = new Date(this.comment.createdOn); // Assuming this.comment.ts is a valid timestamp
      const diffInMs = now - commentDate;
      const diffInMinutes = Math.floor(diffInMs / (1000 * 60));
      const diffInHours = Math.floor(diffInMs / (1000 * 60 * 60));

      if (diffInMinutes < 1) {
        return "Just now";
      } else if (diffInMinutes < 60) {
        return `${diffInMinutes} minute${diffInMinutes !== 1 ? 's' : ''} ago`;
      } else if (diffInHours < 12) {
        return `${diffInHours} hour${diffInHours !== 1 ? 's' : ''} ago`;
      } else {
        // Return the formatted date if it's more than 12 hours ago
        return commentDate.toLocaleDateString('en-US'); // Format like "9/22/24"
      }
    },
    useLastUpdatedOn() {
      const now = new Date();
      const commentDate = new Date(this.comment.ts); // Assuming this.comment.ts is a valid timestamp
      const diffInMs = now - commentDate;
      const diffInMinutes = Math.floor(diffInMs / (1000 * 60));
      const diffInHours = Math.floor(diffInMs / (1000 * 60 * 60));

      if (diffInMinutes < 1) {
        return "Just now";
      } else if (diffInMinutes < 60) {
        return `${diffInMinutes} minute${diffInMinutes !== 1 ? 's' : ''} ago`;
      } else if (diffInHours < 12) {
        return `${diffInHours} hour${diffInHours !== 1 ? 's' : ''} ago`;
      } else {
        // Return the formatted date if it's more than 12 hours ago
        return commentDate.toLocaleDateString('en-US'); // Format like "9/22/24"
      }
    },
    useBackgroundColor() {
      return this.comment?.authUserLastUpdatedBy?.id === this.user?.id ? "#F0F6FD" : "#FEFEFE"
    },
    useToolTip() {
      const options = { 
        month: 'numeric', 
        day: 'numeric', 
        year: '2-digit', 
        hour: 'numeric', 
        minute: 'numeric', 
        hour12: true 
      };
      const createdOnFormatted = new Date(this.comment.createdOn).toLocaleString('en-US', options);
      const lastUpdatedFormatted = new Date(this.comment.ts).toLocaleString('en-US', options);
      return `Created by: <strong>${this.useCreatedBy}</strong> on ${createdOnFormatted}<br>Last updated by: <strong>${this.useLastUpdatedBy}</strong> on ${lastUpdatedFormatted}`;
    },
    commentRows() {
      return this.comment.id === -1 ? 5 : 2;
    }
  },
  methods: {
    commentIsDirty() {
     if (this.localComment.comment !== this.comment.comment) {
        this.$emit('g3CommentDirty', { id: this.comment.id, comment: this.localComment });
      }
    },
    commentIsBlurred() {
     if (this.localComment !== this.comment.comment) {
        this.$emit('g3CommentBlurred', { id: this.comment.id, comment: this.localComment });
      }
    },
    deleteComment(comment) {
      this.$emit('g3CommentDeleted', comment)
    },
    isValidDate: function(date) {
      return isValid(date);
    },
    fDate: function(rawDate) {
      //return "test";
      return formatDateCustom(rawDate, "M/D/YY hh:mm A");
    },
    createdOnDateIsValid(date) {
      return this.isValidDate(new Date(parseISO(date)));
    },
  },
  watch: {
    // Update localComment if the prop changes externally (e.g., via Vuex or parent component)
   comment(newComment) {
     this.localComment = newComment;
   }
  }
};
</script>

<style lang="css" scoped>
.elipsis {
	border: none;
	font-size: 14px;
	color: #333;
	margin-top: -15px;
	margin-right: -10px;

	transition: 0.2s;
}
.elipsis:after {
	content: "\2026";
	font-size: 18px;
}
.elipsis:hover {
	transform: scale(1.5, 1.5);
}
.elipsis-button {
	display: block;
	border: none;
}
.fa-trash {
	margin-left: -10px;
	color: rgb(212, 89, 89);
}
.my-comment {
	padding-left: 30px;
}
.not-my-comment {
  padding-right: 50px;
  margin-left: -50px;
}
.last-updated-by {
  font-weight: 600;
}
.tooltip {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.2); /* Shadow */

}


</style>