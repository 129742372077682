var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('b-form-group',{staticClass:"label",class:{
      'form-group--error': _vm.error,
      'form-group--compact': _vm.compact,
      'text-bold': _vm.boldLabel,
    },attrs:{"id":_vm.id,"label":_vm.label,"label-for":`${_vm.id}-input`,"label-cols":_vm.labelCols,"description":_vm.error ? '' : _vm.description,"label-align":_vm.labelAlign,"label-size":_vm.size,"label-class":_vm.labelClass}},[_c('b-form-input',_vm._g(_vm._b({directives:[{name:"uppercase",rawName:"v-uppercase",value:(_vm.uppercase),expression:"uppercase"}],class:{
        'text-bold': _vm.boldValue,
        'right-align': _vm.rightAlignValue,
      },style:(`width: ${_vm.textFieldWidth}px; margin-left: ${_vm.marginLeftValue}px`),attrs:{"id":`${_vm.id}-input`,"list":`${_vm.id}-input-list`,"name":`${_vm.id}-input`,"type":_vm.type,"value":_vm.value,"autocomplete":_vm.autocomplete,"plaintext":_vm.plaintext,"placeholder":_vm.placeholder,"readonly":_vm.readonly,"size":_vm.size,"state":_vm.state,"aria-describedby":`${_vm.id}-live-feedback`,"tabindex":_vm.tabIndex},on:{"input":function($event){return _vm.updateValue(_vm.id, $event)},"focus":_vm.onFocus,"blur":_vm.onBlur}},'b-form-input',_vm.$attrs,false),_vm.$listeners)),_c('b-form-datalist',{attrs:{"id":`${_vm.id}-input-list`,"options":_vm.getOptions}},[_vm._v(" v-show=\"showDropdown\" ")]),_c('b-form-invalid-feedback',{attrs:{"id":`${_vm.id}-live-feedback`}},[_vm._v(" "+_vm._s(_vm.errorMsg)+" ")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }