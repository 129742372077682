<template>
  <div :style="cssVars">
    <b-form-group
      :id="id"
      :label="label"
      :label-for="`${id}-input`"
      :label-cols="labelCols"
      :description="error ? '' : description"
      :label-align="labelAlign"
      :label-size="size"
      :label-class="labelClass"
      class="label"
      :class="{ 'form-group--error': error, 'form-group--compact': compact }"
    >
      <b-form-radio-group
        :id="`${id}-radio`"
        :name="`${id}-radio`"
        :ref="`${id}-radio`"
        v-model="$attrs.value"
        :options="getOptions()"
        @change="changeValue(id, $event)"
        v-bind="$attrs"
        :text-field="textField"
        :value-field="valueField"
        :readonly="readonly"
        :disabled="disabled"
        :required="required"
        :size="size"
        :plain="plain"
        :state="state"
        :aria-describedby="`${id}-live-feedback`"
        :buttons="buttons"
        :button-variant="buttonVariant"
        v-on="$listeners"
      ></b-form-radio-group>
      <b-form-invalid-feedback :id="`${id}-live-feedback`">{{
        errorMsg
      }}</b-form-invalid-feedback>
    </b-form-group>
  </div>
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    namespace: String,
    fieldName: String,
    buttons: { type: Boolean, default: false },
    buttonVariant: { String, default: "" },
    compact: { type: Boolean, default: false },
    description: { type: String },
    disabled: { type: Boolean, default: false },
    error: { type: Boolean },
    errorMsg: { type: String },
    id: { type: String },
    label: { type: String, default: "" },
    labelAlign: { type: String, default: "left" },
    labelClass: { type: String, default: "" },
    labelCols: { type: [String, Boolean, Number], default: "0" },
    marginLeft: { type: String, default: "" },
    marginRight:{ type: String, default: "" },
    options: { type: [Array, String], default: "" },
    optionsField: { type: String, default: "options" },
    plain: { type: Boolean, default: false },
    plaintext: { type: Boolean, default: false },
    readonly: { type: Boolean, default: false },
    required: { type: Boolean, default: false },
    size: { type: String, default: "" },
    state: { type: [Boolean, null], default: null },
    textField: {type: String, default: ""},
    uppercase: { type: Boolean, default: false },
    valueField: {type: String, default: ""}
  },
  computed: {
    filters() {
      return this.$store.getters[`${this.namespace}/filters`];
    },
    cssVars() {
      return {
        // Note to make this work, must specify ":style = 'cssVars'" in top div element above.
        "--margin-right": this.marginRight,
        "--margin-left": this.marginLeft,
      };
    }
  },

  methods: {
    changeValue(id, $event) {
      this.$emit("g3Change", { field: id, value: $event });
    },
    getOptions() {
      // First, get passed in options
      if (this.options) return this.options;
      // Get options from store
      const filters = this.filters;
      const filter = filters?.find(filter => filter.field === this.fieldName);
      if (!filter) return;
      // Remove the 'All' option if this is itemOptions
      if (this.optionsField === "itemOptions") {
        if (filter.itemOptions.some((option) => option[this.fieldName] === "All")) {
          return filter.itemOptions.filter((option) => option[this.fieldName] !== "All")
        } else {
          return filter[this.optionsField];
        }
      }
    }
  }
};
</script>

<style lang="css" scoped>
.form-group--compact {
  margin-bottom: .4rem;
}
</style>
