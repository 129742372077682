<template>
  <b-modal
    id="base-small-confirm-modal"
    ref="modal"
    :title="title"
    @ok="confirmAction"
    @cancel="cancelAction"
    :ok-title="okText"
    :ok-variant="okVariant"
    :cancel-title="cancelText"
    size="sm"
  >
    <p>{{ message }}</p>
    <template #modal-footer>
      <!-- <b-button @click="cancelAction" variant="secondary">{{ cancelText }}</b-button> -->
      <b-button @click="confirmAction" size="sm" :variant="okVariant">{{ okText }}</b-button>
    </template>
  </b-modal>
</template>

<script>
export default {
  name: 'BaseSmallConfirm',
  props: {
    title: { type: String, default: 'Are you sure?' },
    message: { type: String, default: 'This action cannot be undone.' },
    okText: { type: String, default: 'Confirm' },
    okVariant: { type: String, default: 'danger' },
    cancelText: { type: String, default: 'Cancel' }
  },
  methods: {
    confirmAction() {
      this.$emit('confirm'); // Emit confirm event to parent
      this.$bvModal.hide('base-small-confirm-modal'); // Close modal after confirmation
    },
    cancelAction() {
      this.$emit('cancel');
      this.$bvModal.hide('base-small-confirm-modal');
    },
    show() {
      this.$bvModal.show('base-small-confirm-modal');
    },
    hide() {
      this.$bvModal.hide('base-small-confirm-modal');
    }
  }
};
</script>

<style lang="css" scoped>


</style>