// import the api endpoints
import { getAllUsers } from "@/api/users.api"

// State object
const initialState = () => {
  return {
    users: [],
  }
}

const state = initialState()

// Getter functions
const getters = {
  getUsers(state) {
    return state.users
  },
}

// Actions
const actions = {
  async fetchUsers({ commit }) {
    try {
      const response = await getAllUsers()
      commit("SET_USERS", response.data)
    } catch (error) {
      // handle the error here
    }
  },
  reset({ commit }) {
    commit("RESET")
  },
}

// Mutations
const mutations = {
  RESET(state) {
    Object.assign(state, initialState())
  },
  SET_USERS(state, data) {
    state.users = data
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
