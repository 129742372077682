// src/services/socket.js
import { io } from "socket.io-client";

// Replace with your server's URL (e.g., 'http://localhost:3000')
const socket = io(process.env.VUE_APP_SERVER_URL || "http://localhost:3000");

// Listen for events sent from the server
socket.on("connect", () => {
  console.log(`Connected to server: ${socket.id}`);
});

// Example of receiving a feedback message from the server
socket.on("feedback", (data) => {
  console.log("Feedback from server:", data);
  // You can handle this data (e.g., show it in a notification or update the UI)
});

export default socket;
